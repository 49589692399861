import React from 'react'
import {Link} from 'react-router-dom'
import {Button, Col, Row} from 'reactstrap'
import {faEdit} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {getStepsData} from './steps'
import {toTitleCase} from 'src/shared/toTitleCase'
import {TEST_TYPES} from 'src/views/Assessment/steps'

// Prettify value - make form value human-readable
function prettifyValue(rawValue) {
  let val = rawValue
  // Join array items into a string
  if (Array.isArray(val)) {
    val = val.join(', ')
  }

  // Replace dashes with spaces
  val = String(val || '').replace('_', ' ')
  val = toTitleCase(val)
  return val
}
const testType = {
  subfields: {
    test_type: {
      title: 'If the test displayed does not match your selection, you can change it using the drop-down menu.',
      required: true,
      type: 'select',
      choices: [
        {value: TEST_TYPES['blood-cholesterol'], label: 'Cholesterol Profile'},
        {value: TEST_TYPES['blood-energy'], label: 'Energy Profile'},
        {value: TEST_TYPES['blood-general'], label: 'General Health'},
        {value: TEST_TYPES['blood-heart'], label: 'Heart Profile'},
        {value: 'blood-thyroid', label: 'Thyroid Profile'},
        {value: TEST_TYPES['blood-vitamins'], label: 'Vitamins & Minerals Profile'},
        {value: TEST_TYPES['blood-weight'], label: 'Weight Management'},
      ],
      name: 'test_type',
    },
  },
}

const personal_details = {
  subfields: {
    first_name: {
      title: 'First Name',
      max_length: 63,
      help_text: '',
      required: true,
    },
    last_name: {
      title: 'Last Name',
      max_length: 63,
      help_text: '',
      required: true,
    },
    dob: {
      title: 'Date of Birth',
      fmt: 'date',
      type: 'date',
      help_text:
        'Please note that our tests are designed for individuals who are 18 years of age or older. We apologise for any inconvenience caused, but we kindly request that you provide a date of birth indicating that you are at least 18 years old in order to proceed with the testing process.',
      min: '1900-01-01',
      required: true,
      max: '2006-07-31',
    },
    sex: {
      title: 'Sex at Birth',
      type: 'toggle',
      choices: [
        {
          value: 'female',
          label: 'Female',
        },
        {
          value: 'male',
          label: 'Male',
        },
      ],
      help_text: 'We ask of this so that we can provide the optimal results',
      required: true,
    },
    identify_with_gender: {
      title: 'Do you still identify with this gender?',
      type: 'toggle',
      choices: [
        {
          value: 'true',
          label: 'Yes',
        },
        {
          value: 'false',
          label: 'No',
        },
      ],
      help_text: 'We ask of this so that we can provide the optimal results.',
      required: true,
    },
  },
}
// Assessment Summary - show table with all entered data
// If assessment can be edited, show edit buttons for each section.
export function AssessmentDetails({
  can_be_edited,
  can_edit_assessment,
  assessment_values,
  test_kit_details,
  personal,
  test_type,
}) {
  const {bid} = test_kit_details

  const steps = getStepsData([
    'body',
    'smoking',
    'alcohol',
    'activity',
    'medical_conditions',
    'medications',
    'personal',
    'test_type',
  ])

  const values = {
    personal: {...personal},
    test_type: {test_type},
    ...assessment_values[0],
  }

  function renderStep(stepName, index) {
    const {title, fields} = steps[stepName]

    // If step doesn't have fields, skip it
    if (!fields || Object.keys(fields).length === 0) return null

    const subfields =
      fields[stepName]?.subfields || (stepName === 'personal' ? personal_details.subfields : testType.subfields) // Ensure subfields is an empty object if undefined
    return (
      <Col key={stepName} xs={12} md={6} xl={4}>
        <h3 className="mt-4">
          {title}
          {can_edit_assessment &&
            (title.toLowerCase() === 'personal details' || title.toLowerCase() === 'test type' ? (
              <Button
                tag={Link}
                to={`/bloods/boots/${title.toLowerCase() === 'personal details' ? 'user-details' : 'registration'}/${
                  test_kit_details.test_kit_type
                }/${title.toLowerCase() === 'personal details' ? `${bid}/?edit=${true}` : `?edit=${true}?bid=${bid}`}`}
                size="sm"
                className="ml-3"
                color="primary"
                outline
              >
                <div className="d-flex align-items-center">
                  <FontAwesomeIcon icon={faEdit} className="mr-2" />
                  <span className="mt-1">Edit</span>
                </div>
              </Button>
            ) : (
              <Button
                tag={Link}
                to={`/bloods/boots/assessment-form/${test_kit_details.test_kit_type}/${bid}/${index + 1}`}
                size="sm"
                className="ml-3"
                color="primary"
                outline
              >
                <div className="d-flex align-items-center">
                  <FontAwesomeIcon icon={faEdit} className="mr-2" />
                  <span className="mt-1">Edit</span>
                </div>
              </Button>
            ))}
        </h3>

        <div>
          {Object.keys(subfields).map(f => {
            const prettyValue = prettifyValue(values[stepName]?.[f])
            if (!prettyValue) return null

            return (
              <p key={f} className="mt-1">
                <small>{subfields[f]?.title}</small> <br />
                <strong>{prettyValue}</strong>
              </p>
            )
          })}
        </div>
      </Col>
    )
  }
  return <Row className="assessment__summary">{Object.keys(steps).map((step, index) => renderStep(step, index))}</Row>
}
