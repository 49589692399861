import React from 'react'
import {Button, Container} from 'reactstrap'
import {RouteComponentProps, withRouter} from 'react-router-dom'
import {getTestTypeValueFromUrl} from '../../assessment_utils'

interface StartAssessmentProps extends RouteComponentProps<{id: string; test_type: string}> {}

const StartAssessment: React.FC<StartAssessmentProps> = ({match}) => {
  const {id: bid, test_type} = match.params

  return (
    <Container>
      <div className="m-4 p-4">
        <p>You will now need to complete a short health assessment as part of your test activation process</p>
        <p>This will only take 2 minutes to complete.</p>
        <Button
          color="primary text-white font-weight-bold mt-4"
          href={`/bloods/boots/assessment-single/${getTestTypeValueFromUrl(test_type)}/${bid}`}
        >
          Start health assessment
        </Button>
      </div>
    </Container>
  )
}

export default withRouter(StartAssessment)
