import React from 'react'
import {useHistory, Link} from 'react-router-dom'
import {on_mobile} from 'src/shared/reactstrap-toolbox'
import {RawIcon, gen_line} from './TimelineCommon'
import Register from 'src/assets/icons/Timeline/Desktop/Icons/1Register.png'
import KitDispatched from 'src/assets/icons/Timeline/Desktop/Icons/3KitDispatched.png'
import ActivateKit from 'src/assets/icons/Timeline/Desktop/Icons/4CollectSample.png'
import LabAnalysis from 'src/assets/icons/Timeline/Desktop/Icons/6SampleReceived.png'
import Certificate from 'src/assets/icons/Timeline/Desktop/Icons/7ResultsReady.png'

// on desktop "a" is top, "b" is bottom, on mobile, "a" is left and "b" is right
const get_steps = () => {
  const all_tests = [
    {
      label: 'Test Purchased',
      match: status => status === 'created',
      graphic: Register,
      pos: 'a',
      instructions: () => <>Your test has been purchased, we'll dispatch your kit soon</>,
    },
    {
      label: 'Kit Dispatched',
      label_before: 'Awaiting Dispatch',
      match: status => status === 'dispatched',
      graphic: KitDispatched,
      pos: 'b',
      instructions: () =>
        'The next step is for us to post your collection kit, it should be shipped within 2 business days.',
    },
    {
      label: 'Sample Collected & Kit Activated',
      label_before: 'Collect Sample & Activate Kit',
      match: status => status === 'activated',
      graphic: ActivateKit,
      pos: 'a',
      link: '/tests/wellness/resume-activation/{test_id}/',
      instructions: ({test_id}) => (
        <>
          <p>Your kit is in the post post and should be with you soon.</p>
          <>
            <p>
              As soon as you receive your kit, you should open it, read the instructions and activate it, before
              returning it to us.
            </p>
            <Link to={`/tests/wellness/resume-activation/${test_id}/`} className="btn btn-primary">
              Activate Test Kit
            </Link>
          </>
        </>
      ),
    },
    {
      label: 'Sample Received',
      label_before: 'Awaiting Sample Return',
      match: status => status === 'ready-for-processing' || status === 'processing' || status === 'rejected',
      failed: status => status === 'rejected',
      graphic: KitDispatched,
      pos: 'b',
      instructions: ({test_id}) => (
        <>
          <p>Your kit has been activated, please make sure you post it back to us as soon as possible.</p>
        </>
      ),
    },
    {
      label: 'Lab Analysis',
      match: () => false,
      graphic: LabAnalysis,
      pos: 'b',
      instructions: () => 'Your sample has been received and is being processed.',
    },
    {
      label: 'Results Ready',
      label_before: 'Get Results',
      match: status => status === 'results-ready',
      graphic: Certificate,
      pos: 'a',
    },
  ]
  return all_tests.filter(s => s)
}

export default ({test_id, status, details_link, test_type, test_name, report_id}) => {
  const history = useHistory()
  const steps = get_steps()
  const step_increment = on_mobile ? 60 : 160
  let any_failed = false

  if (status === 'in-progress') {
    status = 'dispatched'
  }

  const results_ready = status === 'results-ready'

  var report_link = `/tests`

  if (null !== report_id && undefined !== report_id) {
    report_link = `/my-reports/${report_id}`
  }

  const currentStepIndex = steps.findIndex(step => step.match(status))

  const Icon = ({step, step_index}) => {
    const failed = step.failed && step.failed(status)
    any_failed |= failed
    const current = any_failed ? false : step_index === currentStepIndex + 1
    const clickLink = current && step.link && step.link.replace('{test_id}', test_id)
    return (
      <RawIcon
        step={step}
        onClick={clickLink ? () => history.push(clickLink) : null}
        step_index={step_index}
        complete={step_index <= currentStepIndex}
        failed={failed}
        current={current}
        step_increment={step_increment}
      />
    )
  }

  const next_step = steps[currentStepIndex + 1] || {title: () => null}
  const Instructions = next_step.instructions || (() => null)

  const line_points = gen_line(steps, step_increment)
    .map(xy => xy.join(','))
    .join(' ')
  return (
    <div className="my-3 border-0">
      {test_name && <h2>{test_name}</h2>}
      <div className="text-center text-md-left text-muted">
        {results_ready ? (
          <>
            <p>Your results are ready</p>
            <>
              <Link to={report_link} className="btn btn-primary">
                View Report
              </Link>
            </>
          </>
        ) : null}
        <Instructions test_id={test_id} />
      </div>
      {details_link ? (
        <div className="mt-2">
          <Link to={`/tests/${test_id}/`}>View test {status === 'results-ready' ? 'results' : 'details'}</Link>
        </div>
      ) : null}
      <svg className="progress-svg" width="100%" viewBox={on_mobile ? '0 0 300 400' : '0 0 900 300'}>
        <polyline points={line_points} />
        {steps.map((step, i) => (
          <Icon key={i} step={step} step_index={i} />
        ))}
      </svg>
    </div>
  )
}
