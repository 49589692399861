import React from 'react'
import {Link, Redirect} from 'react-router-dom'
import {Card, CardTitle, CardText, Button, Row, Col} from 'reactstrap'
import {build_url} from 'src/shared/reactstrap-toolbox'

export default ({user}) => {
  React.useEffect(() => {
    window.app.setTitle('Activate Kit')
  }, [])

  if (user) {
    return <Redirect to="/tests/pcr/activate/" push={false} />
  }

  return (
    <div className="py-4">
      <div className="text-center">
        <h1>Activate your kit</h1>
        <div className="lead">
          To activate your kit, either login using your existing account, or create a new one.
        </div>
      </div>
      <Row className="justify-content-around mt-4">
        <Col md={4}>
          <Card body>
            <CardTitle tag="h2">Login</CardTitle>
            <CardText>If you already have a MyHealthChecked account, log in to activate your kit.</CardText>
            <Button tag={Link} to={build_url('/login/', {next: '/tests/'})} color="primary">
              Log in
            </Button>
          </Card>
        </Col>
        <Col md={4}>
          <Card body>
            <CardTitle tag="h2">Create Account</CardTitle>
            <CardText>If you don't yet have an account, you can create one now, then activate your kit.</CardText>
            <Button tag={Link} to={build_url('/create-account/', {next: '/tests/'})} color="primary">
              Create Account
            </Button>
          </Card>
        </Col>
      </Row>
    </div>
  )
}
