import React, {useEffect} from 'react'
import {useState} from 'react'
import {Link, Redirect, RouteComponentProps} from 'react-router-dom'
import {Button, Col, Row} from 'reactstrap'

import {as_title, Loading} from 'src/shared/reactstrap-toolbox'
import {AssessmentSummary} from './AssessmentSummary'

interface Assessment {
  assessment_values: any
  can_be_edited: boolean
  is_assessment_completed: boolean
  list_of_steps: string[]
  test_kit_details: {
    test_kit_name: string
    test_kit_type: string
  }
}

interface MatchParams {
  test_id: string
}

interface Props extends RouteComponentProps<MatchParams> {}

// Single Assessment view
// Check assessment status. If it's not filled, redirect to first step
// If it's filled and can be edited, offer editiing
// Otherwise, show all data here
function AssessmentSingle({match}: Props) {
  const {test_id} = match.params
  const [assessment, setAssessment] = useState<Assessment | null>(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)

  // Fetch assessment on each page load
  async function loadAssessment(testKitID: string) {
    try {
      setLoading(true)
      setError(null)

      // Load assessment
      const url = `/api/dashboard/wizard/${testKitID}/status`
      const response = await window.app.requests.get(url, null, {
        expected_status: [200, 400],
      })

      if (response.status !== 200) {
        setError(response.data.message || response.data.details?.description)
      }

      // If assessment doesn't exist, throw error
      if (!response.data?.test_kit_details?.test_kit_id) {
        throw new Error('Assessment not found')
      }

      // Otherwise, set assessment
      setAssessment(response.data)
    } catch (e: any) {
      setError(e?.message || e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    loadAssessment(test_id)
  }, [test_id])

  if (loading) {
    return <Loading />
  }

  if (error) {
    return (
      <div className="pt-sm-4 pl-sm-1 pt-md-2 ml-md-3 pb-5">
        <div className="">
          <Row>
            <Col xs={12}>
              <div className="mt-4">
                <h2>Ooops, some error happened</h2>
                {error && <p className="lead">{error}</p>}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    )
  }

  // If assessment isn't completed, just redirect to first step
  if (!assessment?.is_assessment_completed) {
    return <Redirect to={`/assessment/${test_id}/1`} />
  }

  return (
    <div className="pt-sm-4 pl-sm-1 pt-md-2 ml-md-3 pb-5">
      {/* Header */}
      <Row tag="header">
        <Col xs={12}>
          <div className="my-4 d-sm-flex align-items-center">
            <h1>Assessment Details</h1>
            {assessment.can_be_edited && (
              <Button className="ml-4" tag={Link} to={`/assessment/${test_id}/1`} color="primary">
                Edit Assessment
              </Button>
            )}
          </div>
        </Col>
        <Col xs={12}>
          {/* If assessment can be edited, show appropriate message and button */}
          <p className="lead">
            Here are your health assessment details, related to{' '}
            <strong className="fw-600 text-primary">{assessment.test_kit_details.test_kit_name}</strong> (
            {as_title(assessment.test_kit_details.test_kit_type)}). Our doctor will prepare report basing on your
            answers. {assessment.can_be_edited && `You can edit assessment until sample arrives to our lab.`}
          </p>
        </Col>
      </Row>

      {/* Assessment Data  */}
      <AssessmentSummary {...(assessment || {})} />
    </div>
  )
}

export default AssessmentSingle
