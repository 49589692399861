import {faExclamationCircle} from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import {Button, Spinner, List, ListInlineItem} from 'reactstrap'
import {message_toast} from 'src/shared/reactstrap-toolbox'

export default ({report_id, report_raw}) => {
  const [pdf_downloading, setPdfDownloading] = React.useState(false)
  const [txt_downloading, setTxtDownloading] = React.useState(false)
  const [generating_report, setGeneratingReport] = React.useState(false)

  const toastMessage =
      'Your PDF report is currently being generated and will be ready in 1-2 minutes. Please refresh your page to access it shortly.',
    // Show this message if report is not available yet but generating
    handleAlert = () => {
      message_toast({
        icon: faExclamationCircle,
        title: 'Generating report',
        message: toastMessage,
      })
    }

  const downloadPdf = async e => {
    e.preventDefault()
    if (pdf_downloading) {
      return
    }
    setPdfDownloading(true)
    var pdf_url = '/api/dashboard/reports/' + report_id + '/pdf/'
    const r = await fetch(pdf_url)
    if (!r.ok) {
      window.app.setError('report download failed')
    } else if (r.status === 202) {
      message_toast({
        icon: faExclamationCircle,
        title: 'Generating report',
        message: toastMessage,
      })
      setGeneratingReport(true)
      return
    } else {
      setPdfDownloading(false)
      const a = document.createElement('a')
      a.href = URL.createObjectURL(await r.blob())
      a.download = r.headers.get('Content-Disposition').match(/filename\s*=\s*(?:(?:"|')([^"']+)|([^;]+))/)[1]
      document.body.appendChild(a)
      a.click()
      a.remove()
    }
    setGeneratingReport(false)
    setPdfDownloading(false)
  }

  const downloadTxt = async e => {
    e.preventDefault()
    if (pdf_downloading) {
      return
    }
    setTxtDownloading(true)
    var pdf_url = '/api/dashboard/reports/' + report_id + '/txt/'
    const r = await fetch(pdf_url)
    if (!r.ok) {
      window.app.setError('raw data download failed')
    } else {
      const a = document.createElement('a')
      a.href = URL.createObjectURL(await r.blob())
      a.download = r.headers.get('Content-Disposition').match(/filename="(.*?)"/)[1]
      document.body.appendChild(a)
      a.click()
      a.remove()
    }
    setTxtDownloading(false)
  }

  return (
    <List type="inline">
      <ListInlineItem>
        <Button onClick={downloadTxt} className="mr-2 float-right" color="primary" outline>
          {txt_downloading ? <Spinner color="secondary" size="sm" /> : <>Download raw data</>}
        </Button>
      </ListInlineItem>

      <ListInlineItem>
        {generating_report ? (
          <Button className="float-right" color="primary" onClick={handleAlert}>
            Generating PDF Report
          </Button>
        ) : pdf_downloading ? (
          <Button className="float-right" color="primary" disabled>
            <Spinner size="sm">Loading...</Spinner>
            <span> Downloading Report...</span>
          </Button>
        ) : (
          <Button onClick={downloadPdf} className="float-right" color="primary">
            <>Download PDF Report</>
          </Button>
        )}
      </ListInlineItem>
    </List>
  )
}
