import React from 'react'
import {Redirect} from 'react-router-dom'
import {Form, message_toast, Loading, as_title, on_mobile} from 'src/shared/reactstrap-toolbox'
import {faLock} from '@fortawesome/free-solid-svg-icons'
import {check_passwords, PasswordStrengthComponent} from 'src/shared/PasswordStrength'
import EmailLink from 'src/shared/EmailLink'

const fields = {
  email: {required: true, type: 'email', disabled: true, autocomplete: 'username'},
  password: {required: true, type: 'password', max_length: 127, autocomplete: 'new-password'},
  repeat_password: {required: true, type: 'password', max_length: 127, autocomplete: 'new-password'},
}

export default ({match, auth_user}) => {
  const [form_data, setData] = React.useState({})
  const [user, setUser] = React.useState(null)
  const [redirect, setRedirect] = React.useState(null)
  const [password_reset_error, setPasswordResetError] = React.useState(null)

  const password_reset_endpoint = `/api/dashboard/password-reset/${match.params.blob}/`
  const user_logged_in = !!auth_user

  React.useEffect(() => {
    window.app.setTitle('Reset Password')
    window.app.requests.get(password_reset_endpoint, null, {expected_status: [200, 400]}).then(r => {
      if (r.status !== 200) {
        setPasswordResetError(r.data.message)
      } else if (r.data.link_already_used) {
        if (user_logged_in) {
          // user is logged and the link is already used; just redirect them to the dashboard
          setRedirect('/')
        } else {
          setPasswordResetError('Password reset link already used')
        }
      } else {
        setUser(r.data)
        setData({email: r.data.email})
      }
    })
  }, [password_reset_endpoint, user_logged_in])

  const execute = async data => {
    const password_error = check_passwords(data, user)
    if (password_error) {
      return password_error
    }

    return await window.app.requests.post(password_reset_endpoint, data, {expected_status: [200, 400, 422]})
  }

  const afterSubmit = r => {
    window.app.reAuthenticate()
    if (!on_mobile) {
      message_toast({icon: faLock, title: 'Password Set', message: 'Password changed successfully'})
    }
    setRedirect('/')
  }

  if (password_reset_error) {
    return <PasswordResetError message={password_reset_error} />
  } else if (redirect) {
    return <Redirect to={redirect} push={false} />
  } else if (!user) {
    return <Loading />
  }

  return (
    <div>
      <h1>Set Password</h1>
      <div className="row justify-content-center pt-4">
        <div className="col-md-6">
          <div className="text-muted">
            <p>
              Enter a new password to set the password for <span className="text-dark">{user.email}</span>.
            </p>
            <p>
              If you are having trouble logging in or setting your password, please email <EmailLink />.
            </p>
          </div>
          <Form
            fields={fields}
            form_data={form_data}
            onChange={d => setData(d)}
            execute={execute}
            afterSubmit={afterSubmit}
            save_label="Set Password"
          />
          <PasswordStrengthComponent password={form_data.password} user={user} />
        </div>
      </div>
    </div>
  )
}

const PasswordResetError = ({message}) => (
  <div>
    <h1>Reset Password</h1>
    <div className="row justify-content-center pt-4">
      <div className="col-md-6">
        <p>{as_title(message)}</p>

        <p className="text-muted">
          If you continue to experience problems resetting your password, please email <EmailLink />.
        </p>
      </div>
    </div>
  </div>
)
