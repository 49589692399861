import React, {useEffect, useState} from 'react'
import {Loading} from 'src/shared/reactstrap-toolbox'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEdit} from '@fortawesome/free-solid-svg-icons'
import {Button, Modal, ModalHeader, ModalFooter, ModalBody} from 'reactstrap'
import {StandaloneForm, message_toast} from 'src/shared/reactstrap-toolbox'
import DisplayItems from 'src/shared/DisplayItems'
import {buildFormFields} from 'src/form_fields'

const field_defs = {
  first_name: {help_text: ''},
  last_name: {help_text: ''},
  email: null,
  dob: {help_text: ''},
  phone_number_primary: null,
  phone_number_secondary: null,
  sex: null,
  identify_with_gender: null,
  ethnicity: null,
  home_street_address: null,
  home_city: null,
  home_postal_code: null,
  home_country: null,
  passport_number: null,
  marketing_consent: null,
}

export default () => {
  const [accountDetails, setAccountDetails] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)
  const [revokeSent, setRevokeSent] = useState(false)
  const [revokeModalOpen, setRevokeModalOpen] = useState(false)
  const [consent, setConsent] = useState(null)
  const [hideFromUser, setHideFromUser] = useState(false)

  useEffect(() => {
    window.app.setTitle('Account')
    window.app.requests.get('/api/dashboard/account/').then(r => setAccountDetails(r.data))
    window.app.requests.get('/api/dashboard/consent/check/', null, {expected_status: [200, 404]}).then(r => {
      if (r.status === 200) {
        setConsent(r.data)
      }
      if (r.data.hide_from_user) {
        // hide revoke consent when report in processing
        setHideFromUser(true)
      }
    })
  }, [])

  if (!accountDetails) {
    return <Loading />
  }

  const afterSubmit = r => {
    window.app.reAuthenticate()
    message_toast({
      icon: faEdit,
      title: 'Success',
      message: r.data.message,
    })
    toggle()
  }

  const onRevoke = data => {
    window.app.requests.get('/api/dashboard/consent/revoke/', null, {expected_status: [200]})
    setRevokeSent(true)
    revokeToggle()
  }

  const revokeToggle = () => setRevokeModalOpen(open => !open)

  const toggle = () => setModalOpen(open => !open)
  const fields = buildFormFields(field_defs)

  return (
    <div className="pt-2 pt-md-5 px-md-5">
      <div className="d-flex align-items-center">
        <h1 className="text-center text-md-left">My Account</h1>
        <Button color="primary" className="ml-4 mb-2 px-4" onClick={() => setModalOpen(true)}>
          <div className="d-flex align-items-center">
            <FontAwesomeIcon icon={faEdit} className="mr-2" />
            <span className="mt-1 lh-1">Edit</span>
          </div>
        </Button>
      </div>
      <p className="text-muted text-center text-md-left">
        Set your login preferences, help us personalise your experience and make account changes here.
      </p>

      <DisplayItems fields={fields} data={accountDetails} />

      {consent && consent.consent && !hideFromUser ? (
        <div className="pb-5">
          <h3>Revoke wellness consent</h3>
          <p>
            Please only do this if you are sure you wish to revoke your consent to the Wellness DNA testing product.
          </p>

          {revokeSent ? null : (
            <Button onClick={revokeToggle} color="primary">
              Revoke
            </Button>
          )}
          {revokeSent ? <p className="mt-2">Consent successfully revoked.</p> : null}
        </div>
      ) : null}

      <Modal isOpen={revokeModalOpen} toggle={revokeToggle}>
        <ModalHeader toggle={revokeToggle}>Revoke consent</ModalHeader>
        <ModalBody>
          <p>Are you sure you want to revoke consent for DNA wellness products?</p>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={onRevoke}>
            Yes
          </Button>
          <Button className="alt-button" onClick={revokeToggle}>
            No
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>Edit Account Details</ModalHeader>
        <StandaloneForm
          fields={fields}
          initial={{...accountDetails}}
          endpoint="/api/dashboard/account/"
          afterSubmit={afterSubmit}
          onCancel={toggle}
          save_label="Save"
          form_body_class="modal-body"
          form_footer_class="modal-footer"
        />
      </Modal>
    </div>
  )
}
