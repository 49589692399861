import React from 'react'
import {StandaloneForm, Loading, message_toast, as_title} from 'src/shared/reactstrap-toolbox'
import {faArrowCircleRight, faMinusCircle} from '@fortawesome/free-solid-svg-icons'
import {buildFormFields} from 'src/form_fields'
import {Redirect} from 'react-router-dom'
import {clean_booking_reference, check_booking_reference} from 'src/utils'

export default ({match, history, activate_link, info_fields_basic, info_fields_with_booking_reference}) => {
  const {booking_reference, barcode} = match.params

  const [test_info, setTestInfo] = React.useState(null)
  const [redirect, setRedirect] = React.useState(null)
  const [toastMessage, setToastMessage] = React.useState(null)

  React.useEffect(() => {
    window.app.setTitle('Activate Kit')
    window.app.requests
      .get('/api/dashboard/activate/', {booking_reference, barcode}, {expected_status: [200, 422]})
      .then(r => {
        if (r.status === 200) {
          setTestInfo(r.data)
        } else {
          const {message, details} = r.data
          setToastMessage(message)
          setRedirect(details?.test_id ? `/tests/${details.test_id}/` : '/tests/')
        }
      })
  }, [booking_reference, barcode])

  React.useEffect(() => {
    if (toastMessage) {
      message_toast({
        icon: faMinusCircle,
        title: 'Activate Kit',
        message: toastMessage,
      })
    }
  }, [toastMessage])

  if (redirect) {
    return <Redirect to={redirect} />
  } else if (test_info === null) {
    return <Loading />
  }

  const {first_name, last_name, test_type} = test_info

  const execute = async data => {
    let {booking_reference} = data
    const errors = []

    if (booking_reference) {
      booking_reference = clean_booking_reference(booking_reference)
      const error_msg = check_booking_reference(booking_reference)
      if (error_msg) {
        errors.push({loc: ['booking_reference'], msg: error_msg})
      }
    }

    if (errors.length) {
      return {status: 400, data: {details: errors}}
    } else {
      const submit_data = {...data, ...match.params}
      if (submit_data.booking_reference) {
        submit_data.booking_reference = clean_booking_reference(booking_reference)
      }

      const url = activate_link
      return await window.app.requests.post(url, submit_data, {expected_status: [200, 422, 404, 400]}).then(r => {
        if (r.status !== 200) {
          message_toast({
            icon: faArrowCircleRight,
            title: 'Activate Kit',
            message: r.data.message,
          })
        } else {
          afterSubmit(r)
        }
      })
    }
  }

  const afterSubmit = r => {
    if (r.response) {
      message_toast({
        icon: faArrowCircleRight,
        title: 'Kit Activated Successfully',
        message: 'Please check all details are correct',
      })
    }
    if (r.data) {
      //history.push(`/assessment/wellness/`)
      history.push(`/tests/wellness/collect-samples/${barcode}/`)
    }
  }

  var fields_def
  var test_type_title = <></>

  if (test_type) {
    fields_def = {...info_fields_basic}
    test_type_title = (
      <>
        Test Type: <span className="text-dark font-weight-bold">{as_title(test_type)}</span>
      </>
    )
  } else {
    fields_def = {...info_fields_with_booking_reference}
  }

  const fields = buildFormFields(fields_def)
  fields.first_name.help_text = ''
  fields.last_name.help_text = ''
  fields.dob.max = new Date(Date.now() - (18 * 365 + 4) * 24 * 60 * 60 * 1000).toISOString().split('T')[0]

  if (fields.dob)
    fields.dob.help_text =
      '(Please note that our tests are designed for individuals who are 18 years of age or older. We apologise for any inconvenience caused, but we kindly request that you provide a date of birth indicating that you are at least 18 years old in order to proceed with the testing process.)'
  if (fields.sex) fields.sex.title = 'Gender at Birth'
  if (fields.sex) fields.sex.help_text = 'We ask of this so that we can provide the optimal results'
  // delete fields.test_ts

  return (
    <div className="row justify-content-center pt-4">
      <div className="col-md-8">
        <h1>Activate Kit</h1>
        <p className="text-muted">{test_type_title}</p>
        <p className="text-muted">
          Please fill in the form below to activate your kit, you'll be able to edit these details for a short time
          after you've activated your test.
        </p>
        <StandaloneForm
          fields={fields}
          initial={{first_name, last_name}}
          submit_initial={true}
          execute={execute}
          afterSubmit={afterSubmit}
          save_label="Continue"
        />
      </div>
    </div>
  )
}
