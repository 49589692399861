import React, {useState, useRef, useEffect} from 'react'
import {useParams} from 'react-router-dom'
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faArrowCircleRight, faCog} from '@fortawesome/free-solid-svg-icons'
import {StandaloneForm, message_toast, as_title, on_mobile, getTestName} from 'src/shared/reactstrap-toolbox'
import {buildFormFields} from 'src/form_fields'
import {getTestTypeValueFromUrl} from '../../assessment_utils'

interface RouteParams {
  id: string
  test_type: string
}

interface FormButtonsProps {
  state: any
  form_props: any
  submitButtonRef: any
}

interface Details {
  personal: {
    first_name: string
    last_name: string
    dob: string
    sex: string
    identify_with_gender: string
  }
  // other possible fields
}
interface IUserDetails {
  location: any
  history: any
}
const FormButtons = ({state, form_props, submitButtonRef}: FormButtonsProps) => (
  <footer className="d-flex justify-content-end w-100 mt-4">
    <div className={form_props.form_footer_class || 'text-right'}>
      {form_props.showErrorButton && (
        <Button
          tag={'a'}
          href="https://myhealthchecked.com/help"
          target="_blank"
          rel="noopener noreferrer"
          className="mr-3 mb-1"
        >
          Report Issue
        </Button>
      )}
      <Button
        type="submit"
        color="primary"
        disabled={state.disabled || form_props.disabled}
        className="btn-cog mb-1"
        innerRef={submitButtonRef}
      >
        {form_props.save_label || 'Save'}
        <FontAwesomeIcon icon={faCog} className="cog-loading fa-fw" />
      </Button>
    </div>
  </footer>
)

const UserDetails: React.FC<IUserDetails> = ({location, history}) => {
  // Format form fields, ex. change labels, remove help texts
  function formatFormFields(fieldsToFormat: any) {
    const fields = fieldsToFormat

    fields.first_name.help_text = ''
    fields.last_name.help_text = ''

    fields.phone_number_primary.help_text = `At MyHealthChecked, we require a phone number for patients who purchase sexual health tests. This is to ensure 
      our doctors can reach you with your results and any necessary follow-up care in a timely and confidential manner. Please note that providing 
      your phone number does not indicate a positive result. Patient privacy and safeguarding are top priorities for us, and we handle your 
      information with the utmost care.`

    // min age to be 18 years
    fields.dob.max = new Date(Date.now() - (18 * 365 + 4) * 24 * 60 * 60 * 1000).toISOString().split('T')[0]
    fields.dob.help_text =
      'Please note that our tests are designed for individuals who are 18 years of age or older. We apologise for any inconvenience caused, but we kindly request that you provide a date of birth indicating that you are at least 18 years old in order to proceed with the testing process.'

    fields.sex.title = 'Sex at Birth'
    fields.sex.help_text = 'We ask of this so that we can provide the optimal results'

    // Remove PNTS gender option
    fields.sex.choices = fields.sex.choices.filter((choice: any) => choice.value !== 'pnts')

    fields.identify_with_gender.title = 'Do you still identify with this gender?'
    // fields.ethnicity.help_text = ''

    delete fields.test_ts
    delete fields.phone_number_primary
    delete fields.secondary_barcode

    return fields
  }

  const {id: bId, test_type} = useParams<RouteParams>() // Type useParams

  const [error, setError] = useState<{title: string; description?: string} | null>(null)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [submit_count, setSubmitCount] = useState(0)
  const [details, setDetails] = useState<Details | undefined>(undefined)
  const [genderWarningOpen, setGenderWarningOpen] = useState(false)
  const [genderWarningDisplayed, setGenderWarningDisplayed] = useState(false)

  const edit = location.search.includes('true')
  const [form_data, setFormData] = useState({})

  let GenderWarningText = useRef(() => {
    return <></>
  })

  let IncorrectSexWarning = (sex: string) => {
    return (
      <>
        <p>
          You have selected your gender at birth as <b>{sex}</b>. This test includes the measurement of markers
          physiologically associated with people born <b>{correctSex}</b>.
        </p>
        <p>
          This test may not, therefore, be suitable for you. Please contact customer care to exchange or refund your
          product at <a href="mailto:care@myhealthchecked.com">care@myhealthchecked.com</a> or 0203 371 3537.
        </p>
        <p />
        <p>If you are sure you want to continue, close this window and click Continue.</p>
      </>
    )
  }

  const toggleGenderWarning = () => {
    setGenderWarningOpen(open => !open)
    setGenderWarningDisplayed(true)
  }

  const handleSubmit = async (data: any) => {
    if (isMaleTest && data.sex === 'female' && !genderWarningDisplayed) {
      GenderWarningText.current = () => IncorrectSexWarning(data.sex)
      toggleGenderWarning()
      return
    } else if (isFemaleTest && data.sex === 'male' && !genderWarningDisplayed) {
      GenderWarningText.current = () => IncorrectSexWarning(data.sex)
      toggleGenderWarning()
      return
    }

    const newData = {
      bid: bId,
      first_name: data?.first_name || details?.personal?.first_name || data.first_name,
      last_name: data?.last_name || details?.personal?.last_name || data.last_name,
      dob: data?.dob || details?.personal?.dob || data.dob,
      sex: data?.sex || details?.personal?.sex || data.sex,
      identify_with_gender:
        data?.identify_with_gender || details?.personal?.identify_with_gender || data.identify_with_gender,
      test_type: getTestTypeValueFromUrl(test_type),
    }

    const response = await window.app.requests.post(
      `/api/dashboard/registration/personal-details/?edit=${edit ? edit : false}`,
      newData,
      {expected_status: [200, 422, 404]},
    )

    if (response.status !== 200) {
      setError(response.data.message || 'Saving personal details failed. Try again.')
      return
    } else {
      if (!on_mobile && response.status === 200) {
        message_toast({
          icon: faArrowCircleRight,
          title: 'Confirm user details',
          message: 'User details confirmed!',
          onClick: () => {},
          className: '',
        })
      }
      !edit
        ? history.push(`/bloods/boots/assessment/${getTestTypeValueFromUrl(test_type)}/${bId}`)
        : history.push(`/bloods/boots/assessment-single/${getTestTypeValueFromUrl(test_type)}/${bId}`)
    }
  }

  useEffect(() => {
    edit && fetchDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bId])

  const fetchDetails = async () => {
    const url = `/api/dashboard/registration/details/?bid=${bId}`
    const response = await window.app.requests.get(url, null, {expected_status: [200, 400]})
    if (response.status !== 200) {
      setError(response.data.message || response.data.details?.description)
      return
    }
    setFormData(response.data)
    setDetails(response.data)
  }

  const maleTypes = ['blood-erectile', 'blood-male-sex-health']
  const femaleTypes = ['blood-polycystic', 'blood-menopause', 'blood-female-sex-health']

  const isMaleTest = maleTypes.includes(getTestTypeValueFromUrl(test_type))
  const isFemaleTest = femaleTypes.includes(getTestTypeValueFromUrl(test_type))
  const correctSex: string = isMaleTest ? 'male' : 'female'

  const fields_definition = {
    first_name: true,
    last_name: true,
    phone_number_primary: false,
    dob: true,
    sex: true,
    identify_with_gender: true,
    test_ts: false,
  }

  let fields = buildFormFields(fields_definition)
  fields = formatFormFields(fields)

  return (
    <div>
      <div className="row justify-content-center py-5">
        <div className="col-md-8">
          <h1>Confirm your details</h1>
          <p className="text-muted">
            <span className="text-dark font-weight-bold">
              {' '}
              Test Type: {as_title(getTestName(getTestTypeValueFromUrl(test_type!)))} Blood Test
            </span>
          </p>
          <p className="text-muted">
            Please fill in the form to activate your booking. You'll be able to edit these details until the day of
            your booking.
          </p>

          <StandaloneForm
            form_error={error?.title}
            form_data={!error && form_data}
            initial={{...details?.personal, identify_with_gender: details?.personal.identify_with_gender.toString()}}
            fields={{...fields}}
            allow_empty={true}
            onChange={setFormData}
            save_label="Continue"
            execute={handleSubmit}
            Buttons={FormButtons}
            showErrorButton={submit_count >= 2 && error?.title}
          />

          <Modal isOpen={genderWarningOpen} toggle={toggleGenderWarning}>
            <ModalHeader toggle={toggleGenderWarning}>Warning</ModalHeader>
            <ModalBody>{GenderWarningText.current()}</ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={toggleGenderWarning}>
                Close
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    </div>
  )
}

export default UserDetails
