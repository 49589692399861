import React from 'react'

export default ({image_url}) => {
  if (image_url) {
    return (
      <div className="text-center my-3">
        <figure className="figure">
          <img src={image_url} className="figure-img img-fluid rounded h-400" alt="Antigen Test Cassette" />
          <figcaption className="figure-caption">Cassette image used for result analysis.</figcaption>
        </figure>
      </div>
    )
  } else {
    return null
  }
}
