import React from 'react'
import {Col, Row} from 'reactstrap'
import {Narrow} from 'src/Page/NarrowWide'

export default Narrow(({title, children}) => (
  <Row className="justify-content-center mb-3">
    <Col md={6} className="text-center">
      <h1 className="pb-3">{title}</h1>
      <div className="text-muted">{children}</div>
    </Col>
  </Row>
))
