import React from 'react'
import {Link, Redirect} from 'react-router-dom'
import {faMinusCircle} from '@fortawesome/free-solid-svg-icons'
import {Loading, message_toast, on_mobile, StandaloneForm} from 'src/shared/reactstrap-toolbox'

const fields = {
  mobile_number: {type: 'tel', autocomplete: 'tel', title: 'Mobile Number', required: true},
}

export default ({match}) => {
  const {token} = match.params
  const [loaded, setLoaded] = React.useState(null)
  const [redirect, setRedirect] = React.useState(false)
  const [numberUsed, setNumberUsed] = React.useState(null)
  const [initial, setInitial] = React.useState(null)

  React.useEffect(() => {
    if (on_mobile) {
      setRedirect(true)
    } else {
      window.app.requests.get(`/api/antigen/${token}/check/`).then(r => {
        if (r.data.status === 'in-progress') {
          setInitial({mobile_number: r.data.mobile_number})
          setLoaded(true)
        } else {
          message_toast({
            icon: faMinusCircle,
            title: 'Antigen Test',
            message: 'Test Already Complete',
          })
          setRedirect(true)
        }
      })
    }
  }, [token])

  if (redirect) {
    return <Redirect to={`/tests/antigen/${token}/photo/`} />
  } else if (!loaded) {
    return <Loading />
  } else {
    return (
      <div className="row justify-content-center pt-4">
        <div className="col-md-8">
          <h2>Antigen Rapid Test &mdash; SMS to Mobile</h2>
          <div className="lead">
            <p>
              It looks like you're using a laptop or desktop, the next stage of the test process - taking a photo of
              the Test Cassette is <b>much easier on a smartphone</b>.
            </p>
            <p>Would you like to send an SMS to your smartphone with a link to take the cassette photo?</p>
          </div>
          {numberUsed ? (
            <div className="lead">
              <p>
                <b>SMS Message Sent to "{numberUsed}".</b>
              </p>
              <p>
                Once you've got the message you can safely close this window. If you don't get the message, or you
                can't take the photo on your mobile, you can still{' '}
                <Link to={`/tests/antigen/${token}/photo/`}>try on this computer</Link>.
              </p>
            </div>
          ) : (
            <StandaloneForm
              fields={fields}
              initial={initial}
              submit_initial={true}
              endpoint={`/api/antigen/${token}/send-sms/`}
              form_footer_class="text-center"
              onCancel={() => setRedirect(true)}
              cancel_label="Continue on this Computer"
              save_label="Send SMS"
              afterSubmit={r => setNumberUsed(r.data.mobile_number)}
            />
          )}
        </div>
      </div>
    )
  }
}
