import React from 'react'
import {exclusion_field, RenderGrid} from './AssessmentCommon'

const required = true

var fields_wellness = {
  exclusions: {
    title: 'Excluded Foods',
    subfields: {
      dairy: exclusion_field('Milk and dairy', 'dairy'),
      nuts: exclusion_field('Nuts', 'nuts'),
      gluten: exclusion_field('Gluten', 'gluten'),
      shellfish: exclusion_field('Shellfish', 'shellfish'),
      oily_fish: exclusion_field('Oily Fish', 'oily_fish'),
      eggs: exclusion_field('Eggs', 'eggs'),
      poultry: exclusion_field('Poultry', 'poultry'),
      red_meat: exclusion_field('Red Meat excluding Pork', 'red_meat'),
      pork: exclusion_field('Pork', 'pork'),
      alcohol: {
        required,
        title: 'Alcohol',
        name: 'alcohol',
        type: 'toggle',
        choices: [
          {value: 'dont_eat', label: "Don't Drink"},
          {value: 'eat', label: 'Drink'},
        ],
      },
    },
  },
}

const RenderEverything = ({fields, RenderField}) => {
  return (
    <>
      <div className="std-top-md">
        <small>
          For the following please tell us which you can't eat (allergy or religious restriction), don't eat or those
          you eat
        </small>
      </div>
      <RenderGrid fields={fields.exclusions.subfields} RenderField={RenderField} />
    </>
  )
}

export default {
  wellness: {
    title: 'Wellness DNA Assessment',
    icon: null,
    before: () => (
      <div className="text-dark font-weight-bold">
        Please provide some basic details about yourself. We'll use this to customise your reports.
      </div>
    ),
    fields: fields_wellness,
    field_render: RenderEverything,
  },
}
