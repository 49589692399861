import React from 'react'
import {Loading, WithContext} from 'src/shared/reactstrap-toolbox'
import {LinkCard} from 'src/shared/LinkCard'
import {check_step_anon, check_step_auth} from './WellnessForm'
import Certificate from 'src/assets/icons/Platform/Results.svg'

class AssessmentStart extends React.Component {
  constructor(props) {
    super(props)
    this.state = {user: null, error: null}
    this.check_token = this.props.user ? check_step_auth.bind(this) : check_step_anon.bind(this)
  }

  componentDidMount() {
    window.app.setTitle('MyHealthChecked Assessment')
    this.check_token()
  }

  render() {
    if (this.state.error) {
      return (
        <div>
          <h1>{this.state.error.title}</h1>
          <p>{this.state.error.description || null}</p>
        </div>
      )
    } else if (!this.state.user) {
      return <Loading />
    }
    const {token} = this.props.match.params
    const link_to_wellness = token ? `/assessment/${token}/wellness/form` : '/assessment/wellness/form'
    const link_to_dashboard = '/'

    return (
      <div className="pt-2 pt-md-5 px-md-5">
        <h1 className="text-left">Assessments</h1>
        <LinkCard
          to={link_to_wellness}
          image={Certificate}
          title="Complete your wellness assessment questionnaire"
          button_text="Start"
          button2_text="Skip"
          button2_link={link_to_dashboard}
        >
          <div className="text-muted">This could take up to 2 minutes to complete.</div>
        </LinkCard>
      </div>
    )
  }
}

export default WithContext(AssessmentStart)
