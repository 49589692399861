import React from 'react'
import {faCheck, faExclamation} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import './HorizontalSection.scss'

export interface HorizontalSectionItem {
  area?: HealthArea
  name: string
  label: string
  subtitle?: string
  icon: string | null
  status?: 'good' | 'borderline' | 'require-attention' | string
  content: any
  hiddenFromNav?: boolean
  background?: string
}

export interface HorizontalSectionProps {
  items: HorizontalSectionItem[]
  current?: string
  setCurrentItem: (name: string) => void
}

// HorizontalSection function
export function HorizontalSection({items, current, setCurrentItem}: HorizontalSectionProps) {
  const renderNavItem = (item: HorizontalSectionItem) => {
    const isCurrent = current === item.name
    const statusIcon = ['good', 'normal'].indexOf(String(item.status)) !== -1 ? faCheck : faExclamation

    return (
      <button
        className={`horizontalSection__nav-button ${isCurrent && `horizontalSection__nav-button--current`}`}
        onClick={() => setCurrentItem(item.name)}
        style={{backgroundColor: item.background, borderColor: (!isCurrent && item.background) || undefined}}
        key={item.name}
      >
        {item.icon && <img src={item.icon} alt={item.label} />}
        <div className="horizontalSection__nav-button-label">
          <span className="horizontalSection__nav-button-title">{item.label}</span>
          {item.subtitle && <span className="horizontalSection__nav-button-subtitle">{item.subtitle}</span>}
        </div>
        {item.status && (
          <span
            className={`horizontalSection__nav-button-status horizontalSection__nav-button-status--${item.status}`}
          >
            <FontAwesomeIcon icon={statusIcon} />
          </span>
        )}
      </button>
    )
  }

  function renderContent() {
    let item = Object.values(items)[0]
    const currentItem = items.find(i => i.name === current)
    if (currentItem) {
      item = currentItem
    }

    return item.content()
  }

  return (
    <div className="horizontalSection__container">
      <aside className="horizontalSection__nav">
        {items.filter(item => !item.hiddenFromNav).map(item => renderNavItem(item))}
      </aside>
      <main className="horizontalSection__main">{renderContent()}</main>
    </div>
  )
}
