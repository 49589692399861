import React from 'react'
import {message_toast, StandaloneForm} from 'src/shared/reactstrap-toolbox'
import {faEdit} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Button, Modal, ModalHeader} from 'reactstrap'
import {buildFormFields} from 'src/form_fields'
import DisplayItems from 'src/shared/DisplayItems'

const fit2Fly2Antigen = [
  'first_name',
  'last_name',

  'dob',
  'passport_number',

  'phone_number_primary',
  'phone_number_secondary',

  'departure_ts',
  'destination_country',

  'test_ts',
]
const day2Antigen = [
  'first_name',
  'last_name',

  'passport_number',
  'nhs_number',

  'phone_number_primary',
  'phone_number_secondary',

  'sex',
  'ethnicity',

  'dob',
  'vaccination_status',

  'home_address',
  'uk_address',

  'phone_number_primary',
  'test_ts',

  'departure_ts',
  'arrival_ts',

  'departure_country',
  'transit_country',

  'travel_method',
  'travel_vehicle',
  'travel_number',
]

const day2AntigenForm = {
  uk_street_address: true,
  uk_city: true,
  uk_postal_code: true,
}

const wellnessAndBloodFields = ['first_name', 'last_name', 'postage_tracking', 'test_ts']

const sharedFields = [
  'first_name',
  'last_name',
  'dob',
  'passport_number',
  'phone_number_primary',
  'phone_number_secondary',
  'postage_tracking',
  'test_ts',
  'departure_ts',
]

const fitToFlyFields = [...sharedFields, 'destination_country']

const quarantineFields = [
  ...sharedFields,
  'departure_country',
  'departure_country_status',
  'transit_country',
  'arrival_ts',
  'travel_method',
  'travel_vehicle',
  'travel_number',
  'symptoms',
]

export default ({test_id, info, uk_address, update}) => {
  const [modalOpen, setModalOpen] = React.useState(false)
  const toggle = () => setModalOpen(open => !open)

  const {status} = info
  if (status === 'created' || status === 'dispatched') {
    return null
  }

  const afterSubmit = r => {
    message_toast({
      icon: faEdit,
      title: 'Success',
      message: (r.data && r.data.message) || 'Test Updated',
    })
    toggle()
    update()
  }

  let formEndpoint, displayFields, formFields, showModal, formButton, formInitial

  if (info.test_type.includes('wellness') || info.test_type.includes('blood')) {
    formEndpoint = `/api/dashboard/tests/edit/${test_id}/wellness/`
    displayFields = buildFormFields(wellnessAndBloodFields)
    displayFields.first_name.help_text = ''
    displayFields.last_name.help_text = ''
    showModal = status === 'activated'
    formFields = buildFormFields(wellnessAndBloodFields)
    formFields.first_name.help_text = ''
    formFields.last_name.help_text = ''
    formButton = 'Edit Test Details'
    formInitial = {...info}
  } else if (info.test_type === 'day-2-antigen') {
    displayFields = buildFormFields(day2Antigen)
    showModal = true
    formFields = buildFormFields(day2AntigenForm)
    formButton = 'Update UK Address'
    formEndpoint = `/api/dashboard/antigen/update/address/${test_id}/`
    formInitial = {...uk_address}
  } else if (info.test_type === 'fit-to-fly-antigen') {
    displayFields = buildFormFields(fit2Fly2Antigen)
    showModal = false
  } else if (info.test_type === 'fit-to-fly-pcr') {
    displayFields = buildFormFields(fitToFlyFields)
    formEndpoint = `/api/dashboard/tests/edit/${test_id}/fit-to-fly/`
    showModal = status === 'activated'
    formFields = buildFormFields(quarantineFields)
    formButton = 'Edit Test Details'
    formInitial = {...info}
  } else {
    formEndpoint = `/api/dashboard/tests/edit/${test_id}/quarantine/`
    displayFields = buildFormFields(quarantineFields)
    showModal = status === 'activated'
    formFields = buildFormFields(quarantineFields.filter(f => f !== 'test_ts'))
    formButton = 'Edit Test Details'
    formInitial = {...info}
  }

  return (
    <>
      <h2 className="py-2" id="test-details">
        Test Details
        {showModal ? (
          <Button size="sm" className="ml-3" onClick={() => setModalOpen(true)} color="primary">
            <div className="d-flex align-items-center">
              <FontAwesomeIcon icon={faEdit} className="mr-2" />
              <span className="mt-1">{formButton}</span>
            </div>
          </Button>
        ) : null}
      </h2>
      <DisplayItems fields={displayFields} data={info} />

      <Modal isOpen={modalOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>{formButton}</ModalHeader>
        <StandaloneForm
          fields={formFields}
          initial={formInitial}
          endpoint={formEndpoint}
          afterSubmit={afterSubmit}
          onCancel={toggle}
          save_label="Save"
          form_body_class="modal-body"
          form_footer_class="modal-footer"
        />
      </Modal>
    </>
  )
}
