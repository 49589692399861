import React from 'react'
import {StandaloneForm, message_toast} from 'src/shared/reactstrap-toolbox'
import {faEnvelope} from '@fortawesome/free-solid-svg-icons'
import EmailLink from 'src/shared/EmailLink'

const fields = {
  email: {required: true, type: 'email', max_length: 127, autocomplete: 'email'},
  recaptcha_token: {required: true, type: 'recaptcha'},
}

export default () => {
  const [done_email, setDoneEmail] = React.useState(null)

  React.useEffect(() => {
    window.app.setTitle('Reset Password')
  }, [])

  const afterSubmit = r => {
    message_toast({icon: faEnvelope, title: 'Email Sent', message: `Email sent to ${r.data.email}`})
    setDoneEmail(r.data.email)
  }

  if (done_email) {
    return <Done email={done_email} />
  }

  return (
    <div>
      <h1>Reset Password</h1>
      <div className="row justify-content-center pt-4">
        <div className="col-md-6">
          <div className="text-muted">
            <p>
              If you've forgotten your password fill in the form below, we'll send you an email with a link to reset
              your password or more information.
            </p>
            <p>
              If you are having trouble logging in, please email <EmailLink />.
            </p>
          </div>
          <StandaloneForm
            fields={fields}
            endpoint="/api/dashboard/password-reset-request/"
            afterSubmit={afterSubmit}
            save_label="Reset Password"
          />
        </div>
      </div>
    </div>
  )
}

const Done = ({email}) => (
  <div>
    <h1>Reset Password</h1>
    <div className="row justify-content-center pt-4">
      <div className="col-md-6">
        <p className="text-muted">
          An email has been sent to <span className="text-primary">{email}</span> with instructions on resetting your
          password.
        </p>

        <p className="text-center">You can safely close this window.</p>
      </div>
    </div>
  </div>
)
