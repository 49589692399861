import React from 'react'
import {StandaloneForm, message_toast, Loading} from 'src/shared/reactstrap-toolbox'
import {Redirect} from 'react-router-dom'
import {faMinusCircle} from '@fortawesome/free-solid-svg-icons'
import {clean_booking_reference, check_booking_reference} from 'src/utils'

const fields = {
  booking_reference: {
    max_length: 18,
    autocomplete: 'off',
    required: true,
    extra: {bsSize: 'lg'},
    help_text:
      'Booking Reference should be a 12 character code, you will have received via email or from the label on your leaflet.',
  },
}

export default ({location}) => {
  const [redirect, setRedirect] = React.useState(null)
  const [redirectMessage, setRedirectMessage] = React.useState(null)
  const [loaded, setLoaded] = React.useState(false)
  const test_id = new URLSearchParams(location.search).get('test_id')

  React.useEffect(() => {
    window.app.setTitle('Antigen Test')
    if (test_id) {
      window.app.requests.get(`/api/dashboard/activate/${test_id}/`, null, {expected_status: [200, 422]}).then(r => {
        if (r.status === 200) {
          if (r.data.test_type.endsWith('antigen')) {
            setRedirect(`/tests/antigen/form/${r.data.booking_reference}`)
            setLoaded(true)
          } else {
            window.app.setError(`Wrong test type ${r.data.test_type}`)
          }
        } else {
          setRedirectMessage(r.data.message)
          setRedirect(`/tests/${test_id}/`)
        }
      })
    } else {
      setLoaded(true)
    }
  }, [test_id])

  React.useEffect(() => {
    if (redirectMessage) {
      message_toast({
        icon: faMinusCircle,
        title: 'Antigen Test',
        message: redirectMessage,
      })
    }
  }, [redirectMessage])

  if (redirect) {
    return <Redirect to={redirect} />
  } else if (!loaded) {
    return <Loading />
  }

  const execute = async data => {
    const booking_reference = clean_booking_reference(data.booking_reference)
    const error_msg = check_booking_reference(booking_reference)
    if (error_msg) {
      return {status: 400, data: {details: [{loc: ['booking_reference'], msg: error_msg}]}}
    } else {
      return await window.app.requests.get(
        '/api/dashboard/antigen/check/',
        {booking_reference},
        {expected_status: [200, 422]},
      )
    }
  }

  const afterSubmit = r => {
    const {booking_reference} = r.data
    setRedirect(`/tests/antigen/form/${booking_reference}/`)
  }

  return (
    <div className="pt-sm-4 pl-sm-1 pt-md-2 ml-md-3">
      <div className="mb-4 mt-3 pt-3">
        <h1 className="mt-3 ml-3 text-left text-md-left">Antigen Test</h1>
      </div>

      <div className="row justify-content-center pt-4">
        <div className="col-md-6 mb-5">
          <p className="text-muted">
            Please enter the booking reference you received via email after your Antigen Lateral Flow Test was
            purchased.
          </p>
          <div className="activate-1-form">
            <StandaloneForm fields={fields} afterSubmit={afterSubmit} execute={execute} save_label="Continue" />
          </div>
        </div>
      </div>
    </div>
  )
}
