import React from 'react'
import {on_mobile} from 'src/shared/reactstrap-toolbox'
import {ReactComponent as Tick} from 'src/assets/icons/tick.svg'
import {ReactComponent as Cross} from 'src/assets/icons/cross.svg'

const a_line = on_mobile ? 110 : 100
const b_line = on_mobile ? 190 : 200
const step_start = 50

export const gen_line = (steps, step_increment) => {
  // step is x on mobile and y on desktop
  let step = step_start - step_increment
  let last_pos = null
  let line = []
  const add_point = p => {
    const point = [step, p === 'a' ? a_line : b_line]
    if (on_mobile) {
      point.reverse()
    }
    line.push(point)
  }
  for (let pos of steps.map(s => s.pos)) {
    if (pos !== last_pos) {
      add_point(pos)
      last_pos = pos
    }
    step += step_increment
    add_point(pos)
  }
  line.shift()
  return line
}

const SvgText = ({children, x, y, width, height, className}) => (
  <foreignObject x={x} y={y} width={width} height={height}>
    <div xmlns="http://www.w3.org/1999/xhtml" className={className}>
      {children}
    </div>
  </foreignObject>
)

const icon_size = on_mobile ? 35 : 60
const icon_radius = icon_size / 2
const check_radius = on_mobile ? 8 : 10

export const RawIcon = ({step, onClick, step_index, complete, current, step_increment, failed}) => {
  let text_props, x, y
  const is_a = step.pos === 'a'
  if (on_mobile) {
    x = is_a ? a_line : b_line
    y = step_index * step_increment + step_start
    text_props = {
      x: is_a ? 0 : 225,
      y: y - 50,
      width: 75,
      height: 100,
      className: `svg-title text-dark-gray d-flex h-100p align-items-center${
        is_a ? ' justify-content-end text-right' : ''
      }`,
    }
  } else {
    x = step_index * step_increment + step_start
    y = is_a ? a_line : b_line
    text_props = {
      x: x - 65,
      width: 130,
      height: 100,
      y: is_a ? 25 : 250,
      className: 'svg-title text-dark-gray h6 text-center',
    }
  }

  let inner_circle = 'fill-lite-gray'
  if (current) {
    inner_circle = onClick ? 'fill-mhc-peach' : 'fill-mhc-mustard'
  }

  let label = step.label
  if (!complete && step.label_before) {
    label = step.label_before
  }

  const Graphic = step.graphic
  const check_offset = icon_radius - check_radius / 2
  const complete_class = failed ? 'fill-danger' : 'fill-success'
  const CompleteIcon = failed ? Cross : Tick
  return (
    <>
      <SvgText {...text_props}>{label}</SvgText>
      <g onClick={onClick} className={onClick ? 'clickable shadow raise' : ''}>
        <circle cx={x} cy={y} r={icon_radius + 5} stroke="black" strokeWidth="1" fill="white" />
        <circle cx={x} cy={y} r={icon_radius} stroke="none" className={inner_circle} />
        <image xlinkHref={Graphic} x={x - icon_radius} y={y - icon_radius} width={icon_size} height={icon_size} />
        {complete ? (
          <>
            <circle cx={x + check_offset} cy={y + check_offset} r={check_radius} className={complete_class} />
            <CompleteIcon
              x={x + check_offset - 5.4}
              y={y + check_offset - 5.4}
              width={check_radius * 1.2}
              height={check_radius * 1.2}
            />
          </>
        ) : null}
      </g>
    </>
  )
}
