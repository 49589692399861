import React from 'react'

import goldImage from 'src/assets/images/gold-tube-barcode-label.png'
import royalImage from 'src/assets/images/royal-mail-tracking-number-label.png'
import BloodsBarcodeForm from './BloodsBarcodeForm'

const fields = {
  primary_barcode: {
    required: true,
    title: 'Gold Barcode (required)',
    max_length: 12,
    autocomplete: 'off',
    custom_type: 'text',
    type: 'mask',
    help_text: '10 character code printed on the sticker.',
    extra: {
      // Sample code: MGZ-beo-1184
      mask: [/[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z]/, '-', /[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z]/, '-', /\d/, /\d/, /\d/, /\d/],
      // Format entered values
      pipe: (value: string) => {
        let sections = value.split('-')
        // First group should be uppercase
        sections[0] = sections[0].toUpperCase()
        // Second group should be lowercase
        sections[1] = sections[1].toLowerCase()
        return sections.join('-')
      },
      pattern: `[a-zA-Z]{3}-[a-zA-Z]{3}-[0-9]{4}`,
      title: 'Barcode should be in format ABC-abc-1234',
      placeholder: 'ABC-abc-1234',
    },
    image: {
      src: goldImage,
      width: 227,
      height: 85,
    },
  },
  postage_tracking: {
    required: true,
    title: 'Royal mail tracking number (required)',
    max_length: 16,
    autocomplete: 'off',
    custom_type: 'text',
    type: 'mask',
    help_text: 'This number can be found on the Royal Mail Return Label included in your kit.',
    extra: {
      // Sample code: AA 1234 5678 9BB
      mask: [
        /[a-zA-Z]/,
        /[a-zA-Z]/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /[a-zA-Z]/,
        /[a-zA-Z]/,
      ],
      // Format entered values
      pipe: (value: string) => {
        let sections = value.split(' ')
        // First group should be uppercase
        sections[0] = sections[0].toUpperCase()
        // make uppercase when mix of numbers and characters (last group)
        sections[3] = sections[3].match(/[a-zA-Z]/) ? sections[3].toUpperCase() : sections[3]
        return sections.join(' ')
      },
      pattern: `[a-zA-Z]{2} [0-9]{4} [0-9]{4} [0-9]{1}[a-zA-Z]{2}`,
      title: 'Code should be in format AA 1234 5678 9BB',
      placeholder: 'AA 1234 5678 9BB',
    },
    image: {
      src: royalImage,
      width: 150,
      className: 'mt-2',
    },
  },
}

// Bloods Activation Step 1: Check gold barcode, redirect to consents
export default (props: {test_version: string}) => {
  const page_title = 'Enter your barcode & tracking number'
  const activate_check = '/api/dashboard/test-kits/check/'
  const activate_link = '/api/dashboard/activate/activating/'
  const consent_link = '/tests/private/consent/'
  const confirm_link = '/tests/private/confirm/'

  return (
    <BloodsBarcodeForm
      fields={fields}
      activate_link={activate_link}
      consent_link={consent_link}
      confirm_link={confirm_link}
      get_activate_check_link={(barcode: string) => `${activate_check}${barcode}/`}
      page_title={page_title}
      type="bloods"
      test_version={props.test_version}
      // Optional attributes
      show_warn
    />
  )
}
