import food from 'src/assets/icons/food.svg'
import exercise from 'src/assets/icons/Reports/Exercise.svg'

export const advice_groups = {
  eat_more: {color: 'nell-green', title: 'Eat More', icon: food},
  eat_less: {color: 'nell-red', title: 'Eat Less', icon: food},
  exercise: {color: 'nell-blue', title: 'Exercise', icon: exercise},
}

export const effect_data = {
  risk: {color: 'nell-purple-2', leftLabel: 'Lower Risk', rightLabel: 'Higher Risk', centreLabel: 'Medium Risk'},
  advantage: {
    color: 'nell-blue',
    leftLabel: 'Lower Advantage',
    rightLabel: 'Higher Advantage',
    centreLabel: 'Medium Advantage',
  },
  interest: {
    color: 'nell-orange',
    leftLabel: 'Lower Interest',
    rightLabel: 'Higher Interest',
    centreLabel: 'Medium Interest',
  },
}

export const getIntensity = (intensity, effect) => {
  if (effect === 'risk') {
    if (intensity === 0.0) {
      return 'low-intensity'
    } else if (intensity === 1.0) {
      return 'high-intensity'
    }

    return 'medium-intensity'
  }

  if (intensity === 1.0) {
    return 'low-intensity'
  } else if (intensity === 0.0) {
    return 'high-intensity'
  }

  return 'medium-intensity'
}
