import React from 'react'
import {useHistory, useLocation} from 'react-router'

import SwabImg from 'src/assets/images/Isohelix_Rapidri.jpg'
import {Form, Loading} from 'src/shared/reactstrap-toolbox'

export default ({
  fields,
  activate_link,
  consent_link,
  confirm_link,
  page_title,
  image = null,
  get_activate_check_link,
  type,
  test_version,
  image_width = null,
  image_height = null,
  show_warn,
}) => {
  const location = useLocation()
  const history = useHistory()
  const [form_data, setFormData] = React.useState({})
  const [loaded, setLoaded] = React.useState(false)
  const [assBarcodeType, setAssBarcodeType] = React.useState('')
  const [secondaryBarcode, setSecondaryBarcode] = React.useState(null)

  const test_id = new URLSearchParams(location.search).get('test_id')

  React.useEffect(() => {
    window.app.setTitle('Activate Kit')
    if (test_id) {
      window.app.requests
        .get(`/api/dashboard/activate/${test_id}/`, null, {expected_status: [200, 422, 404]})
        .then(r => {
          if (r.status === 200) {
            setFormData(r.data)
          }
          setLoaded(true)
        })
    } else {
      setLoaded(true)
    }
  }, [test_id])

  const execute = async data => {
    let {primary_barcode, postage_tracking} = data

    let checkResponse = await window.app.requests.get(
      `/api/dashboard/test-kits/check/${primary_barcode}/`,
      {},
      {
        expected_status: [200, 422, 400, 404],
      },
    )
    if (checkResponse.data !== null && checkResponse.data.secondary_barcode_type) {
      setAssBarcodeType(checkResponse.data['secondary_barcode_type'])

      // check if the secondary barcode matches the correct type of test
      if (
        (test_version === 'bloods' && checkResponse.data['secondary_barcode_type'] === 'urine') ||
        (test_version === 'sh' && checkResponse.data['secondary_barcode_type'] === 'lavender')
      ) {
        const testType = test_version === 'bloods' ? 'Blood' : 'Sexual Health'
        return {
          status: 400,
          data: {
            details: [
              {
                loc: ['primary_barcode'],
                msg: `To proceed with activating your ${testType} Test Kit, please ensure that you have selected the appropriate option on the activation page.`,
              },
            ],
          },
        }
      }
    }
    if (checkResponse.data !== null && checkResponse.data.secondary_barcode)
      setSecondaryBarcode(checkResponse.data['secondary_barcode'])

    if (checkResponse.status === 200) {
      //passed barcode check, mark it as activating
      checkResponse = await window.app.requests.post(
        activate_link,
        {barcode: primary_barcode, postage_tracking: postage_tracking, stage: 'barcode'},
        {
          expected_status: [200, 422, 400, 404],
        },
      )
    }

    return checkResponse
  }

  const afterSubmit = () => {
    let {barcode, primary_barcode} = form_data

    if (assBarcodeType === 'lavender' || assBarcodeType === 'blue' || assBarcodeType === 'urine') {
      history.push(
        `${confirm_link}${assBarcodeType}/${primary_barcode || barcode}` +
          (secondaryBarcode ? `?secondary_barcode=${secondaryBarcode}` : ''),
      )
    } else {
      history.push(`${consent_link}${primary_barcode || barcode}/`)
    }
  }

  if (!loaded) {
    return <Loading />
  }

  return (
    <div className="row justify-content-center pt-4">
      <div className="mt-3 p-0 col-10">
        <h1>{page_title}</h1>
      </div>

      <div className="row justify-content-center pt-4">
        <div className="col-10">
          <p className="text-muted">
            Please enter the 10 character barcode as it appears on the small sticker attached to the inside of your
            Instructions For Use leaflet.
          </p>
          {show_warn ? (
            <>
              <p className="text-muted">
                To ensure the stability of your sample for processing, you should only collect and return your sample
                <strong>– Monday – Thursday</strong>. Do not collect or return your samples on a weekend or Bank
                Holiday as there will be postal delays.
              </p>
            </>
          ) : null}
          {image && (
            <div className="text-center py-2">
              <img
                loading="eager"
                src={image || SwabImg}
                alt="Test Tube Number"
                className="img-fluid rounded-lg"
                width={image_width || 300}
                height={image_height || 300}
              />
            </div>
          )}
          <div className="activate-1-form">
            <Form
              fields={fields}
              form_data={form_data}
              onChange={setFormData}
              afterSubmit={afterSubmit}
              execute={execute}
              save_label="Confirm & Continue"
            />
          </div>
        </div>
      </div>
    </div>
  )
}
