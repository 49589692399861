import React, {useEffect, useState} from 'react'
import {Container} from 'reactstrap'

import {Loading, as_title} from 'src/shared/reactstrap-toolbox'
import {format_date, iconHelper} from 'src/utils'
import {LinkCard} from 'src/shared/LinkCard'
import LateralFlow from 'src/assets/icons/Platform/Lateral_Flow_Tests.svg'
import PCR from 'src/assets/icons/Platform/PCR_tests.svg'
import Buy from 'src/assets/icons/Platform/Buy_tests.svg'
import BookingList from './Phlebotomy/BookingList'

export default () => {
  const [tests, setTests] = useState(null)
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    window.app.setTitle('My Results')
    window.app.requests.get('/api/dashboard/tests/').then(r => {
      setTests(r.data.tests)
      setLoading(false)
    })
  }, [])

  if (loading) {
    return <Loading />
  }

  return (
    <Container className="mb-5">
      <div className="mt-4">
        <h1 className="text-left text-md-left">My Results</h1>
        <TestsList tests={tests} />
      </div>
    </Container>
  )
}

const TestsList = ({tests}) => {
  let storeURL = 'https://myhealthchecked.com/our-tests'

  if (process.env.REACT_APP_STAGING || process.env.NODE_ENV === 'development') {
    storeURL = 'https://shop-dev.myhealthchecked.com/our-tests'
  }
  const [bookings, setBookings] = useState()
  useEffect(() => {
    const fetchAll = async () => {
      const response = await window.app.requests.get(`/api/dashboard/registration/bookings/`, null, {
        expected_status: [200, 422, 404],
      })
      setBookings(response.data.bookings)
    }
    fetchAll()
  }, [])

  if (!tests?.length && !bookings?.length) {
    return (
      <div>
        <p className="text-muted">You don't have any tests yet.</p>
        <LinkCard to={storeURL} image={Buy} title="Buy Tests">
          <div className="text-muted">If you don't yet have a test, you can buy one now.</div>
        </LinkCard>
      </div>
    )
  }

  return (
    <div>
      <p className="text-muted">Below is a summary of your current tests.</p>

      {tests.map((test, i) => (
        <div key={i}>
          <SummaryCard test={test} />
        </div>
      ))}
      <BookingList bookings={bookings} tests={tests} />
    </div>
  )
}

// Get label for secondary card button, depending on assessment status
function getAssessmentButtonDetails(assessmentID, canEditAssessment, type, testID, status) {
  // status=rejected
  if (status.toLowerCase() === 'error' || status.toLowerCase() === 'rejected') {
    return {
      label: type?.startsWith('wellness-') ? 'Assessment' : assessmentID ? 'Edit Assessment' : 'Fill Assessment',
      link: `#`,
      appendClass: 'disabled',
    }
  }

  // Fixed link for Wellness tests
  if (type?.startsWith('wellness-')) {
    return {
      label: 'Assessment',
      link: '/assessment/wellness',
      appendClass: '',
    }
  }

  // If no assessment and it can't be edited = no label
  if (!assessmentID && !canEditAssessment) {
    return {label: 'Fill Assessment', link: `#`, appendClass: 'disabled'}
  }

  // If has read-only assessment, view it - Note: the API uses the testID (test-kit) ID
  if (assessmentID && !canEditAssessment) {
    return {
      label: 'View Assessment',
      link: type === '' ? '/assessment' : `/assessment/${testID}`,
      appendClass: '',
    }
  }

  // If has filled assessment and can edit, offer editing - Note: the API uses the testID (test-kit) ID
  if (assessmentID && canEditAssessment) {
    return {label: 'Edit Assessment', link: `/assessment/${testID}`, appendClass: ''}
  }

  // Note: the API uses the testID (test-kit) ID
  return {
    label: 'Fill Assessment',
    link: `/assessment/${testID}`,
    appendClass: '',
  }
}

const SummaryCard = ({test}) => {
  const {
    id,
    detail_link,
    type,
    type_name,
    booking_reference,
    primary_barcode,
    report_id,
    test_kit_type,
    status,
    status_name,
    status_ts,
    bid,
    rejected,
  } = test
  let image = PCR
  const isBloodTest = type?.startsWith('blood-')
  if (type?.endsWith('antigen')) image = LateralFlow
  if (type?.startsWith('wellness-')) {
    if (type === 'wellness-weight') {
      image = iconHelper('Weight')
    } else {
      image = iconHelper(type_name)
    }
  }
  if (isBloodTest) image = iconHelper(type_name)

  const {
    label: assessmentLabel,
    link: assessmentLink,
    appendClass: assessmentAppendClass,
  } = !bid && getAssessmentButtonDetails(test.assessment_id, test.can_edit_assessment, test.type, test.id, status_name)

  let link = `/${detail_link}/${id}/`

  if (status === 'report-ready' && report_id) {
    link = `/my-reports/${(test_kit_type || '')?.startsWith('blood-') ? 'blood/' : ''}${report_id}`
  }

  const displayBarcode = test_kit_type || type?.startsWith('wellness-') ? true : false

  let continueActivateBt = {}
  // If the test was activated but there is no test_ts, we'll show a button to continue the activation
  if (!bid && status === 'activating') {
    continueActivateBt = {
      button2_text: 'Return to My Activation',
      //button2_link: `/tests/private/time-administered/${primary_barcode}/${customer_id}/${id}`,
      button2_link: `/${detail_link}/resume/${id}/`,
      button2_append_class: assessmentAppendClass,
    }
  }

  let customerCareBtn,
    assessmentBtn = {}
  if (status === 'rejected' || status === 'error') {
    customerCareBtn = {
      button4_text: 'Contact Customer Care',
      button4_link: 'https://myhealthchecked.com/help', // whatever the link should be
      button4_target: '_blank',
    }
  } else {
    assessmentBtn = {
      button3_text: assessmentLabel,
      button3_link: assessmentLink, // whatever the link should be
      button3_append_class: assessmentAppendClass,
    }
  }

  return (
    <LinkCard
      // If item has test_kit_type, redirect to /test-kits/:id, otherwise /tests/:id
      to={link}
      image={image}
      ts={format_date(status_ts)}
      title={type_name || as_title(type)}
      button_text={status === 'report-ready' && report_id ? `View Results` : `View Test`}
      button_append_class={status === 'rejected' || rejected ? `disabled ` : status}
      {...continueActivateBt}
      {...assessmentBtn}
      {...customerCareBtn}
    >
      <div>
        <span className="text-muted d-none d-md-inline-block mr-2">
          {displayBarcode ? 'Barcode' : 'Booking reference'}:
        </span>
        <code className="inline-code">{displayBarcode ? primary_barcode : booking_reference}</code>
      </div>
      <div>
        <span className="text-muted d-none d-md-inline-block mr-2">Status:</span>
        <span className="text-dark font-weight-bold">{(!rejected && status_name) || (rejected && 'Rejected')}</span>
      </div>
    </LinkCard>
  )
}
