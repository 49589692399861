import React, {useEffect} from 'react'
import {ListGroup, ListGroupItem as BsListGroupItem, Row, Col, Collapse, Navbar, NavbarToggler} from 'reactstrap'
import {Link, useLocation, useParams} from 'react-router-dom'
import {Button} from 'reactstrap'
import {as_title} from 'src/shared/reactstrap-toolbox'
import logo from 'src/assets/icons/mhc_logo.svg'
import Boots from 'src/assets/images/Vector.svg'
import {ReactComponent as dashboard} from 'src/assets/icons/Dashboard.svg'
import {ReactComponent as results} from 'src/assets/icons/Results.svg'
import {ReactComponent as settings} from 'src/assets/icons/Settings.svg'
import {ReactComponent as assessment} from 'src/assets/icons/notepad.svg'
import {ReactComponent as support} from 'src/assets/icons/support.svg'
import {ReactComponent as booking} from 'src/assets/icons/link icon.svg'
import {getTestTypeValueFromUrl} from 'src/assessment_utils'

const ListGroupItem = ({to, active, title, icon}) => {
  const Icon = icon
  const isExternalLink = to?.startsWith('http') || to?.startsWith('https')
  return (
    <BsListGroupItem
      tag={isExternalLink ? 'a' : Link}
      href={isExternalLink ? to : undefined}
      to={!isExternalLink ? to : undefined}
      active={active}
      target={isExternalLink ? '_blank' : undefined}
      rel={isExternalLink ? 'noopener noreferrer' : undefined}
    >
      {icon ? <Icon width={25} height={25} className="float-left p-1 mr-2" /> : null}
      <div className="text-light">{title}</div>
    </BsListGroupItem>
  )
}

export default ({className, user}) => {
  const {test_type} = useParams()
  const location = useLocation()
  const [isOpen, setIsOpen] = React.useState(false)
  const [auth_menu, setAuthMenu] = React.useState(false)
  const [phlebotomy, setPhlebotomy] = React.useState(sessionStorage.getItem('phlebotomy') || false)
  const [unknownTest, setUnknownTest] = React.useState(sessionStorage.getItem('phlebotomy') || false)

  let menu_ = user ? auth_menu : []
  const path = location.pathname

  React.useEffect(() => {
    setIsOpen(false)
    setPhlebotomy(sessionStorage.getItem('phlebotomy') || false)
    setUnknownTest(getTestTypeValueFromUrl(sessionStorage.getItem('test_type')))
  }, [path])

  useEffect(() => {
    // Auth menu for phlebotomy service login
    phlebotomy && unknownTest !== 'unknown'
      ? setAuthMenu([
          {to: '/', name: 'Dashboard', icon: dashboard},
          {to: '/activate', name: 'Activate Test', icon: assessment},
          {
            to: `/bloods/boots/${getTestTypeValueFromUrl(sessionStorage.getItem('test_type'))}`,
            name: 'Boots appt.',
            icon: booking,
          },
          {to: '/tests', name: 'My Results', icon: results},
          {to: '/account', name: 'Account', icon: settings},
          {to: 'https://myhealthchecked.com/faq/blood-analysis-test', name: 'Support', icon: support},
        ])
      : setAuthMenu([
          {to: '/', name: 'Dashboard', icon: dashboard},
          {to: '/activate', name: 'Activate Test', icon: assessment},
          {to: '/tests', name: 'My Results', icon: results},
          {to: '/account', name: 'Account', icon: settings},
          {to: 'https://myhealthchecked.com/faq/blood-analysis-test', name: 'Support', icon: support},
        ])
  }, [phlebotomy, unknownTest])

  const toggle = () => setIsOpen(!isOpen)

  return (
    <Col className={`sidebar-background p-0 m-0 overflow-hidden sticky-top`}>
      <Row>
        <Col xs={2} md={1}>
          <div className="d-lg-none p-3">
            <NavbarToggler className="navbar-light" onClick={toggle} />
          </div>
        </Col>
        <div className="log-section">
          <div className="left-section">
            <Link to="/" className="">
              <img width={135} className="p-2 m-2" src={logo} alt="My Health Checked" />
            </Link>
          </div>
          {/* Show boots logo only for phletbotomist journey login */}
          {(phlebotomy || sessionStorage.getItem('phlebotomy')) && unknownTest !== 'unknown' && (
            <div className="right-section">
              <Link to={`/bloods/boots/${getTestTypeValueFromUrl(test_type || sessionStorage.getItem('test_type'))}`}>
                <img
                  width={80}
                  height={80}
                  className="boots-logo p-2 m-2 align-content-center"
                  src={Boots}
                  alt="Boots"
                />
              </Link>
            </div>
          )}
        </div>
      </Row>
      <Navbar expand="lg" className={'p-0'}>
        <Col className="p-0 m-0">
          <Collapse className="d-lg-none" isOpen={isOpen}>
            <div className="sidebar-background">
              <ListGroup className="menu text-nowrap">
                {menu_.map(m => (
                  <ListGroupItem
                    key={m?.to}
                    to={m?.to}
                    active={m?.to === path}
                    icon={m?.icon}
                    title={as_title(m?.name)}
                  />
                ))}

                {user ? (
                  <Button tag={Link} to="/logout/" className="logout-button">
                    Log out
                  </Button>
                ) : null}
              </ListGroup>
            </div>
          </Collapse>

          <div className="d-none d-lg-block">
            <ListGroup className="menu text-nowrap">
              {menu_.map(m => (
                <ListGroupItem
                  key={m?.to}
                  to={m?.to}
                  active={m?.to === path}
                  icon={m?.icon}
                  title={as_title(m?.name)}
                />
              ))}

              {user ? (
                <Button tag={Link} to="/logout/" outline className="logout-button">
                  Log out
                </Button>
              ) : null}
            </ListGroup>
          </div>
        </Col>
      </Navbar>
    </Col>
  )
}
