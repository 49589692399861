import React from 'react'
import {Container, Row, Col} from 'reactstrap'
import Scale from 'src/shared/Scale'
import {getIntensity} from 'src/constants'
import chevron from 'src/assets/icons/ExpandChevron.svg'
import {image_headers} from 'src/views/Reports/ReportImages'

export default ({name, tag, effect, value, leftLabel, centreLabel, rightLabel, isExpandable, isExpanded}) => {
  const icon = image_headers[tag]
  //const color = labelColour(leftLabel)
  const color = getIntensity(value, effect)

  return (
    <Row>
      <Col className="float-left col-2 h-100 pl-0 mr-lg-1 pl-lg-5">
        {name ? <img src={icon} alt="" width={100} height={100} className="float-left p-1" /> : null}
      </Col>
      <Col>
        <div className="text-left">
          <Container>
            <Row>
              <Col className="ml-4 pl-4">
                <h5 className="mt-3 mb-1">{name}</h5>
              </Col>
              <Col />
            </Row>
          </Container>
        </div>
        <div className="col-12 centered-column">
          <Container fluid>
            <Row>
              <Col className="justify-content-md-center">
                <Scale
                  className={`text-${color}`}
                  steps={2}
                  color={color}
                  leftLabel={leftLabel}
                  centreLabel={centreLabel}
                  rightLabel={rightLabel}
                  percentage={value * 100}
                />
              </Col>
              {isExpandable ? (
                <Col xs={1} md={2} className="p-0 p-md-2">
                  <img
                    src={chevron}
                    alt={isExpanded ? 'collapse' : 'expand'}
                    className={isExpanded ? 'chevron-collapse float-right' : 'chevron-expand float-right'}
                  />
                </Col>
              ) : null}
            </Row>
          </Container>
        </div>
      </Col>
    </Row>
  )
}
