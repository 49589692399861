import React, {useEffect, useState} from 'react'
import {Container} from 'reactstrap'
import {as_title} from 'src/shared/reactstrap-toolbox'
import {LinkCard} from 'src/shared/LinkCard'
import PCR from 'src/assets/icons/Platform/PCR_tests.svg'
import LateralFlow from 'src/assets/icons/Platform/Lateral_Flow_Tests.svg'
import {getTestNameFromType} from 'src/assessment_utils'
import {iconHelperBooking} from 'src/utils'

export default ({bookings, tests}) => {
  return (
    <Container className="mb-5">
      <div className="mt-4">
        <BookingsList bookings={bookings} tests={tests} />
      </div>
    </Container>
  )
}

const BookingsList = ({bookings, tests}) => {
  // Filter bookings to exclude those whose id exists in tests array
  const filteredBookings = bookings?.filter(booking => !tests.some(test => test.bid === booking.id))

  if (!tests?.length && !filteredBookings?.length) {
    return (
      <div>
        <p className="text-muted">You don't have any bookings yet.</p>
      </div>
    )
  }

  return (
    <div>
      {filteredBookings?.map((booking, i) => (
        <div key={i}>
          <SummaryCard booking={booking} />
        </div>
      ))}
    </div>
  )
}

const SummaryCard = ({booking, test}) => {
  const {id, registration_stage, test_type, status, booking_status} = booking
  const [resume_link, setResumeLink] = useState()
  let image = PCR
  const isBloodTest = test_type?.startsWith('blood-')
  if (test_type?.endsWith('antigen')) image = LateralFlow
  if (test_type?.startsWith('wellness-')) {
    if (test_type === 'wellness-weight') {
      image = iconHelperBooking('Weight')
    } else {
      image = iconHelperBooking(test_type)
    }
  }

  if (isBloodTest) image = iconHelperBooking(test_type)
  const resumeJourney = registration_stage => {
    switch (registration_stage) {
      case 'type-confirm':
        setResumeLink(`/bloods/boots/consent/${test_type}/${id}/`)
        break
      case 'consent':
        setResumeLink(`/bloods/boots/user-details/${test_type}/${id}/`)
        break
      case 'personal-details':
        setResumeLink(`/bloods/boots/assessment-form/${test_type}/${id}/1/`)
        break
      default:
        break
    }
  }

  useEffect(() => {
    resumeJourney(registration_stage)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registration_stage])

  let statusText =
    (registration_stage === 'assessment' || registration_stage === 'edit-details') && booking_status !== 'rejected'
      ? 'Registration Complete'
      : booking_status === 'rejected'
      ? 'Rejected'
      : booking_status === 'pending' && 'Pending'

  if (registration_stage === 'assessment' || registration_stage === 'edit-details') {
    booking_status !== 'rejected' && (statusText = 'Registration Complete')
  } else {
    if (booking_status === 'rejected') {
      statusText = 'Rejected'
    } else {
      statusText = 'Pending'
    }
  }
  // Check if registration stage is not 'assessment' or 'edit-details'
  const isDisabled = !['assessment', 'edit-details'].includes(registration_stage)
  let cardLink
  if (registration_stage === 'assessment' || registration_stage === 'edit-details') {
    booking_status !== 'rejected' && (cardLink = `/bloods/boots/success-page/${test_type}/${id}/`)
  } else {
    if (booking_status === 'rejected') {
      cardLink = null
    } else {
      cardLink = resume_link
    }
  }

  return (
    id !== test?.bid && (
      <LinkCard
        to={cardLink}
        image={image}
        title={as_title(getTestNameFromType(test_type))}
        button_text={statusText === 'Registration Complete' ? 'View Booking' : 'Continue'}
        button_append_class={
          status === 'rejected' ? 'disabled' : status || booking_status === 'rejected' ? 'disabled' : status
        }
        disabled={isDisabled} // Disable the LinkCard if not assessment or edit-details
      >
        <div>
          <span className="text-muted d-none d-md-inline-block mr-2">Registration Stage:</span>
          <span className="text-dark font-weight-bold">{registration_stage}</span>
        </div>
        <div>
          <span className="text-muted d-none d-md-inline-block mr-2">Status:</span>
          <span className="text-dark font-weight-bold">{statusText}</span>
        </div>
      </LinkCard>
    )
  )
}
