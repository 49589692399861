import React from 'react'
import {faCog} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

export default ({download_blob}) => {
  const [downloading, setDownloading] = React.useState(false)

  const download = async () => {
    setDownloading(true)
    setTimeout(() => {
      setDownloading(false)
    }, 3000)
  }

  return (
    <a
      href={`/api/certificates/${download_blob}/myhealthchecked_certificate.pdf`}
      onClick={download}
      download
      className={`btn btn-success btn-cog${downloading ? ' disabled' : ''}`}
    >
      Download PDF Certificate
      <FontAwesomeIcon icon={faCog} className="cog-loading fa-fw" />
    </a>
  )
}
