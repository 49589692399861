import React from 'react'
import {
  faCertificate,
  faClipboardList,
  faEdit,
  faEnvelope,
  faFlask,
  faPlus,
  faCamera,
} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {as_title} from 'src/shared/reactstrap-toolbox'
import {format_dt_ago} from 'src/utils'

export default ({updates}) => {
  if (!updates.length) {
    return null
  }
  return (
    <>
      <h2 className="pb-2">Test Updates</h2>
      <div className="test-updates-list">
        {updates.map(({id, ts, type}) => (
          <SingleUpdate key={id} ts={ts} type={type} />
        ))}
      </div>
    </>
  )
}

const shown_updates = {
  'test-created': {icon: faPlus},
  'test-dispatched': {icon: faEnvelope},
  'test-activating': {icon: faClipboardList},
  'test-activated': {icon: faClipboardList},
  'test-in-progress': {icon: faClipboardList},
  'test-result-photo': {icon: faCamera, label: 'Cassette Photo Taken'},
  'test-received-ok': {icon: faEnvelope},
  'test-processing': {icon: faFlask},
  'test-results': {icon: faCertificate},
  'test-user-edit': {icon: faEdit, label: 'Test Updated by User'},
  'test-uk-address-edit': {icon: faEdit, label: 'UK Address Updated by User'},
}

const SingleUpdate = ({ts, type}) => {
  const d = shown_updates[type]
  if (!d) {
    return null
  }
  const {label, icon} = d
  return (
    <div className="my-3 d-flex align-items-center">
      <div className="test-update-icon">
        <FontAwesomeIcon icon={icon} />
      </div>
      <span>
        <span className="text-muted">{format_dt_ago(ts)} &bull;</span> {label || as_title(type)}
      </span>
    </div>
  )
}
