import React, {useEffect, useState} from 'react'
import ReactMarkdown from 'react-markdown'
import {withRouter, RouteComponentProps, Link} from 'react-router-dom'
import {Alert, Button, Container} from 'reactstrap'
import {on_mobile, Error, message_toast, Loading} from 'src/shared/reactstrap-toolbox'
import {faArrowCircleRight, faMinusCircle} from '@fortawesome/free-solid-svg-icons'
import {getTestTypeValueFromUrl} from '../../assessment_utils'

interface Consent {
  consent_given: boolean
  consent_text: string
  test_kit_id: number
}

interface PhlebotomyConsentProps extends RouteComponentProps<{id: string; test_type: string}> {}

const PhlebotomyConsent: React.FC<PhlebotomyConsentProps> = ({history, match}) => {
  const [error, setError] = useState<string | undefined>()
  const [consent, setConsent] = useState<Consent | undefined>()
  const [checkedConsent, setCheckedConsent] = useState(false)
  const [checkedTerms, setCheckedTerms] = useState(false)
  const {id, test_type} = match.params

  const full_activate_link = `/bloods/boots/user-details/${getTestTypeValueFromUrl(test_type)}/${id}`

  useEffect(() => {
    const fetchConsent = async () => {
      const response = await window.app.requests.get(`/api/dashboard/registration/consent/?bid=${id}`, null, {
        expected_status: [200, 422, 404],
      })
      if (response.status !== 200) {
        if (!on_mobile && response.status !== 200) {
          message_toast({
            icon: faMinusCircle,
            title: 'Consent Form',
            message: 'Error loading Consent Form!' || 'Form contains errors',
            onClick: () => {},
            className: '',
          })
        }
      } else {
        setConsent(response.data)
      }
    }
    fetchConsent()
  }, [id])

  useEffect(() => {
    if (consent?.consent_given === true) {
      history.push(full_activate_link)
    }
  }, [consent, full_activate_link, history])

  const submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setError(undefined)

    if (!checkedConsent || !checkedTerms) {
      alert('You must tick both boxes to continue')
      return false
    }

    const response = await window.app.requests.post(
      '/api/dashboard/registration/consent/',
      {
        bid: id,
        consent_given: true,
      },
      {expected_status: [200, 422, 404]},
    )

    if (response.status !== 200) {
      setError(response.data.message || 'Saving consent failed. Try again.')
      return
    } else {
      if (!on_mobile && response.status === 200) {
        message_toast({
          icon: faArrowCircleRight,
          title: 'Consent Form',
          message: 'Consent saved!',
          onClick: () => {},
          className: '',
        })
      }
      history.push(`/bloods/boots/user-details/${getTestTypeValueFromUrl(test_type)}/${id}`)
    }
  }

  if (error && !consent) {
    return (
      <Container className="my-5">
        <Error error={{message: error}} className="" />
        <p className="">If you need assistance, please contact support.</p>
        <Button tag={Link} to="/tests/bloods/activate" color="primary" className="mr-2">
          Previous step
        </Button>
        <Button
          tag="a"
          href="https://myhealthchecked.com/contact"
          target="_blank"
          rel="noopener noreferrer"
          color="primary"
          outline
        >
          Contact us
        </Button>
      </Container>
    )
  }

  if (!consent) {
    return (
      <Container className="my-5">
        <Loading />
      </Container>
    )
  }

  return (
    <div className="row justify-content-center pt-4">
      <form className="mt-3 p-0 col-10" method="POST" onSubmit={e => submit(e)}>
        <h1>Declaration of Consent</h1>

        <div className="p-0">
          <ReactMarkdown children={consent.consent_text} />
        </div>
        <div className="m-3 p-2">
          <h3>Please confirm consent to the above conditions.</h3>

          <div className="m-2">
            <input
              id="consent_given"
              name="consent_given"
              type="checkbox"
              required={true}
              checked={checkedConsent}
              onChange={e => setCheckedConsent(e.target.checked)}
            />{' '}
            <label htmlFor="consent_given">I have read the above information and give consent</label>
            <br />
          </div>

          <div className="m-2">
            <input
              id="terms_read"
              name="terms_read"
              type="checkbox"
              required
              checked={checkedTerms}
              onChange={e => setCheckedTerms(e.target.checked)}
            />{' '}
            <label htmlFor="terms_read">
              I have read the{' '}
              <a href="https://myhealthchecked.com/privacy-policy" target="_blank" rel="noopener noreferrer">
                Privacy Policy
              </a>{' '}
              and agree to the{' '}
              <a href="https://myhealthchecked.com/terms-and-conditions" target="_blank" rel="noopener noreferrer">
                Terms &amp; Conditions
              </a>
            </label>
            <br />
          </div>

          {error && <Alert color="danger">{error}</Alert>}

          <Button color="primary" type="submit">
            Submit
          </Button>
        </div>
      </form>
    </div>
  )
}

export default withRouter(PhlebotomyConsent)
