import React from 'react'
import {StandaloneForm} from 'src/shared/reactstrap-toolbox'

const fields = {
  first_name: {required: true, title: 'First Name', min_length: 1, max_length: 30, value: 'test'},
  last_name: {required: true, title: 'Last Name', min_length: 1, max_length: 30},
  email: {required: true, type: 'email', max_length: 127, autocomplete: 'email'},
  recaptcha_token: {required: true, type: 'recaptcha'},
}

export default ({history}) => {
  React.useEffect(() => {
    window.app.setTitle('Boots - Best of the Best')
  }, [])

  return (
    <div>
      <div className="row justify-content-center pt-4">
        <div className="col-md-8">
          <h1>Boots - Antigen Test</h1>
          <p className="lead">
            Boots - Best of the Best Antigen Test. Please complete the fields below to start recording your antigen
            test results.
          </p>
          <StandaloneForm
            fields={fields}
            afterSubmit={r => history.push(`/tests/antigen/${r.data.blob}/instructions/`)}
            endpoint="/api/boots-botb/register/"
            save_label="Continue"
          />
        </div>
      </div>
    </div>
  )
}
