import {TEST_TYPES} from './views/Phlebotomy/Assessment/steps'

export const URL_TO_TEST_TYPE = {
  cholesterol: TEST_TYPES['blood-cholesterol'],
  'energy-profile': TEST_TYPES['blood-energy'],
  'general-health': TEST_TYPES['blood-general'],
  'heart-profile': TEST_TYPES['blood-heart'],
  thyroid: 'blood-thyroid', // Ensure this is consistent with your TEST_TYPES
  'vitamins-minerals': TEST_TYPES['blood-vitamins'],
  'weight-management': TEST_TYPES['blood-weight'],
}

export function getTestTypeValueFromUrl(urlValue) {
  // Check if the urlValue is a key in TEST_TYPES
  if (Object.values(TEST_TYPES).includes(urlValue)) {
    return urlValue // Return as is if it matches a TEST_TYPES value
  }

  // Otherwise, check URL_TO_TEST_TYPE mapping
  return URL_TO_TEST_TYPE[urlValue] || 'unknown' // Return 'unknown' if no match is found
}

export const TEST_TYPE_TO_NAME = {
  [TEST_TYPES['blood-cholesterol']]: 'Cholesterol Profile Test',
  [TEST_TYPES['blood-energy']]: 'Energy Profile Test',
  [TEST_TYPES['blood-general']]: 'General Health Test',
  [TEST_TYPES['blood-heart']]: 'Heart Profile Test',
  'blood-thyroid': 'Thyroid Profile Test', // Assuming 'blood-thyroid' is not part of TEST_TYPES
  [TEST_TYPES['blood-vitamins']]: 'Vitamins & Minerals Profile Test',
  [TEST_TYPES['blood-weight']]: 'Weight Management Test',
}

export function getTestNameFromType(testType) {
  return TEST_TYPE_TO_NAME[testType] || 'Unknown Test'
}
