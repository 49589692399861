import React from 'react'
import {useLocation} from 'react-router-dom'
import {Button, Row, Col, List, ListInlineItem, Modal, ModalBody} from 'reactstrap'
import {report_icons} from '../Reports/ReportImages'

const AdviceItem = ({label, icon, details}) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const location = useLocation()
  const path = location.pathname

  React.useEffect(() => {
    setIsOpen(false)
  }, [path])

  const handleClick = () => setIsOpen(!isOpen)

  return (
    <div className="mb-2 advice-item">
      <Modal isOpen={isOpen} toggle={handleClick}>
        <Row className="p-3">
          <Col>
            <h3 className="float-left m-1">{label}</h3>
          </Col>
          <Col>
            <Button className="float-right" onClick={handleClick}>
              x
            </Button>
          </Col>
        </Row>
        <div className="border-bottom" />
        <ModalBody>{details}</ModalBody>
      </Modal>

      <Button onClick={handleClick} className="advice-button">
        <img width={70} height={70} src={icon} alt={label} className="rounded-lg" />
        <p className="advice-label">{label}</p>
      </Button>
    </div>
  )
}

const AdviceSection = ({advisements, items}) => {
  return (
    <div>
      <p className="advice-label">(Click a recommendation icon below to learn more)</p>
      <List type="inline">
        {items.map(i => (
          <ListInlineItem>
            <AdviceItem label={advisements[i].name} icon={report_icons[i]} details={advisements[i].description} />
          </ListInlineItem>
        ))}
      </List>
    </div>
  )
}

export default ({firstName, advisements, advice}) => {
  const {eat_less, eat_more, exercise} = advice

  return (
    <div className="recommendation-section">
      {/*<ModalHover active={true} onHover={<h3>Hello world</h3>}>
                <div>Hover this</div>
            </ModalHover>
    */}
      {eat_more && eat_more.length > 0 ? (
        <div className="mt-3">
          <h2 className="mb-2">You should eat/drink more:</h2>
          <AdviceSection advisements={advisements} items={eat_more} />
        </div>
      ) : null}
      {eat_less && eat_less.length > 0 ? (
        <div className="mt-3">
          <h2 className="mb-2">You should eat/drink less:</h2>
          <AdviceSection advisements={advisements} items={eat_less} />
        </div>
      ) : null}
      {exercise && exercise.length > 0 ? (
        <div className="mt-3">
          <h2 className="mb-2">Exercise more:</h2>
          <AdviceSection advisements={advisements} items={exercise} />
        </div>
      ) : null}
    </div>
  )
}
