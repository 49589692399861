import {RouteComponentProps} from 'react-router-dom'
import React, {useCallback, useEffect, useState} from 'react'
import {Button, Container, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap'
import {Loading, StandaloneForm, as_title, message_toast} from 'src/shared/reactstrap-toolbox'
import {buildFormFields} from 'src/form_fields'
import {faArrowCircleRight} from '@fortawesome/free-solid-svg-icons'
interface MatchParams {
  barcode: string
  customer_id: string
  test_id: string
}

interface Props extends RouteComponentProps<MatchParams> {}

interface TestInfo {
  customer_id: number
  primary_barcode: string
  test_kit_id: number
  first_name: string
  last_name: string
  dob: string
  sex: string
  ethnicity: string
  identify_with_gender: boolean
  type: string
  status: string
  secondary_barcode?: string
  secondary_barcode_type: string
  home_postal_code?: string
}

export default function BloodsTestActivation({match, history}: Props) {
  const [test_info, setTestInfo] = useState({} as TestInfo)
  const [showFormError, setShowFormError] = useState(false)
  const [formErrorMessage, setFormErrorMessage] = useState('')
  const [exposureWarningDisplayed, setExposureWarningDisplayed] = useState(false)
  const toggleExposureWarning = () => {
    setExposureWarningOpen(false)
    setExposureWarningDisplayed(true)
  }

  const [exposureWarningOpen, setExposureWarningOpen] = useState(false)

  const {barcode} = match.params
  const form_fields = {
    test_ts: true,
  }

  const getTestDetails = useCallback(async () => {
    const url = `/api/dashboard/test-kits/activate/${barcode}/`

    const result = await window.app.requests.get(url, {}, {expected_status: [200, 422]})

    if (result.status === 200) {
      setTestInfo(result.data)
    }
  }, [barcode])

  // Get initial details
  useEffect(() => {
    window.app.setTitle('Activate Kit')
    getTestDetails()
  }, [barcode, getTestDetails])

  const handleSubmit = async (data: any) => {
    const isSexualHealthTest = test_info.type?.indexOf('sex-health') !== -1

    if (isSexualHealthTest && !exposureWarningDisplayed) {
      setExposureWarningOpen(true)
      return false
    }

    const selectedDateAndTime = new Date(data.test_ts)
    const selectedDateAndTimeTimestamp = Math.floor(selectedDateAndTime.getTime() / 1000)

    const currentDate = new Date()
    const oneHourFromNow = new Date(currentDate.getTime() + 60 * 60 * 1000)
    const oneHourFromNowTimestamp = Math.floor(oneHourFromNow.getTime() / 1000) // Convert to seconds

    let canContinue = true
    let errorMessage = ''

    // is the timestamp more than an hour from now
    if (selectedDateAndTimeTimestamp > oneHourFromNowTimestamp) {
      errorMessage = 'Timestamp cannot be more than one hour in the future.'
      canContinue = false
    }

    let test_kit_id = 0
    if (canContinue) {
      // Activates non-hormone kits
      const activate = await window.app.requests.post('/api/dashboard/test-kits/activate/', test_info, {
        expected_status: [200, 422, 404, 400],
      })

      if (activate.status !== 200) {
        message_toast({
          icon: faArrowCircleRight,
          title: 'Activated Kit',
          message: activate.data.message || 'Form contains errors',
          onClick: () => {},
          className: '',
        })
        setFormErrorMessage(activate.data.message || 'Form contains errors')
      }

      const stage = {
        barcode: test_info.primary_barcode,
        stage: 'test-taken',
        first_name: test_info.first_name,
        last_name: test_info.last_name,
      }

      // Update activation stage status
      await window.app.requests.post('/api/dashboard/activate/activating/', stage, {
        expected_status: [200, 422, 404, 400],
      })

      //submit to main-platform
      const timestamp = await window.app.requests.post(
        `/api/dashboard/test-kits/update-test-timestamp/${barcode}/`,
        data,
        {
          expected_status: [200, 422, 404, 400],
        },
      )
      // If status isn't equal to 200, show and set error
      if (timestamp.status !== 200) {
        setFormErrorMessage(timestamp.data.detail[0]?.msg || 'Form contains errors')
        setShowFormError(true)
        return false
      } else {
        test_kit_id = timestamp.data.test_kit_id
      }
    }

    setFormErrorMessage(errorMessage)
    if (!canContinue) {
      setShowFormError(true)
      return false
    } else {
      setShowFormError(false)
      history.push(`/assessment/${test_kit_id}/1`)
    }
  }

  const fields_definition = {...form_fields}
  let fields = buildFormFields(fields_definition)

  // If there are no test_info, show loader
  if (test_info === null) {
    return (
      <Container className="my-5">
        <Loading />
      </Container>
    )
  }

  return (
    <div className="row justify-content-center pt-4">
      <div className="mt-3 p-0 col-10">
        <h1>Activate your test</h1>
        <p className="text-muted">
          Test Type: <span className="text-dark font-weight-bold">{as_title(test_info.type)}</span>
        </p>
        <div className="pt-2">
          <StandaloneForm
            fields={fields}
            execute={handleSubmit}
            // afterSubmit={afterSubmit}
            save_label="Confirm & Continue"
          />
          {showFormError && <p className="text-danger text-right">{formErrorMessage}</p>}
        </div>
      </div>
      <Modal isOpen={exposureWarningOpen} toggle={toggleExposureWarning}>
        <ModalHeader toggle={toggleExposureWarning}>Warning</ModalHeader>
        <ModalBody>
          <p>
            When testing for sexually transmitted infections (STIs), some infections do not show up immediately after
            sex. The testing window, or the period of time after exposure you will get an accurate result, can vary by
            infection type. For the most accurate test results, we recommend that you follow the accurate testing
            windows listed below.
          </p>
          <p>
            <strong>Chlamydia</strong> - 2 weeks after exposure
          </p>
          <p>
            <strong>Gonorrhoea</strong> - 2 weeks after exposure
          </p>
          <p>
            <strong>HIV</strong> - 45 days after exposure
          </p>
          <p>
            <strong>Syphilis</strong> - 12 weeks after exposure
          </p>
          <p>
            If you've had high-risk exposure to either syphilis or HIV, we recommend repeating the test again in 12
            weeks.
          </p>
          <p>
            If you are unsure about whether you are within a testing window, you should do a test now and another test
            at a later date.
          </p>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggleExposureWarning}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}
