import React from 'react'
import {Loading, as_title, Error} from 'src/shared/reactstrap-toolbox'
import DisplayItems from 'src/shared/DisplayItems'
import Details from './Details'
import Result from './Result'
import GeneralTimeline from './GeneralTimeline'
import WellnessTimeline from './WellnessTimeline'
import Updates from './Updates'
import Image from './Image'

const summaryOngoingFields = {
  booking_reference: {fmt: 'inline-code'},
  barcode: {fmt: 'inline-code'},
  test_type_name: {title: 'Test Type'},
  status: {fmt: 'as-title'},
}

const summaryFinishedFields = {
  ...summaryOngoingFields,
  lab: {fmt: v => (v === 'mhc' ? 'MHC' : as_title(v))},
  assay: {fmt: v => (v === 'taq-path' ? 'TaqPath' : as_title(v))},
}

export default ({match, location}) => {
  const [test, setTest] = React.useState(null)
  const [error, setError] = React.useState(null)
  const [update_sentinel, setUpdateSentinel] = React.useState(0)
  const {test_id} = match.params
  const {hash} = location

  React.useEffect(() => {
    setTest(null)
    window.app.requests.get(`/api/dashboard/tests/${test_id}/`, null, {expected_status: [200, 404]}).then(r => {
      if (r.status === 200) {
        setTest(r.data)
        let wellness = r.data.info.test_type.startsWith('wellness-')
        let testTitle = wellness ? r.data.info.test_type_name : `${as_title(r.data.info.test_type)} Test`
        window.app.setTitle(testTitle)
        // Scroll to top when test ID has changed
        window.scrollTo(0, 0)
      } else {
        setError(`Test not found, id=${test_id}`)
      }
    })
  }, [test_id, update_sentinel])

  React.useEffect(() => {
    if (hash) {
      const clear = setInterval(() => {
        const el = document.getElementById(hash.substr(1))
        if (el) {
          clearInterval(clear)
          el.scrollIntoView()
        }
      }, 100)
    }
  }, [hash])

  if (error) {
    return <Error error={error} />
  } else if (!test) {
    return <Loading />
  }

  const update = () => setUpdateSentinel(v => v + 1)

  const {info, updates, uk_address, image_url, download_blob} = test
  const {test_type, test_type_name, status, result, result_reason, report_id, barcode_status} = info
  let summary_fields
  if (test_type.endsWith('antigen')) {
    summary_fields = summaryOngoingFields
    summary_fields.barcode.title = 'QR Code'
  } else {
    summary_fields = status === 'results-ready' ? summaryFinishedFields : summaryOngoingFields
  }

  let wellness = test_type.startsWith('wellness-')
  let testTitle = wellness ? test_type_name : `${as_title(test_type)} Test`

  return (
    <div className="pt-sm-4 pl-sm-1 pt-md-2 ">
      <div className="mb-4 mt-3 pt-3">
        <h1 className="mt-3 text-left text-md-left">{testTitle}</h1>
      </div>

      {(() => {
        if (wellness) {
          return <WellnessTimeline test_id={test_id} status={status} test_type={test_type} report_id={report_id} />
        } else if (test_type !== 'antigen-5-pack') {
          return (
            <>
              <GeneralTimeline test_id={test_id} status={status} test_type={test_type} />
              <Result
                result={result}
                download_blob={download_blob}
                result_reason={result_reason}
                barcode_status={barcode_status}
              />
              <Image image_url={image_url} />
            </>
          )
        }
      })()}

      <h2 className="pb-2">Test Summary</h2>
      <DisplayItems fields={summary_fields} data={info} />

      <Details test_id={test_id} info={info} uk_address={uk_address} update={update} />

      <Updates updates={updates} />
    </div>
  )
}
