import React, {useEffect, useState} from 'react'
import {RouteComponentProps} from 'react-router-dom'
import {Container, Button, Row, Col, UncontrolledTooltip} from 'reactstrap'
import _45MinCircle from 'src/assets/icons/Platform/45 mins circle.png'
import drinkWaterCircle from 'src/assets/icons/Platform/drink water circle.png'
import collectSampleGT from 'src/assets/icons/Platform/Frame 4699.png'
import newFingerLT from 'src/assets/icons/Platform/Frame 4701.png'
import sitcomfortably from 'src/assets/icons/Platform/Frame 4702.png'
import goldTubeCircle from 'src/assets/icons/Platform/Gold tube circle.png'
import milkingFingerCircle from 'src/assets/icons/Platform/milking finger circle.png'
import newLancetCircle from 'src/assets/icons/Platform/new lancet circle.png'
import puncturePositionCircle from 'src/assets/icons/Platform/Puncture position circle.png'
import washHandscircle from 'src/assets/icons/Platform/Wash hands circle.png'
import wipeFingerCircle from 'src/assets/icons/Platform/wipe finger circle.png'
import exerciseCircle from 'src/assets/icons/Platform/Exercise circle.png'

interface MatchParams {
  test_kit_id: string
}

interface Props extends RouteComponentProps<MatchParams> {}
var noOfTips
var show
var link

export default function BloodsCollectSample({match, history}: Props) {
  const {test_kit_id} = match.params
  const [primaryBarcode, setPrimaryBarcode] = useState('')
  const [secondaryBarcode, setSecondaryBarcode] = useState('')
  const [secondaryType, setSecondaryType] = useState('')

  useEffect(() => {
    window.app.requests.get(`/api/dashboard/test-kits/resume/${test_kit_id}`).then((r: any) => {
      setPrimaryBarcode(r.data.barcode)
      setSecondaryBarcode(r.data.secondary_barcode)
      setSecondaryType(r.data.secondary_type)
    })
  }, [test_kit_id])

  async function handleSubmit() {
    const stage = {
      barcode: primaryBarcode,
      stage: 'collect-sample',
      first_name: '',
      last_name: '',
    }
    await window.app.requests.post('/api/dashboard/activate/activating/', stage, {
      expected_status: [200, 422, 404, 400],
    })

    history.push(`/tests/private/time-administered/${primaryBarcode}/`)
  }

  if (secondaryBarcode && secondaryType === 'lavender') {
    noOfTips = '12'
    show = true
    link = `https://youtu.be/qUiiU0htNvc`
  } else if (secondaryBarcode && secondaryType === 'urine') {
    noOfTips = '10'
    show = false
    link = `https://youtu.be/7MZCX6BlZHE`
  } else {
    noOfTips = '10'
    show = false
    link = `https://youtu.be/XTv13r3o8CU`
  }

  return (
    <Container className="pt-2 pt-md-5 px-md-5">
      <h1 className="mt-4 page-title">Sample collection success: {noOfTips} essential tips</h1>
      <div className="pt-sm-4 pl-sm-1 pt-md-2 pb-5 bloods-text">
        Whilst you should always follow your instructions for use leaflet, it's important to remember these tips to
        ensure you collect a sample that is optimal for lab analysis. Stimulating blood flow is critical for effective
        sampling.
        <br />
        <br />
        <p>
          You can watch our ‘How to’ video{' '}
          <a className="video-link" href={link} target="blank">
            HERE
          </a>
        </p>
      </div>
      <Row>
        <Col lg={12}>
          <div>
            <div className="mb-4 pt-4">
              <h1 className="text-center text-md-left subtitle">Prep for better blood flow:</h1>
            </div>
            <Row>
              <Col>
                <img className="m-2" src={_45MinCircle} alt="45 Min circle"></img>
                <h1 className="mt-2 points-title">1. Give yourself time:</h1>
                <div className="mt-2 mb-2 points-text">
                  Please ensure you have left enough time to perform this test.{' '}
                  <strong>This can take up to 45 minutes.</strong>
                </div>
              </Col>
              <Col>
                <img className="m-2" src={drinkWaterCircle} alt="Drinking water circle"></img>
                <h1 className="mt-2 points-title">2. Drink plenty of water:</h1>
                <div className="mt-2 mb-2 points-text">
                  The day before: <strong>6-8</strong> glasses The morning of your test: <strong>2</strong> glasses
                </div>
              </Col>
              <Col>
                <img className="m-2" src={washHandscircle} alt="Wash hands circle"></img>
                <h1 className="mt-2 points-title">3. Warm your hands:</h1>
                <div className="mt-2 mb-2 points-text">
                  Soak in warm water for <strong>2-3</strong> minutes.
                </div>
              </Col>
              <Col>
                <img className="m-2" src={exerciseCircle} alt="Exercise circle"></img>
                <h1 className="mt-2 points-title">4. Do some light exercise:</h1>
                <div className="mt-2 mb-2 points-text">Go for a walk, swing your arms, or clench your fists.</div>
              </Col>
            </Row>
            <div>
              <div>
                <div className="mb-4 pt-4">
                  <h1 className="text-center text-md-left subtitle">Pointers when collecting your sample: </h1>
                </div>
                <Row>
                  <Col>
                    <img className="m-2" src={sitcomfortably} alt="Sit comfortable circle"></img>
                    <h1 className="mt-2 points-title">5. Sit comfortably:</h1>
                    <div className="mt-2 mb-2 points-text">
                      Place your arm in a <strong>downward position</strong>, using a table or armrest to support.
                    </div>
                    <div>
                      <p>
                        <span id="UncontrolledTooltip5" className="tooltip-text">
                          See Why
                        </span>
                      </p>
                      <UncontrolledTooltip placement="top" target="UncontrolledTooltip5">
                        This position encourages blood flow to your fingers.
                      </UncontrolledTooltip>
                    </div>
                  </Col>
                  <Col>
                    <img className="m-2" src={puncturePositionCircle} alt="Puncture position circle"></img>
                    <h1 className="mt-2 points-title">6. Puncture the side of your finger:</h1>
                    <div className="mt-2 mb-2 points-text">
                      Use your <strong>middle</strong> or <strong>ring finger</strong> on your non-dominant hand.
                    </div>
                    <div>
                      <p>
                        <span id="UncontrolledTooltip6" className="tooltip-text">
                          See Why
                        </span>
                      </p>
                      <UncontrolledTooltip placement="top" target="UncontrolledTooltip6">
                        These fingers have better blood flow and are less sensitive to pain.
                      </UncontrolledTooltip>
                    </div>
                  </Col>
                  <Col>
                    <img className="m-2" src={milkingFingerCircle} alt="Milking finger circle"></img>
                    <h1 className="mt-2 points-title">7. Do not squeeze your finger:</h1>
                    <div className="mt-2 mb-2 points-text">Use a milking action instead.</div>
                    <div>
                      <p>
                        <span id="UncontrolledTooltip7" className="tooltip-text">
                          See Why
                        </span>
                      </p>
                      <UncontrolledTooltip placement="top" target="UncontrolledTooltip7">
                        Squeezing can spoil samples and cause bruising.
                      </UncontrolledTooltip>
                    </div>
                  </Col>
                  <Col>
                    <img className="m-2" src={wipeFingerCircle} alt="Wipe finger circle"></img>
                    <h1 className="mt-2 points-title">8. Discard the first drop:</h1>
                    <div className="mt-2 mb-2 points-text">Wipe with a clean tissue.</div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
          <Row>
            <Col lg={6}>
              <h1 className="mt-2 subtitle gold">Gold tube first:</h1>
              <Row>
                <Col md={6}>
                  <img className="m-2" src={goldTubeCircle} alt="Gold tube circle"></img>
                  <h1 className="mt-2 points-title">9. Collect your sample for your golden tube:</h1>
                  <div className="mt-2 mb-2 points-text">
                    Position the finger over the collection tube to enable blood to drop into blood collection tube.
                  </div>
                </Col>
                <Col md={6}>
                  <img className="m-2" src={collectSampleGT} alt="Collect sample circle"></img>
                  <h1 className="mt-2 points-title">10. Gently mix:</h1>
                  <div className="mt-2 mb-2 points-text">Turn upside down 10 times.</div>
                </Col>
              </Row>
            </Col>
            {show === true && (
              <Col lg={6}>
                <h1 className="mt-2 subtitle lavender">Then lavender tube:</h1>
                <Row>
                  <Col md={6}>
                    <img className="m-2" src={newLancetCircle} alt="New finger circle"></img>
                    <h1 className="mt-2 points-title">11. Use a new finger:</h1>
                    <div className="mt-2 mb-2 points-text">New finger = new lancet.</div>
                    <div>
                      <p>
                        <span id="UncontrolledTooltip11" className="tooltip-text">
                          See Why
                        </span>
                      </p>
                      <UncontrolledTooltip placement="top" target="UncontrolledTooltip11">
                        Using a different finger helps prevent issues like blood clotting and contamination.
                      </UncontrolledTooltip>
                    </div>
                  </Col>
                  <Col md={6}>
                    <img className="m-2" src={newFingerLT} alt="New lacent circle"></img>
                    <h1 className="mt-2 points-title">12. Gently mix:</h1>
                    <div className="mt-2 mb-2 points-text">After each drop, swirl 10 times.</div>
                    <div>
                      <p>
                        <span id="UncontrolledTooltip12" className="tooltip-text">
                          See Why
                        </span>
                      </p>
                      <UncontrolledTooltip placement="top" target="UncontrolledTooltip12">
                        For anticoagulant tubes, mix the specimen periodically during collection to avoid clotting.
                      </UncontrolledTooltip>
                    </div>
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
      <Button className="m-4 float-right continue-btn" onClick={() => handleSubmit()}>
        Continue
      </Button>
    </Container>
  )
}
