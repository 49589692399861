import {faCheck, faTimes} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import moment from 'moment'
import React, {useEffect, useState} from 'react'
import Glucose from 'src/assets/icons/Tests/DNA_Glucose_Management.svg'
import Heart from 'src/assets/icons/Tests/DNA_Heart_Profile.svg'
import Intolerances from 'src/assets/icons/Tests/DNA_Intolerances.svg'
import Vitamins from 'src/assets/icons/Tests/DNA_Vitamins.svg'
import Weight from 'src/assets/icons/Tests/DNA_Weight_Management.svg'
import {as_title} from 'src/shared/reactstrap-toolbox'

// Blood test icons
import Cholesterol from 'src/assets/icons/Tests/Blood_Cholesterol.svg'
import Energy_Profile from 'src/assets/icons/Tests/Blood_Energy_Profile.svg'
import Female_Sexual_Health from 'src/assets/icons/Tests/Blood_Female_Sexual_Health.svg'
import General_Health_Test from 'src/assets/icons/Tests/Blood_General_Health_Test.svg'
import Heart_Profile from 'src/assets/icons/Tests/Blood_Heart_Profile.svg'
import Male_Sexual_Health from 'src/assets/icons/Tests/Blood_Male_Sexual_Health.svg'
import Menopause from 'src/assets/icons/Tests/Blood_Menopause.svg'
import PCOS from 'src/assets/icons/Tests/Blood_PCOS.svg'
import Thyroid from 'src/assets/icons/Tests/Blood_Thyroid.svg'
import Erectile_Dysfunction from 'src/assets/icons/Tests/Blood_Erectile.svg'
import Vitamins_and_Minerals from 'src/assets/icons/Tests/Blood_Vitamins_and_Minerals.svg'
import Weight_Management from 'src/assets/icons/Tests/Blood_Weight_Management.svg'

// Biomarkers icons
import Biomarker_Cholesterol from 'src/assets/icons/Biomarkers/Cholesterol.svg'
import Biomarker_Full_Blood_Count from 'src/assets/icons/Biomarkers/Full_Blood_Count.svg'
import Biomarker_Hormones from 'src/assets/icons/Biomarkers/Hormones.svg'
import Biomarker_Kidney from 'src/assets/icons/Biomarkers/Kidney.svg'
import Biomarker_Liver from 'src/assets/icons/Biomarkers/Liver.svg'
import Biomarker_Sexual_Health from 'src/assets/icons/Biomarkers/Sexual_Health.svg'
import Biomarker_Thyroid from 'src/assets/icons/Biomarkers/Thyroid.svg'
import Biomarker_Vitamins_and_Minerals from 'src/assets/icons/Biomarkers/Vitamins_and_Minerals.svg'
import {getTestNameFromType} from './assessment_utils'

const DTF = 'Do MMM, h:mma'

export const format_date_time = ts => moment(ts).format('Do MMM YYYY LT')
export const format_date = ts => moment(ts).format('Do MMM YYYY')
export const format_date_my = ts => moment(ts).format('MMMM YYYY')
export const format_dt = ts => moment(ts).format(DTF)
export const format_dt_ago = ts => {
  const m = moment(ts)
  return `${m.format(DTF)} (${m.fromNow()})`
}

export const clean_booking_reference = booking_reference =>
  booking_reference && booking_reference.replace(/[^a-z0-9]/gi, '').toUpperCase()

export const check_booking_reference = booking_reference => {
  if (!booking_reference.match(/^([a-z]{4}\d{8}|myhea\d{7})$/i)) {
    return 'Invalid booking reference format, should be 4 or 5 letters followed by 7 or 8 digits'
  }
}

export function format_value(value, fmt, choices) {
  if (typeof value === 'boolean' && !fmt) {
    const icon = value ? faCheck : faTimes
    return <FontAwesomeIcon icon={icon} />
  }
  if (!value) {
    return <span className="text-muted">&mdash;</span>
  }
  if (choices) {
    const selected = choices.find(c => c.value === value)
    return selected ? selected.label : value
  }
  if (fmt === 'datetime') {
    return format_dt_ago(value)
  } else if (fmt === 'date') {
    return format_date(value)
  } else if (fmt === 'json') {
    return <Json obj={JSON.parse(value)} />
  } else if (fmt === 'inline-code') {
    return <code className="inline-code">{value}</code>
  } else if (fmt === 'as-title') {
    return as_title(value)
  } else if (fmt === 'as-country') {
    if (window.country_lookup) {
      return window.country_lookup[value] || value
    }
    return value
  } else if (fmt === 'money') {
    return `£${value.toFixed(2)}`
  } else if (typeof fmt == 'function') {
    return fmt(value)
  } else if (fmt) {
    throw Error(`unknown fmt "${fmt}"`)
  }
  return value
}

const Json = ({obj}) => (
  <div className="code-block">
    <pre>
      <code>{JSON.stringify(obj, null, 2)}</code>
    </pre>
  </div>
)

export function back_or_forward(history, new_path) {
  if (window.last_path === new_path) {
    // sometimes when leaving a modal we want to do the equivalent of clicking the back button (e.g. when the new page
    // we're going to is the same as the one we were on previously)
    history.goBack()
  } else {
    // sometimes when the "modal closed" page is not the same as back, we want to "go forward to that page"
    history.push(new_path)
  }
}

const slugify = s => s && s.toLowerCase().replace(/\s/, '-')

export function track(event, label) {
  const data = {
    event: slugify(event),
    label: slugify(label),
    page_width: window.innerWidth,
    page_height: window.innerHeight,
  }
  if (process.env.NODE_ENV === 'development') {
    console.log('tracking', data)
  } else {
    fetch('/track/', {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(data),
    })
  }
}

export const useDebounce = (value, timeout) => {
  const [debouncedValue, setDebouncedValue] = React.useState(value)

  React.useEffect(() => {
    const handler = setTimeout(() => setDebouncedValue(value), timeout)
    return () => clearTimeout(handler)
  }, [value, timeout])

  return debouncedValue
}

// Get icon by test name
export const iconHelper = name => {
  let icon = null
  const iconList = [
    // DNA
    Glucose,
    Heart,
    Intolerances,
    Vitamins,
    Weight,

    // Blood
    Cholesterol,
    Energy_Profile,
    Male_Sexual_Health,
    General_Health_Test,
    Heart_Profile,
    Female_Sexual_Health,
    Menopause,
    PCOS,
    Thyroid,
    Erectile_Dysfunction,
    Vitamins_and_Minerals,
    Weight_Management,

    // Biomarkers
    Biomarker_Cholesterol,
    Biomarker_Full_Blood_Count,
    Biomarker_Hormones,
    Biomarker_Kidney,
    Biomarker_Liver,
    Biomarker_Sexual_Health,
    Biomarker_Thyroid,
    Biomarker_Vitamins_and_Minerals,
  ]

  const testNames = [
    // DNA
    'Glucose',
    'Heart',
    'Intolerances',
    'Vitamins',
    'Weight',

    // Blood
    'Cholesterol Profile Test',
    'Energy Profile',
    'Male Sexual Health',
    'General Health',
    'Heart Profile',
    'Female Sexual Health',
    'Menopause',
    'PCOS',
    'Thyroid',
    'Erectile Dysfunction',
    'Vitamins & Minerals Profile Test',
    'Weight Management',

    // Biomarkers
    'Biomarker Cholesterol',
    'Biomarker Full Blood Count',
    'Biomarker Hormones',
    'Biomarker Kidney',
    'Biomarker Liver',
    'Biomarker Sexual Health',
    'Biomarker Thyroid',
    'Biomarker Vitamins and Minerals',
  ]

  for (let i = 0; i < iconList.length; i++) {
    if (String(name).toLowerCase().includes(testNames[i].toLowerCase())) {
      icon = iconList[i]
    }
  }
  return icon
}

// Get icon by test name
export const iconHelperBooking = name => {
  let icon = null
  const iconList = [
    // DNA
    Glucose,
    Heart,
    Intolerances,
    Vitamins,
    Weight,

    // Blood
    Cholesterol,
    Energy_Profile,
    Male_Sexual_Health,
    General_Health_Test,
    Heart_Profile,
    Female_Sexual_Health,
    Menopause,
    PCOS,
    Thyroid,
    Erectile_Dysfunction,
    Vitamins_and_Minerals,
    Weight_Management,

    // Biomarkers
    Biomarker_Cholesterol,
    Biomarker_Full_Blood_Count,
    Biomarker_Hormones,
    Biomarker_Kidney,
    Biomarker_Liver,
    Biomarker_Sexual_Health,
    Biomarker_Thyroid,
    Biomarker_Vitamins_and_Minerals,
  ]

  const testNames = [
    // DNA
    'Glucose',
    'Heart',
    'Intolerances',
    'Vitamins',
    'Weight',

    // Blood
    'Cholesterol Profile Test',
    'Energy Profile',
    'Male Sexual Health',
    'General Health',
    'Heart Profile',
    'Female Sexual Health',
    'Menopause',
    'PCOS',
    'Thyroid',
    'Erectile Dysfunction',
    'Vitamins & Minerals Profile Test',
    'Weight Management',

    // Biomarkers
    'Biomarker Cholesterol',
    'Biomarker Full Blood Count',
    'Biomarker Hormones',
    'Biomarker Kidney',
    'Biomarker Liver',
    'Biomarker Sexual Health',
    'Biomarker Thyroid',
    'Biomarker Vitamins and Minerals',
  ]

  for (let i = 0; i < iconList.length; i++) {
    if (String(getTestNameFromType(name))?.toLowerCase().includes(testNames[i]?.toLowerCase())) {
      icon = iconList[i]
    }
  }
  return icon
}
export const BankHolidayHelper = () => {
  const [isBankHoliday, setIsBankHoliday] = useState(null)
  useEffect(() => {
    window.app.requests.get('/api/dashboard/is_bank_holiday/').then(r => {
      if (r.data.is_bank_holiday) {
        setIsBankHoliday(r.data.is_bank_holiday)
      }
    })
  }, [])

  return isBankHoliday
}

export const CanActivateBloodTestsHelper = () => {
  const [canActivateBloodTests, setCanActivateBloodTests] = useState(false)
  useEffect(() => {
    window.app.requests.get('/api/dashboard/can_activate_blood_tests/').then(r => {
      if (r.data.can_activate_blood_tests) {
        setCanActivateBloodTests(r.data.can_activate_blood_tests)
      }
    })
  }, [])

  return canActivateBloodTests
}

export const PrivateBetaFlag = () => {
  const [isBetaFlag, setIsBetaFlag] = useState()
  useEffect(() => {
    window.app.requests.get('/api/dashboard/account/').then(r => {
      setIsBetaFlag(r.data.ff_01_bloods_beta)
    })
  }, [])

  return isBetaFlag
}
