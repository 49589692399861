import React from 'react'
import {Link} from 'react-router-dom'
import {Loading} from 'src/shared/reactstrap-toolbox'

export default ({match, user}) => {
  const {token} = match.params
  const [testId, setTestId] = React.useState(null)
  const [testStatus, setTestStatus] = React.useState(null)

  React.useEffect(() => {
    window.app.requests.get(`/api/antigen/${token}/check/`).then(r => {
      setTestStatus(r.data.status)
      setTestId(r.data.test_id)
    })
  }, [token])

  if (!testId) {
    return <Loading />
  }
  return (
    <div className="row justify-content-center pt-4">
      <div className="col-md-8">
        <h1>Antigen Test Complete</h1>
        <div className="lead">
          <Message status={testStatus} />
          {user ? (
            <Link to={`/tests/${testId}/`} className="btn btn-primary">
              Return to Test
            </Link>
          ) : (
            <p>You can safely close this window.</p>
          )}
        </div>
      </div>
    </div>
  )
}

const Message = ({status}) => {
  if (status === 'processing') {
    return (
      <p>
        Your Antigen test is complete, you will receive an email notification that your results are ready within 4
        hours (weekdays: 8am to 4pm, weekends & bank holidays: from 9am to 3pm).
      </p>
    )
  } else if (status === 'results-ready') {
    return <p>Your Antigen test is complete, please check your email.</p>
  } else {
    return <p>Test in status "{status}"</p>
  }
}
