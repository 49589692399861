import React from 'react'
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap'
import {useAcceptCookies} from './useAcceptCookies'

const COOKIE_PREFERENCES_PAGE_PATH = 'https://myhealthchecked.com/manage-cookie-preferences'
const COOKIE_POLICY_PAGE_PATH = 'https://myhealthchecked.com/cookies'

// Based on the code from CookieNotice.tsx, useAcceptCookies.ts and gtm.ts from shopify-frontend
// but with the dependencies on type script removed.

export default () => {
  const {hasSettings, acceptAllCookies} = useAcceptCookies()

  // If we have the cookie settings render nothing
  if (hasSettings) return null

  // Build the URL with the redirect
  const cookieSettingsUrl = `${COOKIE_PREFERENCES_PAGE_PATH}?redirect=${encodeURIComponent(
    window.location?.toString(),
  )}`

  /* eslint-disable jsx-a11y/anchor-is-valid */
  return (
    <Modal isOpen={true}>
      <ModalHeader>We use cookies</ModalHeader>
      <ModalBody>
        <span className="block mb-6">
          MyHealthChecked uses cookies to provide the necessary functionality of the website, to track and deliver
          personalised ads and content, and therefore to improve your experience. By clicking “Accept All”, you agree
          to our website's cookie use as described in our{' '}
          <a href={COOKIE_POLICY_PAGE_PATH} className="underline">
            Cookie Policy
          </a>
          . You can change your cookie settings at any time by clicking on “Manage my Preferences”.
        </span>
      </ModalBody>
      <ModalFooter>
        <Button color="mhc-salmon" onClick={acceptAllCookies}>
          Accept All
        </Button>
        <Button color="info" href={cookieSettingsUrl}>
          Manage my preferences
        </Button>
      </ModalFooter>
    </Modal>
  )
}
