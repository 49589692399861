import React, {useEffect} from 'react'
import {useState} from 'react'
import {Link, Redirect, RouteComponentProps} from 'react-router-dom'
import {Button, Col, Row} from 'reactstrap'

import {as_title, Loading} from 'src/shared/reactstrap-toolbox'
import {AssessmentDetails} from './AssessmentDetails'

interface Assessment {
  assessment_values: any
  can_be_edited: boolean
  can_edit_assessment: boolean
  is_assessment_completed: boolean
  list_of_steps: string[]
  test_kit_details: {
    test_kit_name: string
    test_kit_type: string
  }
  personal: any
  test_type: string
}

interface MatchParams {
  bid: string
  test_type: string
  test_id: string
}

var boots_assessment: any
interface Props extends RouteComponentProps<MatchParams> {}

// Single Assessment view
// Check assessment status. If it's not filled, redirect to first step
// If it's filled and can be edited, offer editiing
// Otherwise, show all data here
function ViewAssessment({match}: Props) {
  const {test_type, test_id: bid} = match.params

  const [assessment, setAssessment] = useState<Assessment | null>(null)
  const [details, setDetails] = useState()
  const [loading, setLoading] = useState(!true)
  const [error, setError] = useState<string | null>(null)

  // Fetch assessment on each page load
  async function loadAssessment(bid: string) {
    try {
      setLoading(true)
      setError(null)

      // Load assessment
      const url = `/api/dashboard/registration/${bid}/status`
      const response = await window.app.requests.get(url, null, {
        expected_status: [200, 400],
      })

      if (response.status !== 200) {
        setError(response.data.message || response.data.details?.description)
      }

      // If assessment doesn't exist, throw error
      if (!response.data?.test_kit_details?.bid) {
        throw new Error('Assessment not found')
      }

      // Otherwise, set assessment
      boots_assessment = response.data
      setAssessment(response?.data)
    } catch (e: any) {
      setError(e?.message || e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    loadAssessment(bid)
    fetchDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bid])

  if (loading) {
    return <Loading />
  }
  const fetchDetails = async () => {
    const url = `/api/dashboard/registration/details/?bid=${bid}`
    const response = await window.app.requests.get(url, null, {
      expected_status: [200, 400],
    })
    if (response.status !== 200) {
      setError(response.data.message || response.data.details?.description)
    }
    setDetails(response.data)
  }

  if (error) {
    return (
      <div className="pt-sm-4 pl-sm-1 pt-md-2 ml-md-3 pb-5">
        <div className="">
          <Row>
            <Col xs={12}>
              <div className="mt-4">
                <h2>Ooops, some error happened</h2>
                {error && <p className="lead">{error}</p>}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    )
  }

  // If assessment isn't completed, just redirect to first step
  if (assessment?.is_assessment_completed === false) {
    return <Redirect to={`/bloods/boots/assessment-form/${test_type}/${bid}/1`} />
  }

  return (
    <div className="pt-sm-4 pl-sm-1 pt-md-2 ml-md-3 pb-5">
      {/* Header */}
      <Row tag="header">
        <Col xs={12}>
          <div className="my-4 d-sm-flex align-items-center">
            <h1>Assessment Details</h1>
            {assessment?.can_edit_assessment && (
              <Button
                className="ml-4"
                tag={Link}
                to={`/bloods/boots/assessment-form/${test_type}/${bid}/1`}
                color="primary"
              >
                Edit Assessment
              </Button>
            )}
          </div>
        </Col>
        <Col xs={12}>
          {/* If assessment can be edited, show appropriate message and button */}
          <p className="lead">
            Here are your health assessment details, related to{' '}
            <strong className="fw-600 text-primary">{assessment?.test_kit_details?.test_kit_name}</strong> (
            {assessment && as_title(assessment?.test_kit_details?.test_kit_type)}). Our doctor will prepare report
            basing on your answers.{' '}
            {assessment && assessment?.can_be_edited && `You can edit assessment until sample arrives to our lab.`}
          </p>
        </Col>
      </Row>

      {/* Assessment Data  */}
      {assessment && <AssessmentDetails {...(assessment! || boots_assessment!)} {...(details || {})} />}
    </div>
  )
}

export default ViewAssessment
