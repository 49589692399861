import React from 'react'
import {Spinner} from 'reactstrap'
import {RouteComponentProps, withRouter} from 'react-router-dom'
import {combine_classes} from './utils'

interface ErrorProps {
  error: {
    message?: string
  }
  className: string
}

export const Error = ({error, className}: ErrorProps) => (
  <div className={combine_classes(className, 'error')}>
    <h1>Error</h1>
    <p>{error.message || error.toString()}</p>
  </div>
)

interface NotFoundProps extends RouteComponentProps {
  children: any
  className: string
  url?: string
}

export const NotFound = withRouter(({children, className}: NotFoundProps) => (
  <div className={combine_classes(className, 'not-found')}>
    <h1>Page not found</h1>
    <p>The page requested by you does not exist.</p>
    {children}
  </div>
))

interface LoadingProps {
  className?: string
  children?: any
}

export const Loading = ({className = '', children = null}: LoadingProps) => (
  <div role="progressbar" className={combine_classes(className, 'loading')}>
    <div className="text-center py-2">
      <Spinner color="info" />
    </div>
    <div className="text-center text-muted">{children}</div>
  </div>
)
