import React from 'react'
import {Redirect, useLocation} from 'react-router-dom'
import {Loading} from 'src/shared/reactstrap-toolbox'
import {Button} from 'reactstrap'
import Notice from './Notice'

// null - check NODE_ENV and REACT_APP_STAGING,
const FORCE_GUARD = null
const REDIRECT_TO = '/'

export default ({children}) => {
  const [mode, setMode] = React.useState(null)
  const {pathname} = useLocation()

  React.useEffect(() => {
    let use_guard = FORCE_GUARD
    if (use_guard === null) {
      use_guard = process.env.NODE_ENV !== 'test' && (process.env.REACT_APP_STAGING || FORCE_GUARD)
    }
    if (use_guard) {
      setMode(localStorage.mode ? 'testing-enabled' : 'testing-not-enabled')
    } else {
      setMode('live')
    }
  }, [])

  if (mode === null) {
    return <Loading />
  }

  const live = mode === 'testing-enabled' || mode === 'live'
  if (pathname.startsWith('/enable-test-mode')) {
    return live ? <Redirect to={REDIRECT_TO} /> : <Enable />
  } else if (live) {
    return children
  } else {
    return <Notice title="Test System">Test system, for internal use only.</Notice>
  }
}

const Enable = () => {
  const onClick = () => {
    localStorage.mode = 'test-mode'
    window.location = REDIRECT_TO
  }
  return (
    <Notice title="Test Mode">
      <p>This system is for internal use only. Enable test mode so you can use this system.</p>
      <Button color="danger" onClick={onClick}>
        Enable Test Mode
      </Button>
    </Notice>
  )
}
