import React from 'react'
import {Row, Col} from 'reactstrap'

export const RenderGrid = ({fields, RenderField}) => {
  return (
    <Row>
      {Object.values(fields).map(field => (
        <Col key={field.name} md={6} lg={4} xl={4}>
          <div className="std-top-md">
            <RenderField field={field} />
          </div>
        </Col>
      ))}
    </Row>
  )
}

export const FieldCol = ({RenderField, field, lg = 4, md = 6, className}) => (
  <Col lg={lg} md={md}>
    <div className={`std-top ${className || ''}`}>
      <RenderField field={field} />
    </div>
  </Col>
)

export const FieldWide = ({RenderField, field, lg = 12, md = 12, className}) => (
  <Col lg={lg} md={md}>
    <div className={`std-top ${className || ''}`}>
      <RenderField field={field} />
    </div>
  </Col>
)

const required = true

const food_exclusion = {
  required,
  type: 'toggle',
  choices: [
    {value: 'dont_eat', label: "Don't Eat"},
    {value: 'eat', label: 'Eat'},
  ],
}
export const exclusion_field = (title, name) => Object.assign({}, food_exclusion, {title, name})

export const sex_help_text =
  'We respect your choice to identify as a gender other than the one assigned at birth. ' +
  'For the purpose of your genetic test, we are only asking about the sex assigned to you at birth to ' +
  'ensure the accuracy of your results.'
