import React from 'react'
import WellnessConsentForm from './WellnessConsentForm'

export default ({match, history, location}) => {
  const {barcode} = match.params
  const page_title = 'Wellness Consent'
  const activate_link = '/tests/wellness/activate/' + barcode + '/'

  return (
    <WellnessConsentForm
      history={history}
      location={location}
      activate_link={activate_link}
      page_title={page_title}
      barcode={barcode}
    />
  )
}
