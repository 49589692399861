import React, {useEffect, useState, useCallback} from 'react'
import {Link, Redirect, RouteComponentProps, withRouter} from 'react-router-dom'
import {Row, Col, Button} from 'reactstrap'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import {WithContext, Form, Loading, message_toast} from 'src/shared/reactstrap-toolbox'
import {ProgressBar} from 'src/shared/ProgressBar'
import {getStepsData, Step, StepName} from './steps'
import {faArrowCircleRight, faCog, faSmile} from '@fortawesome/free-solid-svg-icons'

type AssessmentState = 'NEW' | 'EDITABLE' | 'READONLY'

type StepStatus = 'completed' | 'invalid'

interface FormButtonsProps {
  state: any
  form_props: any
  submitButtonRef: () => any
}

const FormButtons = ({state, form_props, submitButtonRef}: FormButtonsProps) => (
  <footer className="d-flex justify-content-between w-100 mt-4">
    <div className={''}>
      {form_props.currentStep > 1 && (
        <Button
          tag={Link}
          to={`/bloods/boots/assessment-form/${form_props.testType}/${form_props.bid}/${form_props.currentStep - 1}/`}
          className="mb-1"
        >
          Previous <span className="d-none d-sm-inline">Step</span>
        </Button>
      )}
    </div>

    <div className={form_props.form_footer_class || 'text-right'}>
      <Button
        type="submit"
        color="primary"
        disabled={state.disabled || form_props.disabled}
        className="btn-cog mb-1"
        innerRef={submitButtonRef}
      >
        {form_props.save_label || 'Save'}
        <FontAwesomeIcon icon={faCog} className="cog-loading fa-fw" />
      </Button>
      {form_props.showEditButton && (
        <Button
          className="ml-3 mb-1"
          disabled={state.disabled || form_props.disabled}
          onClick={() => form_props.goToSummary(state)}
        >
          <span className="d-none d-sm-inline">Go to </span>Summary
        </Button>
      )}
    </div>
  </footer>
)

const initialData = {
  body: {units: 'Metric'},
  medical_conditions: {condition: []},
  medications: {medications: []},
}

interface MatchParams {
  step: string
  test_type: string
  bid: string
  test_id: string
}

interface AssessmentFormI extends RouteComponentProps<MatchParams> {
  user: any
}

interface TestInfo {
  customer_id: number
  primary_barcode: string
  test_kit_id: number
  first_name: string
  last_name: string
  dob: string
  sex: string
  ethnicity: string
  identify_with_gender: boolean
  type: string
  status: string
  secondary_barcode?: string
  secondary_barcode_type: string
  home_postal_code?: string
}

// Assessment form
const PhlebotomyAssessment = ({user, match, history, location}: AssessmentFormI) => {
  const [formData, setFormData] = useState<{[key in StepName]?: any}>(initialData)
  const [loading, setLoading] = useState(false)
  const [testType, setTestType] = useState<string>()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [test_info, setTestInfo] = useState({} as TestInfo)
  const [assessmentState, setAssessmentState] = useState<AssessmentState>('NEW')
  const [steps, setSteps] = useState<{[key in StepName]?: Step}>({})
  const [stepsStatus, setStepsStatus] = useState<{[key: number]: StepStatus}>({1: 'completed'})
  // eslint-disable-next-line no-unused-vars
  const [isDirty, setDirty] = useState<boolean>(false)
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState<AppRequestError>({})

  const getStepNumber = useCallback(
    (): number => (match.params.step ? parseInt(match.params.step) : 1),
    [match.params.step],
  )

  // Check if form has unfilled steps before current step
  const hasUnfilledPreviousSteps = useCallback(() => {
    // Loop through previous steps. If one of them doesn't have status 'completed',
    // return true (has unfilled steps)
    for (let i = 1; i < getStepNumber(); i++) {
      if (stepsStatus[i] !== 'completed') {
        return true
      }
    }

    return false
  }, [getStepNumber, stepsStatus])

  const {test_type, test_id: bid, step} = match.params

  // Get assessment details from API
  const getAssessmentDetails = useCallback(async () => {
    const url = `/api/dashboard/registration/${bid}/status`
    setLoading(true)

    const response = await window.app.requests.get(url, null, {
      expected_status: [200, 400],
    })

    if (response.status !== 200) {
      setError({title: response.data.message, description: response.data.details?.description})
      setLoading(false)
      return
    }

    const {test_kit_details, can_be_edited, is_assessment_completed, assessment_values, list_of_steps} = response!.data
    setTestType(test_kit_details?.test_kit_type)

    // Redirect to test kit if assessment can't be edited
    // and wasn't filled
    if (!can_be_edited && !is_assessment_completed) {
      history.push(`/test-kits/${test_kit_details?.test_kit_id}`)
      return
    }

    // Redirect to test kit if assessment can't be edited
    // and was previously filled
    // if (!can_be_edited && is_assessment_completed) {
    //   history.push(`/assessment/${test_kit_details?.test_kit_id}`)
    //   return
    // }

    // Set assessment state (new/editable/read-only)
    if (can_be_edited) {
      setAssessmentState('EDITABLE')
    }

    if (is_assessment_completed === false) {
      setAssessmentState('NEW')
    }

    const newSteps = getStepsData(list_of_steps)

    setSteps(newSteps)

    // Mark steps as completed when opened assessment for edit
    if (is_assessment_completed && can_be_edited) {
      const updatedStepsStatus: {[key: number]: StepStatus} = {}

      Object.keys(newSteps).forEach((_, index) => {
        updatedStepsStatus[index + 1] = 'completed'
      })

      setStepsStatus(updatedStepsStatus)
    }

    if (assessment_values?.length) {
      // Prefill form with saved data
      // Set correct initial data for medications and medical_conditions
      assessment_values[0].medications.is_taking = assessment_values[0].medications.medications?.length ? 'Yes' : 'No'
      assessment_values[0].medical_conditions.was_diagnosed = assessment_values[0].medical_conditions.condition?.length
        ? 'Yes'
        : 'No'

      setFormData(assessment_values[0])
    }

    // If it's new assessment, opened middle step and previous
    // steps aren't filled, redirect to assessment start
    if (!is_assessment_completed && hasUnfilledPreviousSteps()) {
      history.push(`/bloods/boots/assessment-form/${test_type}/${bid}/1`)
    }
    setLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasUnfilledPreviousSteps, history, bid])

  // const getStepName = useCallback(() => Object.keys(steps)[getStepNumber() - 1], [getStepNumber, steps])
  const getStepName = (): StepName => {
    const stepNames: StepName[] = Object.keys(steps) as StepName[]
    return stepNames[getStepNumber() - 1]
  }

  // Block navigation to other parts of the app if form is dirty.
  // Allow navigating between assessment steps

  const isOnLastStep = location.pathname === `/assessment-form/${test_type}/${bid}/${step}` // Replace with actual condition to check if on last step

  useEffect(() => {
    const unblock = history.block(location => {
      const navigatingAway = !location.pathname.includes('/assessment-form/')

      // Allow navigation if on the success page
      if (location.pathname.includes('/success-page')) {
        unblock()
        return
      }

      // Allow navigation if not on the last step or form is not dirty
      if (!navigatingAway || !isDirty || isOnLastStep) {
        unblock()
        return
      }

      // If form is dirty and user agreed to navigate,
      // unblock navigation
      if (
        isDirty &&
        window.confirm('Are you sure you want to exit? Complete the assessment form to get a personalized report.')
      ) {
        unblock()
        return
      }

      // Otherwise keep blocking
      return false
    })

    return unblock
  }, [isDirty, history, location, isOnLastStep])

  useEffect(() => {
    window.app.setTitle('MyHealthChecked Assessment')
    getAssessmentDetails()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bid])

  // Prevent navigating away when assessment isn't finished
  const askBeforeLeavingDirtyForm = useCallback(
    (event: BeforeUnloadEvent) => {
      if (isDirty) {
        event.preventDefault()
        return (event.returnValue = 'Are you sure you want to exit?')
      }
    },
    [isDirty],
  )

  useEffect(() => {
    // Show modal if form has been touched to prevent loosing data
    window.addEventListener('beforeunload', askBeforeLeavingDirtyForm)
    return () => {
      window.removeEventListener('beforeunload', askBeforeLeavingDirtyForm)
    }
  }, [isDirty, askBeforeLeavingDirtyForm])

  // Handle step change (used in progressbar)
  const handleStepChange = (stepNumber: number) => {
    // Disable navigating when it is new assessment AND
    // if new (or previous) step number isn't already completed
    if (
      assessmentState === 'NEW' &&
      stepsStatus[stepNumber] !== 'completed' &&
      stepsStatus[stepNumber - 1] !== 'completed'
    ) {
      return
    }

    setError({})

    history.push(`/bloods/boots/assessment-single/${bid}/${stepNumber}/`)
  }

  // Save assessment, show success message and redirect to main assessment page
  const saveAssessment = async () => {
    const complete_url = `/api/dashboard/registration/${bid}`
    const data: {[key: string]: any} = {}
    // Go through each step and clean up unnecessary data
    Object.keys(formData).forEach((step: string) => {
      data[step] = cleanUpData(formData[step as StepName], step)
      if (!Object.keys(data[step]).length) {
        delete data[step]
      }
    })

    setError({})
    const response = await window.app.requests.post(complete_url, data, {expected_status: [200, 422]})
    if (response.status === 422) {
      const invalidSteps = response.data.detail.map((row: any) => row.loc[1])
      const updatedStepsStatus = stepsStatus

      // Check which step is invalid
      invalidSteps.forEach((step: StepName) => {
        const index = Object.keys(steps).findIndex(key => key === step)
        if (index >= 0) {
          updatedStepsStatus[index + 1] = 'invalid'
        }
      })
      setStepsStatus(updatedStepsStatus)

      setError({title: response.data?.message || 'Saving assessment failed'})
    } else if (response.status !== 200) {
      console.error('error submitting assessment:', response)
      setError({title: response.data?.message || 'Saving assessment failed'})
    } else {
      setStepsStatus({...stepsStatus, [getStepNumber()]: 'completed'})
    }
  }

  // Handle form data change
  const handleDataChange = (newData: {[key: string]: any}) => {
    setFormData({...formData, [getStepName()]: newData})
    setDirty(true)
  }

  // Remove unnecessary data before submitting
  const cleanUpData = (data: {[key: string]: any}, step: string): {[key: string]: any} => {
    if (!data) data = {}

    // Remove extra data from medications step
    if (step === 'medications') {
      if (data.is_taking === 'No') {
        data.medications = []
        delete data.details
      }
      if (data.is_taking) {
        delete data.is_taking
      }
    }

    // Remove extra data from medical conditions step
    if (step === 'medical_conditions') {
      if (data.was_diagnosed === 'No') {
        data.condition = []
        delete data.other_description
      }
      if (data.was_diagnosed) {
        delete data.was_diagnosed
      }
    }

    // Remove empty values
    Object.keys(data).forEach(key => {
      if (!data[key]) {
        delete data[key]
      }

      // Get rid of empty strings
      const isEmptyString = typeof data[key] === 'string' && data[key].trim() === ''
      if (isEmptyString) {
        delete data[key]
      }
    })

    return data
  }

  //To check validity of form and prevent redirection in case of Go To Summary button click
  const goToSummary = () => {
    const formElement = document.querySelector('form') // Adjust the selector based on your form's structure
    if (formElement) {
      if (formElement.checkValidity()) {
        // Form is valid, proceed with navigation
        handleSubmit(formData[getStepName()], true)
      } else {
        // Form is invalid, display error messages or handle accordingly
        formElement.reportValidity()
        // Optionally, scroll to the first invalid field
        const invalidField = formElement.querySelector(':invalid')
        if (invalidField) {
          invalidField.scrollIntoView({behavior: 'smooth'})
        }
      }
    }
  }

  // Handle form submit
  const handleSubmit = async (rawData: {[key: string]: any}, goToSummary: boolean) => {
    const data = cleanUpData(rawData, getStepName())

    // If it's read-only assessment, we're not submitting anything
    if (assessmentState === 'READONLY') {
      return
    }

    // Also if it's first 'start' step, no need to submit
    if (getStepName() === 'start') {
      return
    }

    // If step is correct and it was last step, submit
    // Or if we're editing assessment and step was correct, save everything
    if (getStepNumber() === Object.keys(steps).length) {
      await saveAssessment()

      return
    }

    const stepUrl = `/api/dashboard/registration/validate/${bid}/${getStepName()}/`

    const responseStep = await window.app.requests.post(stepUrl, data, {expected_status: [200, 400, 422]})
    setError({})

    if (responseStep.status !== 200) {
      let message = 'Unknown error happened'
      if (responseStep.status === 422) message = 'Please fill the form correctly'
      if (responseStep.data.message) message = responseStep.data.message
      setError({title: message})
      setStepsStatus({
        ...stepsStatus,
        [getStepNumber()]: 'invalid',
      })

      return responseStep
    }

    // Mark step as completed
    setStepsStatus({...stepsStatus, [getStepNumber()]: 'completed'})

    if (goToSummary) {
      const lastStepNumber = Object.keys(steps).length
      history.push(`/bloods/boots/assessment-form/${test_type}/${bid}/${lastStepNumber}`)
    }

    return responseStep
  }

  // Get list of invalid steps numbers and titles
  const getInvalidSteps = () => {
    // Filter steps with status 'invalid', convert numbers to int
    const invalidSteps = Object.keys(stepsStatus)
      .filter(s => stepsStatus[parseInt(s)] === 'invalid')
      .map(s => parseInt(s, 10))

    return invalidSteps.map(number => {
      const stepName = Object.keys(steps)[number - 1] as StepName

      return {
        stepNumber: number,
        stepTitle: steps[stepName]?.title || `Step ${number - 1}`,
      }
    })
  }

  const afterSubmit = () => {
    if (error?.title) {
      return
    }

    // If current step is last step, go to the summary
    const isLastStep = getStepNumber() === Object.keys(steps).length
    if (isLastStep) {
      history.push(`/bloods/boots/success-page/${test_type}/${bid}`)

      message_toast({
        icon: faSmile,
        title: 'Assessment Completed',
        message: 'All steps completed successfully',
        onClick: () => {},
        className: '',
      })
      window.app.reAuthenticate()

      // Remove beforeunload event listener, when assessment is saved,
      // we don't need to prevent navigation anymore
      window.removeEventListener('beforeunload', askBeforeLeavingDirtyForm)

      // Active hormones kits
      const hormoneKits = ['blood-menopause', 'blood-polycystic', 'blood-erectile']

      if (hormoneKits.includes(test_info.type)) {
        const activate = window.app.requests.post('/api/dashboard/test-kits/activate/', test_info, {
          expected_status: [200, 422, 404, 400],
        })

        if (activate.status !== 200) {
          message_toast({
            icon: faArrowCircleRight,
            title: 'Activated Kit',
            message: activate.data.message || 'Form contains errors',
            onClick: () => {},
            className: '',
          })
          setError(activate.data.message || 'Form contains errors')
        }
      }

      return
    }

    // Otherwise go to next step
    const next_url = `/bloods/boots/assessment-form/${test_type}/${bid}/${getStepNumber() + 1}/`

    history.push(next_url)
    window.scrollTo(0, 0)
  }

  if ((!user || !testType) && error) {
    return (
      <div>
        <h1>{error.title}</h1>
        <p>{error.description || null}</p>
      </div>
    )
  }

  if (!user || !testType || loading) {
    return <Loading />
  }

  const current_step: Step | undefined = steps[getStepName()]

  const Before = current_step?.before || null

  // If step doesn't exist, redirect to the beginning
  if (!current_step) {
    return <Redirect to={`/bloods/boots/assessment-form/${test_type}/${bid}/1`} />
  }

  // If form is readonly,
  if (assessmentState === 'READONLY') {
    Object.keys(current_step.fields).forEach((f: string) => {
      current_step.fields[f].disabled = true
      if (current_step.fields[f]?.subfields) {
        Object.keys(current_step.fields[f]?.subfields).forEach(sf => {
          current_step.fields[f].subfields[sf].disabled = true
        })
      }
    })
  }

  return (
    <div className="pt-sm-4 pl-sm-1 pt-md-2 ml-md-3 pb-5">
      <div className="assessment">
        <Row>
          <Col xs={12}>
            <div className="mt-4">
              <ProgressBar
                steps={Object.keys(steps)}
                currentStep={getStepNumber()}
                handleClick={handleStepChange}
                stepsStatus={stepsStatus}
              />
            </div>
          </Col>
        </Row>
        {Before ? <Before /> : null}
        <Row className="justify-content-center" style={{maxWidth: '60ch', marginInline: 'auto'}}>
          <Col xs={12}>
            <div className="mt-5 mb-4">
              <h1 className="">{current_step.title}</h1>
            </div>
          </Col>
          <Col xs={12}>
            <Form
              form_error={error?.title}
              // Allow untouched form if form is readonly or it's first step
              allow_empty={assessmentState === 'READONLY' || ['start', 'summary'].includes(getStepName())}
              fields={current_step.fields}
              form_data={{...formData[getStepName()]}}
              invalidSteps={getInvalidSteps()}
              goToSummary={() => {
                goToSummary()
              }}
              execute={handleSubmit}
              RenderFields={current_step.field_render || null}
              afterSubmit={() => afterSubmit()}
              onChange={handleDataChange}
              save_label={Object.keys(steps).length === getStepNumber() ? 'Submit Assessment' : 'Continue'}
              Buttons={FormButtons}
              showEditButton={
                getStepNumber() > 1 && getStepNumber() < Object.keys(steps).length && assessmentState === 'EDITABLE'
              }
              currentStep={getStepNumber()}
              stepsStatus={stepsStatus}
              bid={bid}
              testType={testType}
            />
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default WithContext(withRouter(PhlebotomyAssessment))
