import React from 'react'
import {TEST_TYPES} from 'src/views/Assessment/steps'

interface Choice {
  value: string
  label: string
}

type FieldType = 'toggle' | 'number' | 'radio' | 'select' | 'checkboxes' | 'textarea' | 'text' | 'date'

export interface Field {
  title: string
  name?: string
  type?: FieldType
  before?: () => JSX.Element
  choices?: Choice[]
  help_text?: () => string | JSX.Element
  max_length?: number
  max?: number
  min?: number
  required?: boolean
  step?: number
  className?: string
  disabled?: boolean
}

export interface Fieldset {
  [name: string]: {
    title: string
    disabled?: boolean
    subfields: {
      [name: string]: Field
    }
  }
}

// For testing API validations, set `required` to false
// Otherwise we'd have to fill each step fully
const required = true

const yesNoChoices: Choice[] = [
  {value: 'Yes', label: 'Yes'},
  {value: 'No', label: 'No'},
]

// Body fields (height, weight)
const bodyFields: Fieldset = {
  body: {
    title: 'Body',
    subfields: {
      units: {
        title: 'Units',
        required,
        type: 'toggle',
        choices: [
          {value: 'Metric', label: 'Metric'},
          {value: 'Imperial', label: 'Imperial'},
        ],
        name: 'units',
      },
      height: {
        title: 'Height',
        required,
        type: 'number',
        name: 'height',
        max_length: 4,
        step: 0.1,
      },
      weight: {
        title: 'Weight',
        required,
        type: 'number',
        name: 'weight',
        max_length: 4,
        step: 0.1,
      },
    },
  },
}

// Smoking fields
const smokingFields: Fieldset = {
  smoking: {
    title: 'Smoking',
    subfields: {
      smoking: {
        title: 'Do you smoke cigarettes?',
        required,
        type: 'toggle',
        choices: [
          {value: 'Yes', label: 'Yes'},
          {value: 'No', label: 'No / I quit more than 6 months ago'},
          {value: 'Recently quit', label: 'I quit in the past 6 months'},
        ],
        name: 'smoking',
      },
      cigarettes_per_day: {
        title: 'How many cigarettes do you (or did you) smoke each day?',
        required,
        type: 'radio',
        choices: [
          {value: '0 - 5', label: '0 - 5'},
          {value: '6 - 10', label: '6 - 10'},
          {value: '11 - 20', label: '11 - 20'},
          {value: '20+', label: '20+'},
        ],
        name: 'cigarettes_per_day',
      },
    },
  },
}

// Alcohol fields
const alcoholFields: Fieldset = {
  alcohol: {
    title: 'Alcohol',
    subfields: {
      alcohol: {
        title: 'Do you drink alcohol?',
        required,
        type: 'toggle',
        choices: [
          {value: 'Yes', label: 'Yes'},
          {value: 'No', label: 'No'},
          {value: 'Recently quit', label: 'I recently quit'},
        ],
        name: 'alcohol',
      },
      alcohol_units_per_week: {
        title: 'How many units do you drink each week?',
        type: 'radio',
        required,
        choices: [
          {value: '0 - 7', label: 'Less than 7'},
          {value: '7 - 14', label: '7 - 14'},
          {value: '15 - 30', label: '15 - 30'},
          {value: '30+', label: 'More than 30'},
        ],
        name: 'alcohol_units_per_week',
        help_text: () => (
          <>
            If you are unsure how to calculate the number of units, please visit{` `}
            <a
              href="https://www.nhs.uk/live-well/alcohol-advice/calculating-alcohol-units/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.nhs.uk/live-well/alcohol-advice/calculating-alcohol-units/
            </a>
          </>
        ),
      },
    },
  },
}

// Common options for activity level
const activityChoices = [
  {value: '0', label: '0 h/week'},
  {value: '1', label: '1 h/week'},
  {value: '2', label: '2 h/week'},
  {value: '3', label: '3 h/week'},
  {value: '4', label: '4 h/week'},
  {value: '5', label: '5 h/week'},
  {value: '6', label: '6 h/week'},
  {value: '7', label: '7 h/week'},
  {value: '7+', label: '7+ h/week'},
]

// Activity fiedls
const activityFields: Fieldset = {
  activity: {
    title: 'Physical Activity',
    subfields: {
      physical_activity: {
        title: 'Do you do any physical activity?',
        required,
        type: 'toggle',
        name: 'physical_activity',
        choices: yesNoChoices,
      },
      light_activity_hours_per_week: {
        title: 'Light activity, e.g. walking, stretching',
        required,
        type: 'select',
        name: 'light_activity_hours_per_week',
        choices: activityChoices,
        before: () => <p>Please tell us how many hours you spent on physical activity last week</p>,
      },
      moderate_activity_hours_per_week: {
        title: 'Moderate activity, e.g. aerobics, golf',
        required,
        type: 'select',
        name: 'moderate_activity_hours_per_week',
        choices: activityChoices,
      },
      vigorous_activity_hours_per_week: {
        title: 'Vigorous activity, e.g. running, weight training',
        required,
        type: 'select',
        name: 'vigorous_activity_hours_per_week',
        choices: activityChoices,
      },
    },
  },
}

// Medical conditions
const medicalConditions = [
  'Anaemia',
  'Asthma',
  'Cancer',
  'Chronic Kidney Disease',
  'Coeliac Disease',
  'COPD',
  'Diabetes',
  'Dementia',
  'Gout',
  'Heart disease',
  'High blood pressure',
  'High cholesterol',
  'HIV',
  'Irritable bowel syndrome',
  'Inflammatory bowel disease',
  'Irregular heart rhythm',
  'Liver disease',
  'Mental health problems',
  'Polycystic Ovary Syndrome',
  'Rheumatoid arthritis',
  'Thyroid problems',
  'Stroke / TIA',
  'Other',
]

// Medical conditions fields
const medicalConditionsFields: Fieldset = {
  medical_conditions: {
    title: 'Medical Conditions',
    subfields: {
      was_diagnosed: {
        title: 'Have you been diagnosed with any medical conditions?',
        required: true,
        type: 'toggle',
        name: 'was_diagnosed',
        choices: yesNoChoices,
      },
      condition: {
        title: 'Please tick all that apply',
        required: false,
        type: 'checkboxes',
        name: 'condition',
        choices: medicalConditions.map(value => ({
          label: value,
          value,
        })),
      },
      other_description: {
        title: 'Please Specify Other Conditions',
        required,
        type: 'textarea',
        max_length: 255,
        name: 'other_description',
      },
    },
  },
}

const medications = [
  'Blood pressure medication',
  'Cholesterol lowering drugs',
  'Contraceptives',
  'Diabetes medication including insulin',
  'Diuretics',
  'Heart medication',
  'Hormone replacement therapy',
  'Mental health medication',
  'Steroids',
  'Thyroxine',
  'Other',
]

// Medications fields
const medicationsFields: Fieldset = {
  medications: {
    title: 'Medications',
    subfields: {
      is_taking: {
        title: 'Are you taking any regular medication?',
        required: true,
        type: 'toggle',
        name: 'is_taking',
        choices: yesNoChoices,
      },
      medications: {
        title: 'Please tick all that apply',
        required: false,
        type: 'checkboxes',
        name: 'medications',
        choices: medications.map(value => ({
          label: value,
          value,
        })),
      },
      other_description: {
        title: 'Please specify other medications',
        required,
        type: 'textarea',
        max_length: 255,
        name: 'other_description',
      },
      details: {
        title: 'Please tell us the name, dosage and frequency of your medication',
        required,
        type: 'textarea',
        max_length: 255,
        name: 'details',
      },
    },
  },
}

// Menstrual fields
const menstrualFields: Fieldset = {
  menstrual: {
    title: 'Menstrual',
    subfields: {
      cycle_length: {
        title: 'What is your normal cycle length?',
        required,
        type: 'select',
        name: 'cycle_length',
        choices: [
          {value: 'Less than 21 days', label: 'Less than 21 days'},
          {value: '21-26 days', label: '21-26 days'},
          {value: '27-29 days', label: '27-29 days'},
          {value: '30-40 days', label: '30-40 days'},
          {value: 'More than 40 days', label: 'More than 40 days'},
          {value: "It's not regular", label: "It's not regular"},
          {value: 'Menopausal', label: 'Menopausal'},
          {value: 'Pregnant', label: 'Pregnant'},
        ],
      },
      day_of_cycle: {
        title: 'Which day of your cycle was this blood test taken on?',
        required,
        type: 'select',
        name: 'day_of_cycle',
        choices: [
          {value: '1 - 5', label: '1 - 5'},
          {value: '6 - 14', label: '6 - 14'},
          {value: '15 - 21', label: '15 - 21'},
          {value: '22 - 28', label: '22 - 28'},
          {value: '29 - 40', label: '29 - 40'},
          {value: '40+', label: '40+'},
          {value: 'Not sure', label: 'Not sure'},
          {value: 'Menopausal', label: 'Menopausal'},
          {value: 'Pregnant', label: 'Pregnant'},
        ],
        help_text: () => 'Your cycle begins on the 1st day of your period.',
      },
      contraception: {
        title: 'Are you taking contraception?',
        required,
        type: 'select',
        name: 'contraception',
        choices: [
          {value: 'No', label: 'No'},
          {value: 'Combined pill', label: 'Combined pill'},
          {value: 'Mini pill', label: 'Mini pill'},
          {value: 'Contraceptive implant', label: 'Contraceptive implant'},
          {value: 'Contraceptive injection', label: 'Contraceptive injection'},
          {value: 'IUS or hormonal coil', label: 'IUS or hormonal coil'},
          {value: 'IUD or copper coil', label: 'IUD or copper coil'},
          {value: 'Other', label: 'Other'},
        ],
      },
    },
  },
}

// Sexual health fields
const sexualHealthFields: Fieldset = {
  sexual_health: {
    title: 'Sexual Health',
    subfields: {
      sexually_active: {
        title: 'Are you sexually active?',
        required,
        type: 'toggle',
        name: 'sexually_active',
        choices: yesNoChoices,
      },
      partners: {
        title: 'Your sexual partners are:',
        required,
        type: 'toggle',
        name: 'partners',
        choices: [
          {value: 'Women', label: 'Women'},
          {value: 'Men', label: 'Men'},
          {value: 'Both', label: 'Both'},
        ],
      },
      recent_sex: {
        title: 'How long ago did you last have sex?',
        required,
        type: 'radio',
        name: 'recent_sex',
        choices: [
          {value: 'Less than a week ago', label: 'Less than a week ago'},
          {value: 'In the past month', label: 'In the past month'},
          {value: '2-3 months ago', label: '2-3 months ago'},
          {value: 'More than 3 months ago', label: 'More than 3 months ago'},
        ],
      },
      had_unprotected_sex: {
        title: 'Have you had unprotected sex in the past 3 months?',
        required,
        type: 'toggle',
        name: 'had_unprotected_sex',
        choices: yesNoChoices,
      },
      unprotected_sex_type: {
        title: 'Which type of sex was unprotected?',
        required,
        type: 'checkboxes',
        name: 'unprotected_sex_type',
        choices: [
          {value: 'Oral sex', label: 'Oral sex'},
          {value: 'Vaginal sex', label: 'Vaginal sex'},
          {value: 'Anal sex', label: 'Anal sex'},
        ],
      },
      sti_diagnosed: {
        title: 'Have you ever been diagnosed with an STI?',
        required,
        type: 'radio',
        name: 'sti_diagnosed',
        choices: [
          {value: 'No', label: 'No'},
          {value: 'Yes, currently diagnosed', label: 'Yes, currently diagnosed'},
          {value: 'Yes, previously diagnosed but fine now', label: 'Yes, previously diagnosed but fine now'},
        ],
      },
      sti_symptoms: {
        title: 'Do you have any symptoms?',
        required,
        type: 'toggle',
        name: 'sti_symptoms',
        choices: yesNoChoices,
      },
      sti_symptoms_description: {
        title: 'Please specify',
        required,
        type: 'textarea',
        max_length: 255,
        name: 'sti_symptoms_description',
      },
      potential_sti_exposure: {
        title: 'Do you think you may have been exposed to an STI?',
        required,
        type: 'toggle',
        name: 'potential_sti_exposure',
        choices: yesNoChoices,
      },
      potential_sti_exposure_type: {
        title: 'Please specify',
        required,
        type: 'checkboxes',
        name: 'potential_sti_exposure_type',
        choices: [
          {value: 'Chlamydia', label: 'Chlamydia'},
          {value: 'Gonorrhoea', label: 'Gonorrhoea'},
          {value: 'Herpes', label: 'Herpes'},
          {value: 'HIV', label: 'HIV'},
          {value: 'HPV', label: 'HPV'},
          {value: 'Syphilis', label: 'Syphilis'},
          {value: 'Other', label: 'Other'},
        ],
      },
    },
  },
}
const personalFields: Fieldset = {
  personal: {
    title: 'Personal Information',
    subfields: {
      first_name: {
        title: 'First Name',
        type: 'text',
        max_length: 63,
      },
      last_name: {
        title: 'Last Name',
        type: 'text',
        max_length: 63,
      },
      dob: {
        title: 'Date of Birth',
        type: 'date',
        help_text: () =>
          'Please note that our tests are designed for individuals who are 18 years of age or older. We apologise for any inconvenience caused, but we kindly request that you provide a date of birth indicating that you are at least 18 years old in order to proceed with the testing process.',
        min: 1900,
        max: 2006,
      },
      sex: {
        title: 'Sex at Birth',
        type: 'toggle',
        choices: [
          {
            value: 'female',
            label: 'Female',
          },
          {
            value: 'male',
            label: 'Male',
          },
        ],
        help_text: () => 'We ask of this so that we can provide the optimal results',
        required: true,
      },
      identify_with_gender: {
        title: 'Do you still identify with this gender?',
        type: 'toggle',
        choices: [
          {
            value: 'true',
            label: 'Yes',
          },
          {
            value: 'false',
            label: 'No',
          },
        ],
        help_text: () => 'We ask of this so that we can provide the optimal results.',
        required: true,
      },
    },
  },
}

const testTypeFields: Fieldset = {
  test_type: {
    title: 'If the test displayed does not match your selection, you can change it using the drop-down menu.',
    subfields: {
      test_type: {
        title: 'Test Type',
        type: 'select',
        name: 'test_type',
        choices: [
          {value: TEST_TYPES['blood-cholesterol'], label: 'Cholesterol Profile'},
          {value: TEST_TYPES['blood-energy'], label: 'Energy Profile'},
          {value: 'blood-erectile', label: 'Erectile Dysfunction'},
          {value: TEST_TYPES['blood-general'], label: 'General Health'},
          {value: TEST_TYPES['blood-heart'], label: 'Heart Profile'},
          {value: TEST_TYPES['blood-menopause'], label: 'Menopause Profile'},
          {value: TEST_TYPES['blood-polycystic'], label: 'Polycystic Ovary Syndrome (PCOS)'},
          {value: 'blood-thyroid', label: 'Thyroid Profile'},
          {value: TEST_TYPES['blood-vitamins'], label: 'Vitamins & Minerals Profile'},
          {value: TEST_TYPES['blood-weight'], label: 'Weight Management'},
        ],
      },
    },
  },
}

// Merge all form fields into single object
export default {
  body: bodyFields,
  smoking: smokingFields,
  alcohol: alcoholFields,
  activity: activityFields,
  medicalConditions: medicalConditionsFields,
  medications: medicationsFields,
  menstrual: menstrualFields,
  sexualHealth: sexualHealthFields,
  personal: personalFields,
  test_type: testTypeFields,
}
