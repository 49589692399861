import {useEffect, useState} from 'react'
import Cookies from 'js-cookie'

const COOKIE_NAME = 'cookie_settings'

// Export as a separate function so it can be called from class components
export const hasAnalyticsCookie = () => {
  if (Cookies.get(COOKIE_NAME)) {
    const cookies = JSON.parse(`${Cookies.get(COOKIE_NAME)}`)
    return cookies.analytics === 'yes'
  }
  return false
}

export const useAcceptCookies = () => {
  const [hasSettings, setHasSettings] = useState(false)
  const [hasAnalytics, setHasAnalytics] = useState(false)

  useEffect(() => {
    if (Cookies.get(COOKIE_NAME)) {
      setHasSettings(true)
      setHasAnalytics(hasAnalyticsCookie())
    }
  }, [])

  const acceptAllCookies = () => {
    setHasSettings(true)

    const cookies = {
      essential: 'yes',
      advertising: 'yes',
      analytics: 'yes',
      functional: 'yes',
    }

    const cookieConfig = {
      expires: 365,
    }

    // If it's not localhost, add 'domain' to cookie config
    if (window.location.hostname !== 'localhost') {
      cookieConfig.domain = window.location.host.replace(/^dashboard\./, '')
    }

    Cookies.set(COOKIE_NAME, JSON.stringify(cookies), cookieConfig)
  }

  return {hasSettings, hasAnalytics, acceptAllCookies}
}
