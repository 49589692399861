import React from 'react'
import {Card, CardText, CardTitle, Col, Row} from 'reactstrap'
import {as_title} from 'src/shared/reactstrap-toolbox'
import Download from './Download'

export default ({result, result_reason, download_blob, barcode_status}) => {
  if (barcode_status !== undefined && barcode_status !== null && barcode_status === 'anonymised-original') {
    return (
      <>
        <Row className="justify-content-center mb-3">
          <Col md={4}>
            <Card body className={`text-center`}>
              <CardTitle tag="h2">Result Anonymised</CardTitle>
            </Card>
          </Col>
        </Row>
      </>
    )
  }
  switch (result) {
    case 'negative':
      return <ResultPanel download_blob={download_blob} result={result} className="border border-success" />
    case 'positive':
      return <ResultPanel download_blob={download_blob} result={result} className="bg-danger" inverse />
    case 'inconclusive':
      return <ResultPanel download_blob={download_blob} result={result} className="bg-warning" />
    case 'invalid':
      return (
        <ResultPanel download_blob={download_blob} result={result} className="bg-warning">
          {result_reason ? (
            <>
              <b>Reason:</b> {result_reason}
            </>
          ) : null}
        </ResultPanel>
      )
    default:
      return null
  }
}
const ResultPanel = ({download_blob, result, inverse, className, children}) => (
  <>
    <Row className="justify-content-center mb-3">
      <Col md={4}>
        <Card body inverse={inverse} className={`text-center ${className}`}>
          <CardTitle tag="h2">{as_title(result)} Result</CardTitle>
          <CardText>{children}</CardText>
        </Card>
      </Col>
    </Row>
    <div className="text-center mt-3">
      <Download download_blob={download_blob} />
    </div>
  </>
)
