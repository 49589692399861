import React, {useState} from 'react'
import {Alert, Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap'
import {message_toast} from 'src/shared/reactstrap-toolbox'
import {faX} from '@fortawesome/free-solid-svg-icons'
import {useHistory} from 'react-router-dom'

var resume_link

export const ContinueJourney = props => {
  const history = useHistory()
  const [modalOpen, setModalOpen] = useState(false)

  // Set the correct resume link based on the current registration stage
  switch (props.resume?.registration_stage) {
    case 'type-confirm':
      resume_link = `/bloods/boots/consent/${props.test_type}/${props.resume.id}/`
      break
    case 'consent':
      resume_link = `/bloods/boots/user-details/${props.test_type}/${props.resume.id}/`
      break
    case 'personal-details':
    case 'assessment':
      resume_link = `/bloods/boots/assessment-form/${props.test_type}/${props.resume.id}/1/`
      break
    default:
      break
  }

  // API call to reject the booking
  const rejectBooking = async () => {
    const url = `/api/dashboard/registration/reject-booking/?bid=${props.resume?.id}`
    const response = await window.app.requests.post(url, null, {expected_status: [200, 400]})

    if (response.status !== 200) {
      message_toast({
        icon: faX,
        title: 'Reject Booking',
        message: response.data?.detail || response.data.details?.description,
        onClick: () => {},
        className: '',
        time: 3000,
      })
    } else {
      props.isBookingRejected()
      message_toast({
        icon: faX,
        title: 'Reject Booking',
        message: 'Your appointment has been rejected successfully. You can proceed to book a new one now.',
        onClick: () => {},
        className: '',
        time: 3000,
      })
      history.push(`/bloods/boots/${props.resume?.test_type || sessionStorage.getItem('test_type')}`)
    }
  }

  // Toggle modal visibility
  const toggleModal = () => setModalOpen(!modalOpen)

  // Handle modal confirmation to reject the booking
  const handleRejectBooking = () => {
    toggleModal()
  }

  // Reject an appointment
  const handleConfirmReject = () => {
    rejectBooking()
    toggleModal()
  }

  return (
    <div className="container">
      <h1>ACTION REQUIRED</h1>
      <hr />

      {props.untestable && (
        <div className="mt-4">
          <div>
            <p>
              We regret to inform you that our partner lab was unable to successfully analyze your sample(s). This may
              happen due to several reasons such as leakage during transit, clotted samples, or heamolysis.
            </p>
            <p>To book a new appointment, please click on the button below.</p>
          </div>
          <Button color="primary" href="https://www.boots.com/wellnesstestrebook" target="_blank">
            Rebook Appointment
          </Button>
        </div>
      )}
      {props.resume && (
        <div className="mt-4">
          <h4>
            Your registration with Boots In-store Wellness Testing Service powered by MyHealthChecked is not yet
            complete.
          </h4>

          <p>Please click the button below to continue your registration.</p>
          <Button color="primary" href={resume_link}>
            Continue Registration
          </Button>

          <Alert className="mt-4 text-justify" color="warning">
            <strong className="alert-heading">Important Reminder: </strong>
            <p>
              You can only register for one test at a time. Once you’ve received your test results, you’ll be able to
              register for another.
            </p>
            <p>
              <strong>Please note:</strong> It's essential to complete your registration before arriving at Boots for
              your in-store appointment. If your registration is incomplete, you may be unable to proceed with your
              test.
            </p>
            <p>If you'd like to cancel your current registration, please select "Cancel" below.</p>
            <span>
              <Button color="danger text-white mt-4 font-weight-bold" onClick={handleRejectBooking}>
                Cancel Registration
              </Button>
            </span>
          </Alert>

          <Modal isOpen={modalOpen} toggle={toggleModal}>
            <ModalHeader toggle={toggleModal}>Confirm Rejection</ModalHeader>
            <ModalBody>Are you sure you want to reject this appointment? This action cannot be undone.</ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggleModal}>
                Cancel
              </Button>
              <Button color="danger" onClick={handleConfirmReject}>
                Confirm
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      )}
    </div>
  )
}
