import React from 'react'
import CaptureQRImage from 'src/shared/CaptureQRImage'

export default ({match}) => {
  return (
    <div className="row justify-content-center pt-4">
      <div className="col-md-8">
        <CaptureQRImage match={match}>
          <div>Hold your antigen cassette up to the your camera so it fits in the outline below.</div>
          <b>Make sure the results area is clearly visible in the photo.</b>
        </CaptureQRImage>
      </div>
    </div>
  )
}
