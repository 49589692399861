import React, {useCallback, useEffect, useState, useRef} from 'react'
import {RouteComponentProps} from 'react-router-dom'
import {Button, Container, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faArrowCircleRight, faCog} from '@fortawesome/free-solid-svg-icons'

import {StandaloneForm, Loading, message_toast, as_title} from 'src/shared/reactstrap-toolbox'
import {buildFormFields} from 'src/form_fields'
// import {TEST_TYPES} from 'src/views/Assessment/steps'

interface FormButtonsProps {
  state: any
  form_props: any
  submitButtonRef: any
}

// Form buttons
const FormButtons = ({state, form_props, submitButtonRef}: FormButtonsProps) => (
  <footer className="d-flex justify-content-end w-100 mt-4">
    <div className={form_props.form_footer_class || 'text-right'}>
      {form_props.showErrorButton && (
        <Button
          tag={'a'}
          href="https://myhealthchecked.com/help"
          target="_blank"
          rel="noopener noreferrer"
          className="mr-3 mb-1"
        >
          Report Issue
        </Button>
      )}

      <Button
        type="submit"
        color="primary"
        disabled={state.disabled || form_props.disabled}
        className="btn-cog mb-1"
        innerRef={submitButtonRef}
      >
        {form_props.save_label || 'Save'}
        <FontAwesomeIcon icon={faCog} className="cog-loading fa-fw" />
      </Button>
    </div>
  </footer>
)

// // Get link to tutorial depending on test type
// function get_tutorial_link(test_type: TestType) {
//   const tutorial_links = {
//     [TEST_TYPES['blood-female-sex-health']]: '/help/videos/femalesexhealth',
//     [TEST_TYPES['blood-male-sex-health']]: '/help/videos/malesexhealth',
//   }
//
//   const path = tutorial_links[test_type] || '/help/videos/blood'
//
//   return `https://www.myhealthchecked.com${path}`
// }

// Format form fields, ex. change labels, remove help texts
function formatFormFields(fieldsToFormat: any) {
  const fields = fieldsToFormat

  fields.first_name.help_text = ''
  fields.last_name.help_text = ''

  fields.phone_number_primary.help_text = `At MyHealthChecked, we require a phone number for patients who purchase sexual health tests. This is to ensure 
    our doctors can reach you with your results and any necessary follow-up care in a timely and confidential manner. Please note that providing 
    your phone number does not indicate a positive result. Patient privacy and safeguarding are top priorities for us, and we handle your 
    information with the utmost care.`

  // min age to be 18 years
  fields.dob.max = new Date(Date.now() - (18 * 365 + 4) * 24 * 60 * 60 * 1000).toISOString().split('T')[0]
  fields.dob.help_text =
    'Please note that our tests are designed for individuals who are 18 years of age or older. We apologise for any inconvenience caused, but we kindly request that you provide a date of birth indicating that you are at least 18 years old in order to proceed with the testing process.'

  fields.sex.title = 'Gender at Birth'
  fields.sex.help_text = 'We ask of this so that we can provide the optimal results'

  // Remove PNTS gender option
  fields.sex.choices = fields.sex.choices.filter((choice: any) => choice.value !== 'pnts')

  fields.identify_with_gender.title = 'Do you still identify with this gender?'
  fields.ethnicity.help_text = ''

  delete fields.test_ts
  delete fields.secondary_barcode

  return fields
}

const form_fields = {
  first_name: true,
  last_name: true,
  phone_number_primary: true,
  dob: true,
  sex: true,
  identify_with_gender: true,
  ethnicity: true,
  test_ts: false,
}

interface MatchParams {
  barcode: string
}

interface TestInfo {
  customer_id: number
  primary_barcode: string
  test_kit_id: number
  first_name: string
  last_name: string
  dob: string
  sex: string
  ethnicity: string
  identify_with_gender: boolean
  type: string
  status: string
  secondary_barcode?: string
  secondary_barcode_type: string
  home_postal_code?: string
}

interface Props extends RouteComponentProps<MatchParams> {}

export function BloodsConfirmCustomerDetails({match, history}: Props) {
  const {barcode} = match.params

  const [error, setError] = useState<{title: string; description?: string} | null>()
  const [submit_count, setSubmitCount] = useState(0)
  const [test_info, setTestInfo] = useState({} as TestInfo)
  const [genderWarningOpen, setGenderWarningOpen] = useState(false)
  const [genderWarningDisplayed, setGenderWarningDisplayed] = useState(false)

  // eslint-disable-next-line
  const [form_data, setFormData] = useState({})
  let GenderWarningText = useRef(() => {
    return <></>
  })

  let IncorrectSexWarning = (sex: string) => {
    return (
      <>
        <p>
          You have selected your gender at birth as <b>{sex}</b>. This test includes the measurement of markers
          physiologically associated with people born <b>{correctSex}</b>.
        </p>
        <p>
          This test may not, therefore, be suitable for you. Please contact customer care to exchange or refund your
          product at <a href="mailto:care@myhealthchecked.com">care@myhealthchecked.com</a> or 0203 371 3537.
        </p>
        <p />
        <p>If you are sure you want to continue, close this window and click Continue.</p>
      </>
    )
  }

  //toggle the display, and mark that it's been opened
  const toggleGenderWarning = () => {
    setGenderWarningOpen(open => !open)
    setGenderWarningDisplayed(true)
  }

  // Load test details from API
  const getTestDetails = useCallback(async () => {
    const url = `/api/dashboard/test-kits/activate/${barcode}/`

    const result = await window.app.requests.get(url, {}, {expected_status: [200, 422]})

    if (result.status === 200) {
      // Convert identify_with_gender value from boolean to 'true'/'false' strings
      if (typeof result.data?.identify_with_gender === 'boolean') {
        result.data.identify_with_gender = `${result.data.identify_with_gender}`
      }

      setTestInfo(result.data)
    }
  }, [barcode])

  // Get initial details
  useEffect(() => {
    window.app.setTitle('Confirm your details')

    getTestDetails()
  }, [barcode, getTestDetails])

  // Handle form submit
  const handleSubmit = async (data: any) => {
    // Warns female customer that they are performing a male test
    if (isMaleTest && data.sex === 'female' && !genderWarningDisplayed) {
      GenderWarningText.current = () => IncorrectSexWarning(data.sex)
      toggleGenderWarning()

      return
    }
    // Warns male customer that they are performing a female test
    else if (isFemaleTest && data.sex === 'male' && !genderWarningDisplayed) {
      GenderWarningText.current = () => IncorrectSexWarning(data.sex)
      toggleGenderWarning()

      return
    }

    const errors: string[] = []
    setSubmitCount(submit_count + 1)

    if (errors.length) {
      return {status: 400, data: {details: errors}}
    }

    const submit_data = {...data, barcode: barcode, secondary_barcode: test_info?.secondary_barcode || null}
    submit_data.identify_with_gender = submit_data.identify_with_gender === 'true'

    const stage = {
      barcode: test_info.primary_barcode,
      stage: 'personal-details',
      first_name: test_info.first_name,
      last_name: test_info.last_name,
    }

    // Update activation stage status
    const result = await window.app.requests.post('/api/dashboard/activate/activating/', stage, {
      expected_status: [200, 422, 404, 400],
    })

    const r = await window.app.requests.post('/api/dashboard/tests/submit-customer-details/', submit_data, {
      expected_status: [200, 422, 404, 400],
    })

    if (r.status !== 200) {
      setError({title: r.data.detail[1].msg || 'Form contains errors'})
      message_toast({
        icon: faArrowCircleRight,
        title: 'Test Details Entered',
        message: r.data.detail[1].msg || 'Form contains errors',
        onClick: () => {},
        className: '',
      })
      return
    } else {
      message_toast({
        icon: faArrowCircleRight,
        title: 'Test Details Entered',
        message: 'Success',
        onClick: () => {},
        className: '',
      })
    }

    if (result.status !== 200) {
      setError({title: result.data.message || 'Form contains errors', description: result.data.details?.description})
    }

    if (isSexualHealthTest) {
      history.push(`/tests/private/when-should-i-test/${barcode}/`)
    } else {
      history.push(`/tests/private/collect-samples/${test_info.test_kit_id}/`)
    }
  }

  // If there are no test_info, show loader
  if (test_info === null) {
    return (
      <Container className="my-5">
        <Loading />
      </Container>
    )
  }

  const {type} = test_info

  const maleTypes = ['blood-erectile', 'blood-male-sex-health']
  const femaleTypes = ['blood-polycystic', 'blood-menopause', 'blood-female-sex-health']
  const sexualHealthTypes = ['blood-female-sex-health', 'blood-male-sex-health']

  const isMaleTest = maleTypes.includes(type)
  const isFemaleTest = femaleTypes.includes(type)
  const isSexualHealthTest = sexualHealthTypes.includes(type)
  const correctSex: string = isMaleTest ? 'male' : 'female'

  // Prepare form fields
  const fields_definition = {...form_fields}
  let fields = buildFormFields(fields_definition)
  fields = formatFormFields(fields)
  if (!isSexualHealthTest) delete fields.phone_number_primary

  return (
    <div className="row justify-content-center py-5">
      <div className="col-md-8">
        <h1>Confirm your details</h1>
        <p className="text-muted">
          Test Type: <span className="text-dark font-weight-bold">{as_title(type)}</span>
        </p>
        <p className="text-muted">Before you can activate your test, please submit your details below.</p>

        <StandaloneForm
          form_error={error?.title}
          form_data={error && form_data}
          fields={fields}
          execute={handleSubmit}
          Buttons={FormButtons}
          // Show "Report Issue" button after two failed submissions
          showErrorButton={submit_count >= 2 && error?.title}
          save_label="Continue"
        />

        <Modal isOpen={genderWarningOpen} toggle={toggleGenderWarning}>
          <ModalHeader toggle={toggleGenderWarning}>Warning</ModalHeader>
          <ModalBody>{GenderWarningText.current()}</ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={toggleGenderWarning}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </div>
  )
}
