import React from 'react'
import {Progress} from 'reactstrap'

import './MarkerScale.scss'

interface Props {
  value: BiomarkerValue
  type: BiomarkerType
  result: BiomarkerResult
  boundary: number | null
  lower_boundary: number | null
  upper_boundary: number | null
  decision_type?: 'female-manual' | string
}

interface ScaleDetails {
  pointerPosition: number
  min?: number
  max?: number
  barSize: number
  leftBarSize?: number
}

const lowBoundaryTypes: BiomarkerType[] = ['low-normal', 'low-normal-high']
const highBoundaryTypes: BiomarkerType[] = ['normal-high', 'low-normal-high', 'negative-positive']

interface SingleBoundary extends Props {
  value: number
  boundary: number
}

interface TwoBoundaries extends Props {
  value: number
  lower_boundary: number
  upper_boundary: number
}

interface NegativePositiveBoundary extends Props {
  // value: Omit<BiomarkerValue, number>
}

function isSingleBoundaryScale(params: Props): params is SingleBoundary {
  return params.type === 'low-normal' || params.type === 'normal-high'
}

// function isTwoBoundariesScale(params: Props): params is TwoBoundaries {
//   return params.type === 'low-normal-high'
// }

function isNegativePositiveScale(params: Props): params is NegativePositiveBoundary {
  return params.type === 'negative-positive'
}

// Trim number to range. Keeps value within min-max boundary
function trimToRange(value: number, min: number, max: number) {
  // If value is lower than min, return min
  if (value < min) {
    return min
  }

  // If value is greater than max, return max
  if (value > max) {
    return max
  }

  return value
}

// Get scale details:
// - arrow position
// - bar parts sizes
// - min and max range
function getScaleDetails(allParams: Props): ScaleDetails {
  let params = {...allParams}

  // Modify value of biomarker with female-manual decision type
  // to show result on correct part of the scale
  // 1 - middle of low boundary
  // 3 - middle of normal boundary
  // 5 - middle of high boundary
  if (params.decision_type === 'female-manual') {
    params.lower_boundary = 2
    params.upper_boundary = 4

    params.value = 3
    if (params.result === 'low') {
      // 1 - middle of low boundary
      params.value = 1
    } else if (params.result === 'normal') {
      // 3 - middle of normal boundary
      params.value = 3
    } else if (params.result === 'high') {
      // 5 - middle of high boundary
      params.value = 5
    }
  }

  // Default bar size is 50%
  let barSize = 50
  let leftBarSize = 0

  // Handle 'negative-positive' marker (sexual health)
  // No delta, simple 0-100% scale, each bar 50% width
  if (isNegativePositiveScale(params)) {
    return {
      pointerPosition: params.result === 'green' ? 25 : 75,
      barSize: 50,
    }
  }

  // Handle single boundary scale
  if (isSingleBoundaryScale(params)) {
    let {value, boundary}: SingleBoundary = params
    const pointerPosition = (value / boundary / 2) * 100

    return {
      pointerPosition: trimToRange(pointerPosition, 0, 100),
      barSize,
      leftBarSize,
    }
  }

  // Otherwise it's two boundaries scale (low-normal-high)
  let {lower_boundary, upper_boundary, value}: TwoBoundaries = params
  // Calculate difference between upper and lower boundary
  let delta = upper_boundary - lower_boundary
  // Minimum value to show on a scale
  let min = lower_boundary - delta
  if (min < 0) min = 0

  // Maximum value to show on a scale
  let max = upper_boundary + delta

  // Size of min bar (min-lower) %
  leftBarSize = ((lower_boundary - min) / (max - min)) * 100
  // Normal and high bar size %
  barSize = (100 - leftBarSize) / 2

  const pointerPosition = ((value - min) / (max - min)) * 100

  return {
    pointerPosition: trimToRange(pointerPosition, 2, 98),
    barSize,
    leftBarSize,
  }
}

export function MarkerScale(props: Props) {
  const {value, type} = props

  const showLowBoundary = lowBoundaryTypes.includes(type)
  const showHighBoundary = highBoundaryTypes.includes(type)
  const isBoolMarker = type === 'negative-positive'
  const normalBoundaryLabel = isBoolMarker ? 'Not detected' : 'Normal'
  let highBoundaryLabel = isBoolMarker ? 'Detected' : 'High'

  if (String(value).toLowerCase() === 'unconfirmed reactive') {
    highBoundaryLabel = 'Unconfirmed Reactive'
  }

  const {barSize, pointerPosition, leftBarSize} = getScaleDetails(props)

  // Render range label (depending on boundaries)
  // Hide for female-manual biomarkers
  function renderRangeLabel() {
    if (props.decision_type === 'female-manual') return null

    if (props.type === 'low-normal-high') {
      if (!props.lower_boundary || !props.upper_boundary) {
        return `${props.lower_boundary} - ${props.upper_boundary}`
      } else {
        return `${props.lower_boundary.toFixed(2)} - ${props.upper_boundary.toFixed(2)}`
      }
    }

    if (props.type === 'low-normal') {
      if (!props.boundary) {
        return `> ${props.boundary}`
      } else {
        return `> ${props.boundary.toFixed(2)}`
      }
    }

    if (props.type === 'normal-high') {
      if (!props.boundary) {
        return `< ${props.boundary}`
      } else {
        return `< ${props.boundary.toFixed(2)}`
      }
    }
  }

  return (
    <div className="markerScale__container">
      {/* Pointer/arrow */}
      <div className="markerScale__pointer" style={{left: `${pointerPosition}%`}}></div>

      {/* Low */}
      <Progress className="my-0 markerScale__bar-container" multi>
        {showLowBoundary && (
          <>
            <Progress
              className="mr-0 markerScale__bar markerScale__bar--bad"
              bar
              value={leftBarSize || barSize}
            ></Progress>
          </>
        )}

        {/* Normal */}
        <Progress className="mx-0 markerScale__bar" bar value={barSize}></Progress>

        {/* High */}
        {showHighBoundary && (
          <Progress
            className={`ml-0 markerScale__bar markerScale__bar--${
              value === 'Unconfirmed Reactive' ? 'unconfirmed' : 'bad'
            }`}
            bar
            value={barSize}
          ></Progress>
        )}
      </Progress>
      <div className="markerScale__labels">
        {showLowBoundary && (
          <div style={{width: `${leftBarSize || barSize}%`}} className="markerScale__label">
            Low
          </div>
        )}
        <div style={{width: `${barSize}%`}} className="markerScale__label">
          {normalBoundaryLabel}
          <div className="markerScale__label-range">{renderRangeLabel()}</div>
        </div>
        {showHighBoundary && (
          <div style={{width: `${barSize}%`}} className="markerScale__label">
            {highBoundaryLabel}
          </div>
        )}
      </div>
    </div>
  )
}
