import React, {useEffect} from 'react'
import {Redirect} from 'react-router-dom'
import {faCheck, faExclamationTriangle} from '@fortawesome/free-solid-svg-icons'
import {Loading, message_toast} from 'src/shared/reactstrap-toolbox'

const messages = {
  'email-updated': 'Your email address has been successfully updated.',
  'email-exists': 'A user with this email address already exists, your email has not be updated.',
  'already-used': 'This link to update your email address has already been used.',
  'updated-since': 'Your email address has already been updated since this link was generated.',
  'invalid-link': 'This link to update your email address is invalid.',
  'link-expired': 'This link to update your email address has expired (each link is valid for 24 hours).',
}

export default ({location}) => {
  const [redirect, setRedirect] = React.useState(null)

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const messageKey = params.get('message')
    const message = messages[messageKey] || 'Updating your email address failed'
    messageKey === 'email-updated'
      ? message_toast({
          icon: faCheck,
          title: 'Success',
          message: message,
          time: 4000,
        })
      : message_toast({
          icon: faExclamationTriangle,
          title: 'Error',
          message: message,
          time: 4000,
        })

    setRedirect('/')
  }, [location.search])

  return redirect ? <Redirect to={redirect} push={false} /> : <Loading />
}
