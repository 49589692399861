import React from 'react'
import EmailLink from 'src/shared/EmailLink'

export default () => (
  <div>
    <h1>Unsubscribed</h1>
    <p>
      You have unsubscribed from <b>all emails</b>, you will not receive further emails from the MyHealthChecked
      platform.
    </p>
    <p>
      If you wish to resubscribe, please contact <EmailLink />.
    </p>
  </div>
)
