import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import * as Fontawesome from '@fortawesome/fontawesome-svg-core'
import {BrowserRouter as Router} from 'react-router-dom'

import './styles/main.scss'
import App from './App'

Fontawesome.config.autoAddCss = false

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: process.env.REACT_APP_VERSION,
    environment: process.env.REACT_APP_ENVIRONMENT,
    ignoreErrors: ['Non-Error exception captured', 'Non-Error promise rejection captured', 'Illegal invocation'],
  })
  Sentry.setTag('release_context', process.env.REACT_APP_CONTEXT)
}

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById('root'),
)

if (process.env.NODE_ENV === 'development') {
  // hack to prompt reload on python code change
  //window.t = import('./.update.js')
}
