import {RouteComponentProps} from 'react-router-dom'
import React, {useCallback, useEffect, useState} from 'react'
import {Container} from 'reactstrap'
import {Loading, StandaloneForm, as_title} from 'src/shared/reactstrap-toolbox'
import {buildFormFields} from 'src/form_fields'
interface MatchParams {
  barcode: string
  customer_id: string
  test_id: string
}

interface Props extends RouteComponentProps<MatchParams> {}

interface TestInfo {
  type: string
}

export default function WellnessTestActivation({match, history}: Props) {
  const [test_info, setTestInfo] = useState({} as TestInfo)
  const [showFormError, setShowFormError] = useState(false)
  const [formErrorMessage, setFormErrorMessage] = useState('')

  const {barcode} = match.params
  const form_fields = {
    test_ts: true,
  }

  const getTestDetails = useCallback(async () => {
    const url = `/api/dashboard/wellness/activate/${barcode}/`

    const result = await window.app.requests.get(url, {}, {expected_status: [200, 422]})

    if (result.status === 200) {
      setTestInfo(result.data)
    }
  }, [barcode])

  // Get initial details
  useEffect(() => {
    window.app.setTitle('Activate Kit')
    getTestDetails()
  }, [barcode, getTestDetails])

  const handleSubmit = async (data: any) => {
    const selectedDateAndTime = new Date(data.test_ts)
    const selectedDateAndTimeTimestamp = Math.floor(selectedDateAndTime.getTime() / 1000)

    const currentDate = new Date()
    const oneHourFromNow = new Date(currentDate.getTime() + 60 * 60 * 1000)
    const oneHourFromNowTimestamp = Math.floor(oneHourFromNow.getTime() / 1000) // Convert to seconds

    let canContinue = true
    let errorMessage = ''

    // is the timestamp more than an hour from now
    if (selectedDateAndTimeTimestamp > oneHourFromNowTimestamp) {
      errorMessage = 'Timestamp cannot be more than one hour in the future.'
      canContinue = false
    }

    if (canContinue) {
      //submit to main-platform
      const result = await window.app.requests.post(
        `/api/dashboard/wellness/update-test-timestamp/${barcode}/`,
        data,
        {
          expected_status: [200, 422, 404, 400],
        },
      )
      // If status isn't equal to 200, show and set error
      if (result.status !== 200) {
        setFormErrorMessage(result.data.detail[0]?.msg || 'Form contains errors')
        setShowFormError(true)
        return false
      }
    }

    setFormErrorMessage(errorMessage)
    if (!canContinue) {
      setShowFormError(true)
      return false
    } else {
      setShowFormError(false)
      history.push(`/assessment/wellness/form`)
    }
  }

  const fields_definition = {...form_fields}
  let fields = buildFormFields(fields_definition)

  // If there are no test_info, show loader
  if (test_info === null) {
    return (
      <Container className="my-5">
        <Loading />
      </Container>
    )
  }

  return (
    <div className="row justify-content-center pt-4">
      <div className="mt-3 p-0 col-10">
        <h1>Activate your test</h1>
        <p className="text-muted">
          Test Type: <span className="text-dark font-weight-bold">{as_title(test_info.type)}</span>
        </p>
        <div className="pt-2">
          <StandaloneForm
            fields={fields}
            execute={handleSubmit}
            // afterSubmit={afterSubmit}
            save_label="Confirm & Continue"
          />
          {showFormError && <p className="text-danger text-right">{formErrorMessage}</p>}
        </div>
      </div>
    </div>
  )
}
