import aerobic_capacity from 'src/assets/icons/Sports/Aerobic_strength_circuit.svg'
import ace from 'src/assets/icons/HealthAreas/Exercise_WeightLoss.svg'
import adrenergic_receptors from 'src/assets/icons/HealthAreas/AdrenergicReceptors_InsulinResponse.svg'
import report_alcohol from 'src/assets/icons/HealthAreas/Alcohol_Sensitivity.svg'
import bcaa_and_glucose from 'src/assets/icons/HealthAreas/Plasma_BCAAs_GlucoseLevels.svg'
import beta_carotene from 'src/assets/icons/HealthAreas/Beta_Carotene_Deficiency.svg'
import choline_deficiency from 'src/assets/icons/HealthAreas/Choline_Deficiency.svg'
import caffeine_and_anxiety from 'src/assets/icons/HealthAreas/Caffeine_Anxiety.svg'
import report_caffeine from 'src/assets/icons/HealthAreas/Caffeine_Sensitivity.svg'
import calcium_levels from 'src/assets/icons/HealthAreas/Calcium_Deficiency.svg'
import carbohydrate_response from 'src/assets/icons/HealthAreas/Carbohydrate_Response.svg'
import cholesterol from 'src/assets/icons/HealthAreas/LDL_Levels.svg'
import appetite from 'src/assets/icons/HealthAreas/Eating_Habits.svg'
import fat from 'src/assets/icons/HealthAreas/Weight_Management.svg'
import folate_deficiency from 'src/assets/icons/HealthAreas/Folic_Acid_Deficiency.svg'
import report_glucose from 'src/assets/icons/HealthAreas/Glucose_Management.svg'
import gluten_intolerance from 'src/assets/icons/HealthAreas/Gluten_Intolerance.svg'
import glycemic_response from 'src/assets/icons/HealthAreas/Glycemic_Response.svg'
import insulin_resistance from 'src/assets/icons/HealthAreas/Insulin_Resistance.svg'
import iron_deficiency from 'src/assets/icons/HealthAreas/Iron_Deficiency.svg'
import iron_overload from 'src/assets/icons/HealthAreas/Iron_Overload.svg'
import lactose_intolerance from 'src/assets/icons/HealthAreas/Lactose_Intolerance.svg'
import meal_time from 'src/assets/icons/HealthAreas/Meal_Times.svg'
import metabolic_syndrome from 'src/assets/icons/HealthAreas/Metabolic_Syndrome.svg'
import muscle_power from 'src/assets/icons/Reports/Exercise.svg'
import omega_3 from 'src/assets/icons/HealthAreas/Omega_3.svg'
import pgc_and_glucose from 'src/assets/icons/HealthAreas/PGC_1a_Levels_GlucoseMetabolism.svg'
import protein_response from 'src/assets/icons/HealthAreas/Protein_Response.svg'
import oxidative_stress from 'src/assets/icons/Sports/Aerobic_strength_circuit.svg'
import saturated_fat from 'src/assets/icons/HealthAreas/SaturatedFat_Response.svg'
import triglyceride from 'src/assets/icons/HealthAreas/Triglyceride_Levels.svg'
import sodium from 'src/assets/icons/HealthAreas/Salt_Sensitivity.svg'
import report_selenium from 'src/assets/icons/HealthAreas/Selenium_Deficiency.svg'
import taste_bitterness from 'src/assets/icons/Reports/TasteBitterness.svg'
import taste_fat from 'src/assets/icons/Reports/TasteFat.svg'
import taste_salt from 'src/assets/icons/Reports/TasteSalt.svg'
import vitamin_B12_deficiency from 'src/assets/icons/HealthAreas/Vitamin_B12_Deficiency.svg'
import vitamin_c_deficiency from 'src/assets/icons/HealthAreas/Vitamin_C_Deficiency.svg'
import vitamin_d_deficiency from 'src/assets/icons/HealthAreas/Vitamin_D_Deficiency.svg'
import weight_and_exercise from 'src/assets/icons/HealthAreas/Exercise_WeightLoss.svg'
import weight_loss_maintenance from 'src/assets/icons/HealthAreas/WeightLoss_Maintenance.svg'
import weight_management from 'src/assets/icons/HealthAreas/Weight_Management.svg'
import zinc_and_glucose from 'src/assets/icons/HealthAreas/GlucoseLevels_ZincIntake.svg'

import wine from 'src/assets/icons/Drinks/Wine.svg'
import whiskey from 'src/assets/icons/Drinks/Whiskey.svg'
import espresso from 'src/assets/icons/Drinks/Espresso.svg'
import beer from 'src/assets/icons/Drinks/Beer.svg'
import energy_drinks from 'src/assets/icons/Drinks/Energy_drinks.svg'
import passionflower_tea from 'src/assets/icons/Drinks/Passionflower_tea.svg'
import soda from 'src/assets/icons/Drinks/Soda.svg'
import coffee from 'src/assets/icons/Drinks/Coffee.svg'
import tequila from 'src/assets/icons/Drinks/Tequila.svg'
import ice_mocha from 'src/assets/icons/Drinks/Ice_Mocha.svg'
import green_tea from 'src/assets/icons/Drinks/Green_tea.svg'
import black_leaf_tea from 'src/assets/icons/Drinks/Black_leaf_tea.svg'
import vodka from 'src/assets/icons/Drinks/Vodka.svg'
import hot_cocoa from 'src/assets/icons/Drinks/Hot_cocoa.svg'
import cola from 'src/assets/icons/Drinks/Cola.svg'
import rum from 'src/assets/icons/Drinks/Rum.svg'
import chocolate_milk from 'src/assets/icons/Drinks/Chocolatemilk.svg'
import camomile_tea from 'src/assets/icons/Drinks/Camomile_tea.svg'
import tart_cherry_juice from 'src/assets/icons/Drinks/Tart_cherry_juice.svg'
import kidney_beans from 'src/assets/icons/Legumes/Kidney_beans.svg'
import lentils from 'src/assets/icons/Legumes/Lentils.svg'
import white_beans from 'src/assets/icons/Legumes/White_beans.svg'
import chickpea_pasta from 'src/assets/icons/Legumes/Chickpea_pasta.svg'
import edamame from 'src/assets/icons/Legumes/Edamame.svg'
import soya_beans from 'src/assets/icons/Legumes/Soya_beans.svg'
import chickpea from 'src/assets/icons/Legumes/Chickpea.svg'
import asparagus from 'src/assets/icons/Vegetables/Asparagus.svg'
import artichoke from 'src/assets/icons/Vegetables/Artichoke.svg'
import mustard_greens from 'src/assets/icons/Vegetables/Mustard_greens.svg'
import cassava from 'src/assets/icons/Vegetables/Cassava.svg'
import mushrooms from 'src/assets/icons/Vegetables/Mushrooms.svg'
import corn from 'src/assets/icons/Vegetables/Corn.svg'
import yellow_pepper from 'src/assets/icons/Vegetables/Yellow_pepper.svg'
import butternut_squash from 'src/assets/icons/Vegetables/Butternut_squash.svg'
import cauliflower from 'src/assets/icons/Vegetables/Cauliflower.svg'
import kale from 'src/assets/icons/Vegetables/Kale.svg'
import carrots from 'src/assets/icons/Vegetables/Carrots.svg'
import broccoli from 'src/assets/icons/Vegetables/Broccoli.svg'
import red_cabbage from 'src/assets/icons/Vegetables/Red_cabbage.svg'
import turnip_greens from 'src/assets/icons/Vegetables/Turnip_greens.svg'
import parsnips from 'src/assets/icons/Vegetables/Parsnips.svg'
import pumpkin from 'src/assets/icons/Vegetables/Pumpkin.svg'
import brussels_sprouts from 'src/assets/icons/Vegetables/Brussels_Sprouts.svg'
import potatoes from 'src/assets/icons/Vegetables/Potatoes.svg'
import spinach from 'src/assets/icons/Vegetables/Spinach.svg'
import collard from 'src/assets/icons/Vegetables/Collard.svg'
import sweet_potato from 'src/assets/icons/Vegetables/Sweet_potato.svg'
import white_rice from 'src/assets/icons/Rice/White_rice.svg'
import brown_rice from 'src/assets/icons/Rice/Brown_rice.svg'
import basmati_rice from 'src/assets/icons/Rice/Basmati_rice.svg'
import eggs from 'src/assets/icons/Proteins/Eggs.svg'
import beef_liver from 'src/assets/icons/Proteins/Beef_liver.svg'
import chicken from 'src/assets/icons/Proteins/Chicken.svg'
import chicken_skin from 'src/assets/icons/Proteins/Chicken_skin.svg'
import red_meat from 'src/assets/icons/Proteins/Red_meat.svg'
import processed_meat from 'src/assets/icons/Proteins/Processed_meat.svg'
import egg_yolk from 'src/assets/icons/Proteins/Egg_yolk.svg'
import falafel from 'src/assets/icons/Other/Falafel.svg'
import cocoa_powder from 'src/assets/icons/Other/Cocoa_powder.svg'
import tofu from 'src/assets/icons/Other/Tofu.svg'
import pancakes from 'src/assets/icons/Other/Pancakes.svg'
import omega_3_supplement from 'src/assets/icons/Other/Omega_3_supplement.svg'
import chocolate from 'src/assets/icons/Other/Chocolate.svg'
import sun_exposure from 'src/assets/icons/Other/Sun_exposure.svg'
import pastry_products from 'src/assets/icons/Other/Pastry_products.svg'
import canned_products from 'src/assets/icons/Other/Canned_products.svg'
import ultra_processed_food from 'src/assets/icons/Other/Ultra_processed_food.svg'
import cakes from 'src/assets/icons/Other/Cakes.svg'
import nutritional_yeast from 'src/assets/icons/Other/Nutritional_yeast.svg'
import vitamin_d_supplement from 'src/assets/icons/Other/Vitamin_D_supplement.svg'
import dark_chocolate from 'src/assets/icons/Other/Dark_chocolate.svg'
import sockeye_salmon from 'src/assets/icons/Oily_fish/Sockeye_salmon.svg'
import salmon from 'src/assets/icons/Oily_fish/Salmon.svg'
import oily_fish from 'src/assets/icons/Oily_fish/Oily_fish.svg'
import noodles from 'src/assets/icons/Pasta/Noodles.svg'
import pasta from 'src/assets/icons/Pasta/Pasta.svg'
import egg_noodles from 'src/assets/icons/Pasta/Egg_noodles.svg'
import white_bread from 'src/assets/icons/Pasta/White_bread.svg'
import kettlebell_swings from 'src/assets/icons/Sports/Kettlebell_swings.svg'
import lifting_weights from 'src/assets/icons/Sports/Lifting_weights.svg'
import aerobic_strength_circuit from 'src/assets/icons/Sports/Aerobic_strength_circuit.svg'
import box_jump from 'src/assets/icons/Sports/Box_jump.svg'
import stair_climbing from 'src/assets/icons/Sports/Stair_climbing.svg'
import spinning from 'src/assets/icons/Sports/Spinning.svg'
import hiit from 'src/assets/icons/Sports/HIIT.svg'
import skipping from 'src/assets/icons/Sports/Skipping.svg'
import hiking from 'src/assets/icons/Sports/Hiking.svg'
import cycling from 'src/assets/icons/Sports/Cycling.svg'
import elliptical from 'src/assets/icons/Sports/Elliptical.svg'
import running from 'src/assets/icons/Sports/Running.svg'
import dancing from 'src/assets/icons/Sports/Dancing.svg'
import boxing from 'src/assets/icons/Sports/Boxing.svg'
import swimming from 'src/assets/icons/Sports/Swimming.svg'
import tennis from 'src/assets/icons/Sports/Tennis.svg'
import clams from 'src/assets/icons/Shellfish/Clams.svg'
import mussels from 'src/assets/icons/Shellfish/Mussels.svg'
import oysters from 'src/assets/icons/Shellfish/Oysters.svg'
import apple_cider_vinegar from 'src/assets/icons/Herbs_seasoning/Apple_cider_vinegar.svg'
import butter from 'src/assets/icons/Herbs_seasoning/Butter.svg'
import parsley from 'src/assets/icons/Herbs_seasoning/Parsley.svg'
import avocado_oil from 'src/assets/icons/Herbs_seasoning/Avocado_oil.svg'
import black_pepper from 'src/assets/icons/Herbs_seasoning/Black_pepper.svg'
import table_salt from 'src/assets/icons/Herbs_seasoning/Table_salt.svg'
import olive_oil from 'src/assets/icons/Herbs_seasoning/Olive_oil.svg'
import lemon_juice from 'src/assets/icons/Herbs_seasoning/Lemon_juice.svg'
import vegetable_broth from 'src/assets/icons/Herbs_seasoning/Vegetable_broth.svg'
import sesame_oil from 'src/assets/icons/Herbs_seasoning/Sesame_oil.svg'
import turmeric from 'src/assets/icons/Herbs_seasoning/Turmeric.svg'
import rye_flour from 'src/assets/icons/Grains/Rye_flour.svg'
import hummus from 'src/assets/icons/Grains/Hummus.svg'
import oat_bran from 'src/assets/icons/Grains/Oat_bran.svg'
import amaranth from 'src/assets/icons/Grains/Amaranth.svg'
import quinoa from 'src/assets/icons/Grains/Quinoa.svg'
import buckwheat from 'src/assets/icons/Grains/Buckwheat.svg'
import cous_cous from 'src/assets/icons/Grains/Cous_cous.svg'
import granola from 'src/assets/icons/Grains/Granola.svg'
import cereal from 'src/assets/icons/Grains/Ceareal.svg'
import nuts from 'src/assets/icons/Nuts_and_Seeds/Nuts.svg'
import brazil_nuts from 'src/assets/icons/Nuts_and_Seeds/Brazil_nuts.svg'
import almonds from 'src/assets/icons/Nuts_and_Seeds/Almonds.svg'
import pistachios from 'src/assets/icons/Nuts_and_Seeds/Pistachios.svg'
import pecan from 'src/assets/icons/Nuts_and_Seeds/Pecan.svg'
import pumpkin_seeds from 'src/assets/icons/Nuts_and_Seeds/Pumpkin_seeds.svg'
import macadamia from 'src/assets/icons/Nuts_and_Seeds/Macadamia.svg'
import chia_seeds from 'src/assets/icons/Nuts_and_Seeds/Chia_seeds.svg'
import walnut from 'src/assets/icons/Nuts_and_Seeds/Walnut.svg'
import sunflower_seeds from 'src/assets/icons/Nuts_and_Seeds/Sunflower_seeds.svg'
import pine_nuts from 'src/assets/icons/Nuts_and_Seeds/Pine_nuts.svg'
import grape_juice from 'src/assets/icons/Fruits/Grape juice.svg'
import blueberries from 'src/assets/icons/Fruits/Blueberries.svg'
import raspberries from 'src/assets/icons/Fruits/Raspberries.svg'
import blackcurrants from 'src/assets/icons/Fruits/Blackcurrants.svg'
import acerola_cherries from 'src/assets/icons/Fruits/Acerola_cherries.svg'
import papaya from 'src/assets/icons/Fruits/Papaya.svg'
import kiwi from 'src/assets/icons/Fruits/Kiwi.svg'
import apple_juice from 'src/assets/icons/Fruits/Apple_juice.svg'
import guava from 'src/assets/icons/Fruits/Guava.svg'
import orange_juice from 'src/assets/icons/Fruits/Orange_juice.svg'
import cranberries from 'src/assets/icons/Fruits/Cranberries.svg'
import blackberries from 'src/assets/icons/Fruits/Blackberries.svg'
import avocado from 'src/assets/icons/Fruits/Avocado.svg'
import strawberries from 'src/assets/icons/Fruits/Strawberries.svg'
import pomegranate from 'src/assets/icons/Fruits/Pomegranate.svg'
import apple from 'src/assets/icons/Fruits/Apple.svg'
import grapes from 'src/assets/icons/Fruits/Grapes.svg'
import starfruit from 'src/assets/icons/Fruits/Starfruit.svg'
import milk from 'src/assets/icons/Dairy/Milk.svg'
import yogurt from 'src/assets/icons/Dairy/Yogurt.svg'
import cottage_cheese from 'src/assets/icons/Dairy/Cottage_cheese.svg'
import ice_cream from 'src/assets/icons/Dairy/Ice_cream.svg'
import cheese from 'src/assets/icons/Dairy/Cheese.svg'
import mackerel from 'src/assets/icons/Fish/Mackerel.svg'
import sardines from 'src/assets/icons/Fish/Sardines.svg'
import herring from 'src/assets/icons/Fish/Herring.svg'
import trout from 'src/assets/icons/Fish/Trout.svg'
import halibut from 'src/assets/icons/Fish/Halibut.svg'
import tuna from 'src/assets/icons/Fish/Tuna.svg'
import folate from 'src/assets/icons/Reports/Folate.svg'
import caffeine from 'src/assets/icons/Reports/Caffeine.svg'
import tastesalt from 'src/assets/icons/Reports/TasteSalt.svg'
import alcohol from 'src/assets/icons/Reports/Alcohol.svg'
import processingsaturated from 'src/assets/icons/Reports/ProcessingSaturated.svg'
import tastefat from 'src/assets/icons/Reports/TasteFat.svg'
import glucose from 'src/assets/icons/Reports/Glucose.svg'
import processingtriglyceride from 'src/assets/icons/Reports/ProcessingTriglyceride.svg'
import gluten from 'src/assets/icons/Reports/Gluten.svg'
import tastebitterness from 'src/assets/icons/Reports/TasteBitterness.svg'
import eatinghabits from 'src/assets/icons/Reports/EatingHabits.svg'
import vitamind from 'src/assets/icons/Reports/VitaminD.svg'
import exercise from 'src/assets/icons/Reports/Exercise.svg'
import processingomega3 from 'src/assets/icons/Reports/ProcessingOmega3.svg'
import selenium from 'src/assets/icons/Reports/Selenium.svg'
import lactose from 'src/assets/icons/Reports/Lactose.svg'

export const report_icons = {
  wine: wine,
  whiskey: whiskey,
  espresso: espresso,
  beer: beer,
  'energy-drinks': energy_drinks,
  'passionflower-tea': passionflower_tea,
  soda: soda,
  coffee: coffee,
  tequila: tequila,
  'ice-mocha': ice_mocha,
  'green-tea': green_tea,
  'black-tea': black_leaf_tea,
  vodka: vodka,
  'hot-cocoa': hot_cocoa,
  cola: cola,
  rum: rum,
  'chocolate-milk': chocolate_milk,
  'camomile-tea': camomile_tea,
  'tart-cherry-juice': tart_cherry_juice,
  'kidney-beans': kidney_beans,
  lentils: lentils,
  'white-beans': white_beans,
  'chickpea-pasta': chickpea_pasta,
  edamame: edamame,
  'soya-beans': soya_beans,
  chickpeas: chickpea,
  asparagus: asparagus,
  artichoke: artichoke,
  'mustard-greens': mustard_greens,
  cassava: cassava,
  mushrooms: mushrooms,
  corn: corn,
  'yellow-peppers': yellow_pepper,
  'butternut-squash': butternut_squash,
  cauliflower: cauliflower,
  kale: kale,
  carrots: carrots,
  broccoli: broccoli,
  'red-cabbage': red_cabbage,
  'turnip-greens': turnip_greens,
  parsnips: parsnips,
  pumpkin: pumpkin,
  'brussel-sprouts': brussels_sprouts,
  potatoes: potatoes,
  spinach: spinach,
  'lifting-weight': lifting_weights,
  collard: collard,
  'sweet-potato': sweet_potato,
  'white-rice': white_rice,
  'brown-rice': brown_rice,
  'basmati-rice': basmati_rice,
  eggs: eggs,
  'beef-liver': beef_liver,
  chicken: chicken,
  'chicken-skin': chicken_skin,
  'red-meat': red_meat,
  'processed-meat': processed_meat,
  'egg-yolk': egg_yolk,
  falafel: falafel,
  'cocoa-powder': cocoa_powder,
  tofu: tofu,
  pancakes: pancakes,
  'omega-3-supplement': omega_3_supplement,
  chocolate: chocolate,
  'sun-exposure': sun_exposure,
  'pastry-products': pastry_products,
  'canned-products': canned_products,
  'ultra-processed-foods': ultra_processed_food,
  cakes: cakes,
  'nutritional-yeast': nutritional_yeast,
  'vitamin-d-supplement': vitamin_d_supplement,
  'dark-chocolate': dark_chocolate,
  'sockeye-salmon': sockeye_salmon,
  salmon: salmon,
  'oily-fish': oily_fish,
  noodles: noodles,
  pasta: pasta,
  'egg-noodles': egg_noodles,
  'white-bread': white_bread,
  'kettlebell-swing': kettlebell_swings,
  'aerobic-strength-circuit': aerobic_strength_circuit,
  'box-jump': box_jump,
  'stair-climbing': stair_climbing,
  spinning: spinning,
  hiit: hiit,
  skipping: skipping,
  hiking: hiking,
  cycling: cycling,
  elliptical: elliptical,
  running: running,
  dancing: dancing,
  boxing: boxing,
  swimming: swimming,
  tennis: tennis,
  clams: clams,
  mussels: mussels,
  oysters: oysters,
  'apple-cider-vinegar': apple_cider_vinegar,
  butter: butter,
  parsley: parsley,
  'avocado-oil': avocado_oil,
  'black-pepper': black_pepper,
  'table-salt': table_salt,
  'olive-oil': olive_oil,
  'lemon-juice': lemon_juice,
  'vegetable-broth': vegetable_broth,
  'sesame-oil': sesame_oil,
  turmeric: turmeric,
  'rye-flour': rye_flour,
  hummus: hummus,
  'oat-bran': oat_bran,
  amaranth: amaranth,
  quinoa: quinoa,
  buckwheat: buckwheat,
  couscous: cous_cous,
  granola: granola,
  cereal: cereal,
  nuts: nuts,
  'brazil-nuts': brazil_nuts,
  almonds: almonds,
  pistachios: pistachios,
  pecan: pecan,
  'pumpkin-seeds': pumpkin_seeds,
  macadamia: macadamia,
  'chia-seeds': chia_seeds,
  walnuts: walnut,
  'sunflower-seeds': sunflower_seeds,
  'pine-nuts': pine_nuts,
  'grape-juice': grape_juice,
  blueberries: blueberries,
  raspberries: raspberries,
  blackcurrants: blackcurrants,
  'acerola-cherries': acerola_cherries,
  papaya: papaya,
  kiwi: kiwi,
  'apple-juice': apple_juice,
  guavas: guava,
  'orange-juice': orange_juice,
  cranberries: cranberries,
  blackberries: blackberries,
  avocado: avocado,
  strawberries: strawberries,
  pomegranate: pomegranate,
  apple: apple,
  grapes: grapes,
  starfruit: starfruit,
  milk: milk,
  yogurt: yogurt,
  'cottage-cheese': cottage_cheese,
  'ice-cream': ice_cream,
  cheese: cheese,
  mackerel: mackerel,
  sardines: sardines,
  herring: herring,
  trout: trout,
  halibut: halibut,
  tuna: tuna,
  folate: folate,
  caffeine: caffeine,
  tastesalt: tastesalt,
  alcohol: alcohol,
  processingsaturated: processingsaturated,
  tastefat: tastefat,
  glucose: glucose,
  processingtriglyceride: processingtriglyceride,
  gluten: gluten,
  tastebitterness: tastebitterness,
  eatinghabits: eatinghabits,
  vitamind: vitamind,
  exercise: exercise,
  outside: sun_exposure,
  processingomega3: processingomega3,
  selenium: selenium,
  lactose: lactose,
}

export const report_names = {
  wine: 'Wine',
  whiskey: 'Whiskey',
  espresso: 'Espresso',
  beer: 'Beer',
  'energy-drinks': 'Energy drinks',
  'passionflower-tea': 'Passionflower tea',
  soda: 'Soda',
  coffee: 'Coffee',
  tequila: 'Tequila',
  'ice-mocha': 'Ice Mocha',
  'green-tea': 'Green tea',
  'black-leaf-tea': 'Black leaf tea',
  vodka: 'Vodka',
  'hot-cocoa': 'Hot cocoa',
  cola: 'Cola',
  rum: 'Rum',
  'chocolate-milk': 'Chocolate milk',
  'camomile-tea': 'Camomile tea',
  'tart-cherry-juice': 'Tart cherry juice',
  'kidney-beans': 'Kidney beans',
  lentils: 'Lentils',
  'white-beans': 'White beans',
  'chickpea-pasta': 'Chickpea pasta',
  edamame: 'Edamame',
  'soya-beans': 'Soya beans',
  chickpeas: 'Chickpea',
  asparagus: 'Asparagus',
  artichoke: 'Artichoke',
  'mustard-greens': 'Mustard greens',
  cassava: 'Cassava',
  mushrooms: 'Mushrooms',
  corn: 'Corn',
  'yellow-peppers': 'Yellow pepper',
  'butternut-squash': 'Butternut squash',
  cauliflower: 'Cauliflower',
  kale: 'Kale',
  carrots: 'Carrots',
  broccoli: 'Broccoli',
  'red-cabbage': 'Red cabbage',
  'turnip-greens': 'Turnip greens',
  parsnips: 'Parsnips',
  pumpkin: 'Pumpkin',
  'brussel-sprouts': 'Brussels Sprouts',
  potatoes: 'Potatoes',
  spinach: 'Spinach',
  'lifting-weight': 'Lifting weights',
  collard: 'Collard',
  'sweet-potato': 'Sweet potato',
  'white-rice': 'White rice',
  'brown-rice': 'Brown rice',
  'basmati-rice': 'Basmati rice',
  eggs: 'Eggs',
  'beef-liver': 'Beef liver',
  chicken: 'Chicken',
  'chicken-skin': 'Chicken skin',
  'red-meat': 'Red meat',
  'processed-meat': 'Processed meat',
  'egg-yolk': 'Egg yolk',
  falafel: 'Falafel',
  'cocoa-powder': 'Cocoa powder',
  tofu: 'Tofu',
  pancakes: 'Pancakes',
  'omega-3-supplement': 'Omega 3 supplement',
  chocolate: 'Chocolate',
  'sun-exposure': 'Sun exposure',
  'pastry-products': 'Pastry products',
  'canned-products': 'Canned products',
  'ultra-processed-foods': 'Ultra processed food',
  cakes: 'Cakes',
  'nutritional-yeast': 'Nutritional yeast',
  'vitamin-d-supplement': 'Vitamin D supplement',
  'dark-chocolate': 'Dark chocolate',
  'sockeye-salmon': 'Sockeye salmon',
  salmon: 'Salmon',
  'oily-fish': 'Oily fish',
  noodles: 'Noodles',
  pasta: 'Pasta',
  'egg-noodles': 'Egg noodles',
  'white-bread': 'White bread',
  'kettlebell-swings': 'Kettlebell swings',
  'aerobic-strength-circuit': 'Aerobic strength circuit',
  'box-jump': 'Box jump',
  'stair-climbing': 'Stair climbing',
  spinning: 'Spinning',
  hiit: 'HIIT',
  skipping: 'Skipping',
  hiking: 'Hiking',
  cycling: 'Cycling',
  elliptical: 'Elliptical',
  running: 'Running',
  dancing: 'Dancing',
  boxing: 'Boxing',
  swimming: 'Swimming',
  tennis: 'Tennis',
  clams: 'Clams',
  mussels: 'Mussels',
  oysters: 'Oysters',
  'apple-cider-vinegar': 'Apple cider vinegar',
  butter: 'Butter',
  parsley: 'Parsley',
  'avocado-oil': 'Avocado oil',
  'black-pepper': 'Black pepper',
  'table-salt': 'Table salt',
  'olive-oil': 'Olive oil',
  'lemon-juice': 'Lemon juice',
  'vegetable-broth': 'Vegetable broth',
  'sesame-oil': 'Sesame oil',
  turmeric: 'Turmeric',
  'rye-flour': 'Rye flour',
  hummus: 'Hummus',
  'oat-bran': 'Oat bran',
  amaranth: 'Amaranth',
  quinoa: 'Quinoa',
  buckwheat: 'Buckwheat',
  couscous: 'Cous cous',
  granola: 'Granola',
  cereal: 'Cereal',
  nuts: 'Nuts',
  'brazil-nuts': 'Brazil nuts',
  almonds: 'Almonds',
  pistachios: 'Pistachios',
  pecan: 'Pecan',
  'pumpkin-seeds': 'Pumpkin seeds',
  macadamia: 'Macadamia',
  'chia-seeds': 'Chia seeds',
  walnuts: 'Walnut',
  'sunflower-seeds': 'Sunflower seeds',
  'pine-nuts': 'Pine nuts',
  'grape-juice': 'Grape juice',
  blueberries: 'Blueberries',
  raspberries: 'Raspberries',
  blackcurrants: 'Blackcurrants',
  'acerola-cherries': 'Acerola cherries',
  papaya: 'Papaya',
  kiwi: 'Kiwi',
  'apple-juice': 'Apple juice',
  guavas: 'Guava',
  'orange-juice': 'Orange juice',
  cranberries: 'Cranberries',
  blackberries: 'Blackberries',
  avocado: 'Avocado',
  strawberries: 'Strawberries',
  pomegranate: 'Pomegranate',
  apple: 'Apple',
  grapes: 'Grapes',
  starfruit: 'Starfruit',
  milk: 'Milk',
  yogurt: 'Yogurt',
  'cottage-cheese': 'Cottage cheese',
  'ice-cream': 'Ice cream',
  cheese: 'Cheese',
  mackerel: 'Mackerel',
  sardines: 'Sardines',
  herring: 'Herring',
  trout: 'Trout',
  halibut: 'Halibut',
  tuna: 'Tuna',
  folate: 'Folate',
  caffeine: 'Caffeine',
  tastesalt: 'TasteSalt',
  alcohol: 'Alcohol',
  processingsaturated: 'ProcessingSaturated',
  tastefat: 'TasteFat',
  glucose: 'Glucose',
  processingtriglyceride: 'ProcessingTriglyceride',
  gluten: 'Gluten',
  tastebitterness: 'TasteBitterness',
  eatinghabits: 'EatingHabits',
  vitamind: 'VitaminD',
  exercise: 'Exercise',
  outside: 'Outdoor Exercise',
  processingomega3: 'ProcessingOmega3',
  selenium: 'Selenium',
  lactose: 'Lactose',
}

export const image_headers = {
  'aerobic-capacity': aerobic_capacity,
  ace: ace,
  alcohol: report_alcohol,
  'b3-and-insulin': adrenergic_receptors,
  'bcaa-and-glucose': bcaa_and_glucose,
  'beta-carotene': beta_carotene,
  'choline-deficiency': choline_deficiency,
  caffeine: report_caffeine,
  'caffeine-and-anxiety': caffeine_and_anxiety,
  'calcium-levels': calcium_levels,
  'carbohydrate-response': carbohydrate_response,
  'ldl-cholesterol': cholesterol,
  appetite: appetite,
  fat: fat,
  'folate-deficiency': folate_deficiency,
  glucose: report_glucose,
  'glucose-management': report_glucose,
  'gluten-intolerance': gluten_intolerance,
  'glycemic-response': glycemic_response,
  'insulin-resistance': insulin_resistance,
  'iron-deficiency': iron_deficiency,
  'iron-overload': iron_overload,
  'lactose-intolerance': lactose_intolerance,
  'meal-time': meal_time,
  'metabolic-syndrome': metabolic_syndrome,
  'muscle-power': muscle_power,
  'omega-3': omega_3,
  'omega-3-deficiency': omega_3,
  'oxidative-stress': oxidative_stress,
  'pgc-and-glucose': pgc_and_glucose,
  'protein-response': protein_response,
  'saturated-fat': saturated_fat,
  triglyceride: triglyceride,
  'triglyceride-levels': triglyceride,
  sodium: sodium,
  'sodium-sensitivity': sodium,
  selenium: report_selenium,
  'taste-bitterness': taste_bitterness,
  'taste-fat': taste_fat,
  'taste-salt': taste_salt,
  'vitamin-d-deficiency': vitamin_d_deficiency,
  'vitamin-C': vitamin_c_deficiency,
  'vitamin-B12-deficiency': vitamin_B12_deficiency,
  'weight-and-exercise': weight_and_exercise,
  'weight-loss-maintenance': weight_loss_maintenance,
  'weight-management': weight_management,
  'zinc-and-glucose': zinc_and_glucose,
}
