import React, {useEffect, useState} from 'react'
import {useParams, Link, useHistory} from 'react-router-dom'
import {getTestName, message_toast} from 'src/shared/reactstrap-toolbox'
import {faX} from '@fortawesome/free-solid-svg-icons'
import {Alert, Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap'
import {getTestTypeValueFromUrl} from '../../assessment_utils'

const SuccessPage = () => {
  const {id: bid, test_type} = useParams()
  const [bookingId, setBookingId] = useState()
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState('')
  const [latest, setLatest] = useState('')
  const [currentKit, setCurrentKit] = useState('')
  const [status, setStatus] = useState()
  const [modalOpen, setModalOpen] = useState(false)

  const history = useHistory()

  const toggleModal = () => setModalOpen(!modalOpen)

  const rejectBooking = async () => {
    const url = `/api/dashboard/registration/reject-booking/?bid=${bid}`
    const response = await window.app.requests.post(url, null, {
      expected_status: [200, 400],
    })

    if (response.status !== 200) {
      setError(response.data.message || response.data.details?.description)
    }

    if (response.status === 200) {
      message_toast({
        icon: faX,
        title: 'Reject Booking',
        message: 'Your appointment has been rejected successfully. You can proceed to book a new one now.',
        onClick: () => {},
        className: '',
        time: 3000,
      })
      history.push(`/bloods/boots/${test_type || sessionStorage.getItem('test_type')}`)
    }
  }

  const handleRejectBooking = () => {
    toggleModal()
  }

  const handleConfirmReject = () => {
    rejectBooking()
    toggleModal()
  }

  const fetchDetails = async () => {
    const url = `/api/dashboard/registration/details/?bid=${bid}`
    const response = await window.app.requests.get(url, null, {
      expected_status: [200, 400],
    })
    if (response.status !== 200) {
      setError(response.data.message || response.data.details?.description)
    }
    if (response.data.personal.status) {
      setStatus(response.data.personal.status)
    }
    setCurrentKit(response.data.personal.bstatus)
    setLatest(response.data.personal.booking_status)
    setBookingId(response.data.personal.booking_id)
  }

  useEffect(() => {
    fetchDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, latest])

  return (
    <div className="pt-sm-4 pl-sm-2 pl-md-2 mt-4">
      <h1>APPOINTMENT CONFIRMATION</h1>
      <div className="row justify-content-center py-5">
        <div className="col-md-12">
          <h3>Success!</h3>
          <p>
            Your registration with Boots In-store Wellness Testing Service powered by MyHealthChecked is now complete.
          </p>
          <hr />
          <h3>Test type: {getTestName(getTestTypeValueFromUrl(test_type))} </h3>
          <p>
            When you arrive for your appointment, please provide the health professional with registration number{' '}
            <strong>{status === 'archived' ? bookingId + '-2' : bookingId}.</strong>
          </p>
          <p>You can edit your registration details until the day of your appointment.</p>
          <Button
            className="mt-2 text-white font-weight-bold"
            tag={Link}
            to={`/bloods/boots/assessment-single/${getTestTypeValueFromUrl(test_type)}/${bid}/`}
            color="primary"
          >
            {latest !== 'completed' ? 'Edit details' : 'View details'}
          </Button>

          {!currentKit && currentKit !== 'activating' && latest !== 'completed' && latest !== 'rejected' && (
            <Alert className="mt-4 text-justify" color="warning">
              <strong class="alert-heading">Important Reminder: </strong>
              <p>
                You can only register for one test at a time. Once you’ve received your test results, you’ll be able to
                register for another.
              </p>
              <p>
                <strong>Please note:</strong> It's essential to complete your registration before arriving at Boots for
                your in-store appointment. If your registration is incomplete, you may be unable to proceed with your
                test.
              </p>
              <p>If you'd like to cancel your current registration, please select "Cancel" below.</p>
              <span>
                <Button color="danger text-white mt-4 font-weight-bold" onClick={() => handleRejectBooking()}>
                  Cancel Registration
                </Button>
              </span>
            </Alert>
          )}

          <Modal isOpen={modalOpen} toggle={toggleModal}>
            <ModalHeader toggle={toggleModal}>Confirm Rejection</ModalHeader>
            <ModalBody>Are you sure you want to reject this appointment? This action cannot be undone.</ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggleModal}>
                Cancel
              </Button>
              <Button color="danger" onClick={handleConfirmReject}>
                Confirm
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    </div>
  )
}

export default SuccessPage
