import React, {useRef} from 'react'
import {Link} from 'react-router-dom'
import {Row, Col, Alert, Button} from 'reactstrap'
import allFields, {Field, Fieldset} from './fields'

// For testing API validations, set `required` to false
// Otherwise we'd have to fill each step fully
const requireConditionalSteps = true

export const TEST_TYPES = {
  'blood-cholesterol': 'blood-cholesterol',
  'blood-energy': 'blood-energy',
  'blood-female-sex-health': 'blood-female-sex-health',
  'blood-general': 'blood-general',
  'blood-heart': 'blood-heart',
  'blood-male-sex-health': 'blood-male-sex-health',
  'blood-menopause': 'blood-menopause',
  'blood-polycystic': 'blood-polycystic',
  'blood-vitamins': 'blood-vitamins',
  'blood-weight': 'blood-weight',
  'blood-fertility': 'blood-fertility',
  'blood-progesterone': 'blood-progesterone',
}

export type StepName =
  | 'start'
  | 'body'
  | 'smoking'
  | 'alcohol'
  | 'activity'
  | 'medical_conditions'
  | 'medications'
  | 'menstrual'
  | 'sexual_health'
  | 'summary'

export interface Step {
  title: string
  fields: Fieldset
  field_render: ({fields, RenderField, formData}: StepRenderProps) => any
  before?: () => JSX.Element
}

interface InvalidStep {
  stepNumber: number
  stepTitle: string
}

interface StepRenderProps {
  fields: Fieldset
  RenderField: ({field, optional}: {field: Field; optional?: boolean}) => any
  formData: {[key: string]: any}
  formProps: {
    invalidSteps: InvalidStep[]
    testID: string
    testType: TestType
  }
}

// 2. Body step
const RenderBody = ({fields, RenderField, formData}: StepRenderProps) => {
  const lastUnits = useRef(null)

  if (lastUnits.current !== formData?.units) {
    if (formData.units === 'Metric') {
      if ('height' in formData) {
        formData.height = Number(formData.height * 2.54).toFixed(2)
      }

      if ('weight' in formData) {
        formData.weight = Number(formData.weight / 2.205).toFixed(2)
      }
    } else if (formData.units === 'Imperial') {
      if ('height' in formData) {
        formData.height = Number(formData.height / 2.54).toFixed(2)
      }

      if ('weight' in formData) {
        formData.weight = Number(formData.weight * 2.205).toFixed(2)
      }
    }

    lastUnits.current = formData.units
  }

  const {subfields} = fields.body

  subfields.height = {
    title: subfields.height.title,
    required: subfields.height.required,
    type: subfields.height.type,
    name: subfields.height.name,
    min: subfields.height.title.includes('cm') ? 50 : 20,
    max: subfields.height.title.includes('cm') ? 300 : 110,
    step: subfields.height.step,
  }
  subfields.weight = {
    title: subfields.weight.title,
    required: subfields.weight.required,
    type: subfields.weight.type,
    name: subfields.weight.name,
    min: subfields.weight.title.includes('kg') ? 20 : 40,
    max: subfields.weight.title.includes('kg') ? 500 : 1110,
    step: subfields.weight.step,
  }
  const heightField = subfields.height
  const weightField = subfields.weight
  const units = formData?.units || 'Metric'
  const weightUnit = units === 'Imperial' ? 'lbs' : 'kg'
  const heightUnit = units === 'Imperial' ? 'inch' : 'cm'

  heightField.title = `Height (${heightUnit})`
  weightField.title = `Weight (${weightUnit})`

  return (
    <Row>
      <Col xs={12}>
        <RenderField field={heightField} />
      </Col>
      <Col xs={12}>
        <RenderField field={weightField} />
      </Col>
      <Col xs={12}>
        <RenderField field={subfields.units} />
      </Col>
    </Row>
  )
}

// 3. Smoking step
const RenderSmoking = ({fields, RenderField, formData}: StepRenderProps) => {
  const {subfields} = fields.smoking
  const showDetails = formData?.smoking === 'Yes'

  return (
    <Row>
      <Col key={subfields.smoking.name} xs={12}>
        <RenderField field={subfields.smoking} />
      </Col>

      {/* Show field if 'smoking' field value is equal to 'Yes' */}
      {showDetails && (
        <Col key={subfields.cigarettes_per_day.name} xs={12}>
          <RenderField field={{...subfields.cigarettes_per_day, required: requireConditionalSteps}} />
        </Col>
      )}
    </Row>
  )
}

// 4. Alcohol step
const RenderAlcohol = ({fields, RenderField, formData}: StepRenderProps) => {
  const {subfields} = fields.alcohol

  const showDetails = formData?.alcohol === 'Yes'

  return (
    <Row>
      <Col key={subfields.alcohol.name} xl={12}>
        <RenderField field={subfields.alcohol} />
      </Col>

      {/* Show field if 'alcohol' field value is equal to 'Yes' */}
      {showDetails && (
        <Col key={subfields.alcohol_units_per_week.name} xl={12}>
          <RenderField field={{...subfields.alcohol_units_per_week, required: requireConditionalSteps}} />
        </Col>
      )}
    </Row>
  )
}

// 5. Activity step
const RenderActivity = ({fields, RenderField, formData}: StepRenderProps) => {
  const {physical_activity, ...restOfFields} = fields.activity.subfields

  const showDetails = formData?.physical_activity === 'Yes'

  return (
    <Row>
      <Col key={physical_activity.name} xs={12}>
        <RenderField field={physical_activity} />
      </Col>

      {/* Show field if 'physical_activity' field value is equal to 'Yes' */}
      {showDetails &&
        Object.values(restOfFields).map(field => (
          <Col key={field.name} xs={12}>
            <RenderField field={field} />
          </Col>
        ))}
    </Row>
  )
}

// 6. Medical conditions step
const RenderMedicalConditions = ({fields, RenderField, formData}: StepRenderProps) => {
  const {other_description, condition, was_diagnosed} = fields.medical_conditions.subfields

  const showSubfields = formData?.was_diagnosed === 'Yes'
  const showDetails = showSubfields && !!formData?.condition?.length && formData.condition.includes('Other')

  return (
    <Row>
      <Col xs={12}>
        <RenderField field={was_diagnosed} />
      </Col>

      {showSubfields && (
        <Col xs={12}>
          <RenderField field={{...condition, className: 'checkbox--columns', required: showSubfields}} />
        </Col>
      )}

      {/* Show field to describe other conditions if "Other" field is checked */}
      {showDetails && (
        <Col xs={12}>
          <RenderField field={{...other_description, required: requireConditionalSteps}} />
        </Col>
      )}
    </Row>
  )
}

// 7. Medications step
const RenderMedications = ({fields, RenderField, formData}: StepRenderProps) => {
  const {subfields} = fields.medications

  const showSubfields = formData?.is_taking === 'Yes'

  // Show field to describe other medications if "Other" field is checked
  const showDescription = showSubfields && formData?.medications && formData?.medications.includes('Other')
  // Show dosage if any checkbox is ticked
  const showDosageInput = showSubfields && !!formData?.medications?.length

  return (
    <Row>
      <Col xs={12}>
        <RenderField field={subfields.is_taking} />
        {showSubfields && (
          <RenderField field={{...subfields.medications, className: 'checkbox--columns', required: showSubfields}} />
        )}
      </Col>

      <Col xs={12}>
        {showDosageInput && (
          <div>
            <RenderField field={{...subfields.details, required: requireConditionalSteps}} />
            {/* Other medications */}
            {showDescription && (
              <RenderField field={{...subfields.other_description, required: requireConditionalSteps}} />
            )}
          </div>
        )}
      </Col>
    </Row>
  )
}

// 8. Menstrual step (conditional)
const RenderMenstrual = ({fields, RenderField}: StepRenderProps) => {
  const {subfields} = fields.menstrual
  return (
    <Row>
      {Object.values(subfields).map(field => (
        <Col key={field.name} xs={12}>
          <RenderField field={field} />
        </Col>
      ))}
    </Row>
  )
}

// 9. Sexual health step (conditional)
const RenderSexualHealth = ({fields, RenderField, formData}: StepRenderProps) => {
  const {subfields} = fields.sexual_health

  const showDetails = formData?.sexually_active === 'Yes'
  const showUnprotectedSexType = formData?.had_unprotected_sex === 'Yes'
  const showSymptomsDetails = formData?.sti_symptoms === 'Yes'
  const showStiType = formData?.potential_sti_exposure === 'Yes'

  return (
    <Row>
      <Col xs={12}>
        <RenderField field={subfields.sexually_active} />
        {showDetails && (
          <>
            <RenderField field={{...subfields.partners, required: requireConditionalSteps}} />
            <RenderField field={{...subfields.recent_sex, required: requireConditionalSteps}} />
            <RenderField field={{...subfields.had_unprotected_sex, required: requireConditionalSteps}} />
            {showUnprotectedSexType && (
              <RenderField field={{...subfields.unprotected_sex_type, required: requireConditionalSteps}} />
            )}
          </>
        )}
      </Col>

      <Col xs={12}>
        <RenderField field={subfields.sti_diagnosed} />
        <RenderField field={subfields.sti_symptoms} />

        {showSymptomsDetails && (
          <RenderField field={{...subfields.sti_symptoms_description, required: requireConditionalSteps}} />
        )}

        <RenderField field={subfields.potential_sti_exposure} />
        {showStiType && (
          <RenderField field={{...subfields.potential_sti_exposure_type, required: requireConditionalSteps}} />
        )}
      </Col>
    </Row>
  )
}

const RenderSummary = ({formProps}: StepRenderProps) => {
  const {invalidSteps, testID} = formProps

  // Show list of invalid steps and maybe errors for fields
  const renderErrors = () => {
    if (!invalidSteps?.length) {
      return null
    }

    return (
      <section>
        <Alert color="danger">
          <h4 className="alert-heading mt-1">Following steps are incomplete</h4>
          <p>Please check entered data and submit again.</p>
          <ol className="mb-0">
            {invalidSteps.map(({stepNumber, stepTitle}) => (
              <li key={stepNumber} className="mb-1">
                <Link to={`/assessment/${testID}/${stepNumber}`} className="mr-2" style={{color: 'inherit'}}>
                  {stepTitle}
                </Link>
                <Button tag={Link} to={`/assessment/${testID}/${stepNumber}`} size="sm" color="light">
                  Edit
                </Button>
              </li>
            ))}
          </ol>
        </Alert>
      </section>
    )
  }

  return (
    <div>
      <p className="lead">
        Our doctor will review your test results in the context of this Health Assessment. Please check that all
        details are correct before submitting, as you will not be able to edit your answers after submission.
      </p>

      {renderErrors()}
    </div>
  )
}

export const steps: {[key in StepName]: Step} = {
  start: {
    title: 'Health Assessment',
    fields: {},
    field_render: ({formProps}) => {
      const womenHealthTests: TestType[] = ['blood-menopause', 'blood-polycystic']
      const isWomenHealthTest = womenHealthTests.includes(formProps.testType)
      return (
        <div>
          {isWomenHealthTest ? (
            <p className="lead">
              You must complete the following Health Assessment as part of the test activation process. The information
              you submit will be reviewed by our doctors to ensure your results are assessed in accordance with the
              information you submit. Your test results will not be processed until the Health Assessment is complete.
            </p>
          ) : (
            <p className="lead">
              You must complete the Health Assessment in order for you to receive your results and doctor's report. The
              information you submit will be reviewed by our doctors to ensure accurate assessments of your test
              results.
            </p>
          )}
          <p className="text-muted">This will take up to 10 minutes to complete.</p>
        </div>
      )
    },
  },

  // 2. Body
  body: {
    title: 'Height & Weight',
    fields: allFields.body,
    field_render: RenderBody,
  },

  // 3. Smoking
  smoking: {
    title: 'Smoking',
    fields: allFields.smoking,
    field_render: RenderSmoking,
  },

  // 4. Alcohol
  alcohol: {
    title: 'Alcohol',
    fields: allFields.alcohol,
    field_render: RenderAlcohol,
  },

  // 5. Activity
  activity: {
    title: 'Physical Activity',
    fields: allFields.activity,
    field_render: RenderActivity,
  },

  // 6. Medical Conditions
  medical_conditions: {
    title: 'Medical Conditions',
    fields: allFields.medicalConditions,
    field_render: RenderMedicalConditions,
  },

  // 7. Medications
  medications: {
    title: 'Medications',
    fields: allFields.medications,
    field_render: RenderMedications,
  },

  // 8. Menstrual
  menstrual: {
    title: 'Menstrual',
    fields: allFields.menstrual,
    field_render: RenderMenstrual,
  },

  // 9. Sexual Health
  sexual_health: {
    title: 'Sexual Health',
    fields: allFields.sexualHealth,
    field_render: RenderSexualHealth,
  },
  summary: {
    title: 'Assessment Summary',
    fields: {},
    field_render: RenderSummary,
  },
}

// Create object with list of steps and their data
export function getStepsData(stepsNames: StepName[]) {
  const requiredSteps: {[key in StepName]?: Step} = {
    start: steps.start,
  }

  stepsNames.forEach(step => {
    requiredSteps[step] = steps[step]
  })
  // Add summary in the end
  requiredSteps.summary = steps.summary

  return requiredSteps
}
