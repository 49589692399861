import React from 'react'
import {Container, Alert} from 'reactstrap'

import {LinkCard} from 'src/shared/LinkCard'
// import {CanActivateBloodTestsHelper} from 'src/utils'
import DNA from 'src/assets/icons/Platform/Activate_Wellness_DNA.svg'
import Blood from 'src/assets/icons/HealthAreas/LDL_Levels.svg'
import SH from 'src/assets/icons/Platform/Activate_SH.svg'
import Covid from 'src/assets/icons/Platform/Covid_tests.svg'

// Activate view - list of possible test types to activate
function ActivateView() {
  // let canActivateBloodTests = CanActivateBloodTestsHelper()
  const currentDate = new Date()
  const endOfLifeDay = new Date(2024, 0, 2)
  const url = 'https://myhealthchecked.com/service-updates'
  return (
    <div>
      <Container className="mb-5">
        <div className="pt-sm-4 pl-sm-2 pl-md-2 mt-4">
          <h1 className="text-left py-4">Activate my Test</h1>
          {
            (endOfLifeDay > currentDate) ?
                <Alert color="danger">
                  Before activating your test, please check our important information helping you test and return your sample
                  at the best time over the festive period.
                  <br />
                  <a target="_blank" href={url} className="btn btn-primary mt-2 mr-2 mb-2" rel="noopener noreferrer">
                    Read more
                  </a>
                </Alert> : <></>
          }
          <div className="text-left">
            {/*{canActivateBloodTests ? (*/}
            <LinkCard
              to="/tests/bloods/activate"
              image={Blood}
              title="Activate a Blood Test"
              button_text="Activate Kit"
            >
              <div className="text-muted">If you have received a Blood Test kit, you can activate it now.</div>
            </LinkCard>
            <LinkCard
              to="/tests/sexual-health/activate"
              image={SH}
              title="Activate a Sexual Health Test"
              button_text="Activate Kit"
            >
              <div className="text-muted">If you have received a Sexual Health Test kit, you can activate it now.</div>
            </LinkCard>
            {/*) : null}*/}
            <LinkCard to="/tests/wellness/activate" image={DNA} title="Activate a DNA Test" button_text="Activate Kit">
              <div className="text-muted">If you have received a DNA Test kit, you can activate it now.</div>
            </LinkCard>

            <LinkCard to="/tests/covid/" image={Covid} title="Activate Covid Test" button_text="Activate Kit">
              <div className="text-muted">Take action on your Covid tests.</div>
            </LinkCard>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default ActivateView
