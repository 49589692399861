import React, {useEffect} from 'react'
import {RouteComponentProps} from 'react-router-dom'

interface MatchParams {
  test_id: string
}

interface Props extends RouteComponentProps<MatchParams> {}

export default function ResumeActivateWellness({history, match}: Props) {
  const {test_id} = match.params

  useEffect(() => {
    window.app.requests.get(`/api/dashboard/activate/resume/${test_id}`).then((r: any) => {
      if (r.data.activation_stage === 'barcode') {
        history.push(`/tests/wellness/consent/${r.data.barcode}/`)
      } else if (r.data.activation_stage === 'consent') {
        history.push(`/tests/wellness/activate/${r.data.barcode}/`)
      } else if (r.data.activation_stage === 'personal-details') {
        history.push(`/tests/wellness/collect-samples/${r.data.barcode}/`)
      } else if (r.data.activation_stage === 'collect-sample') {
        history.push(`/tests/wellness/time-administered/${r.data.barcode}/`)
      } else if (r.data.activation_stage === 'test-taken') {
        history.push(`/assessment/wellness/form`)
      } else {
        history.push('/tests/wellness/activate')
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <>Loading...</>
}
