import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCheck, faTimes} from '@fortawesome/free-solid-svg-icons'

function Step({number, label, current, status, stepsCount, handleClick}) {
  function renderLabel() {
    if (status === 'invalid') {
      return <FontAwesomeIcon icon={faTimes} className="fa-fw" />
    }

    if (status === 'completed') {
      return <FontAwesomeIcon icon={faCheck} className="fa-fw" />
    }

    return number
  }
  return (
    <button
      className={`progressbar__item ${current ? 'progressbar__item--current' : ''} ${
        status ? `progressbar__item--${status}` : ''
      }`}
      style={{width: `${100 / stepsCount}%`}}
      onClick={() => handleClick(number)}
    >
      <div className="progressbar__item-point">{renderLabel()}</div>
      <div className="progressbar__item-label">{label.replace('_', ' ')}</div>
    </button>
  )
}

export function ProgressBar({steps, stepsStatus = {}, currentStep, handleClick = stepNumber => {}}) {
  return (
    <div className="progressbar">
      <span className="progressbar__line" />
      <span
        className="progressbar__line progressbar__line--completed"
        style={{maxWidth: `${(100 * (currentStep - 0.5)) / steps.length}%`}}
      />
      {steps.map((step, index) => (
        <Step
          key={index}
          number={index + 1}
          handleClick={handleClick}
          label={step}
          current={currentStep === index + 1}
          status={stepsStatus[index + 1]}
          stepsCount={steps.length}
        />
      ))}
    </div>
  )
}
