import React from 'react'
import {Loading, message_toast, StandaloneForm} from 'src/shared/reactstrap-toolbox'
import {Redirect} from 'react-router-dom'
import {buildFormFields} from 'src/form_fields'
import {faMinusCircle} from '@fortawesome/free-solid-svg-icons'

const field_defs = {
  'day-2-antigen': {
    first_name: true,
    last_name: true,
    dob: true,
    sex: true,
    ethnicity: true,
    passport_number: true,
    nhs_number: false,
    travel_method: true,
    travel_vehicle: false,
    travel_number: true,
    departure_ts: true,
    arrival_ts: true,
    departure_country: true,
    transit_country: false,
    vaccination_status: true,
    phone_number_primary: true,
    home_street_address: true,
    home_city: true,
    home_postal_code: true,
    home_country: true,
    uk_street_address: true,
    uk_city: true,
    uk_postal_code: true,
  },
  'fit-to-fly-antigen': {
    first_name: true,
    last_name: true,
    dob: true,
    sex: true,
    ethnicity: true,
    home_postal_code: true,
    passport_number: true,
    phone_number_primary: false,
    departure_ts: false,
    destination_country: false,
  },
}

export default ({match}) => {
  const {booking_reference} = match.params

  const [testInfo, setTestInfo] = React.useState(null)
  const [redirect, setRedirect] = React.useState(null)
  const [toastMessage, setToastMessage] = React.useState(null)

  React.useEffect(() => {
    window.app.setTitle('Antigen Test')
    window.app.requests
      .get('/api/dashboard/antigen/update/', {booking_reference}, {expected_status: [200, 310, 422]})
      .then(r => {
        if (r.status === 200) {
          setTestInfo(r.data)
        } else if (r.status === 310) {
          setRedirect(`/tests/antigen/${r.data.details.location}/instructions/`)
        } else {
          const {message, details} = r.data
          setToastMessage(message)
          setRedirect(details?.test_id ? `/tests/${details.test_id}/` : '/tests/')
        }
      })
  }, [booking_reference])

  React.useEffect(() => {
    if (toastMessage) {
      message_toast({
        icon: faMinusCircle,
        title: 'Antigen Test',
        message: toastMessage,
      })
    }
  }, [toastMessage])

  if (redirect) {
    return <Redirect to={redirect} />
  } else if (!testInfo) {
    return <Loading />
  }
  const {test_type} = testInfo

  const execute = async data => {
    const url = `/api/dashboard/antigen/update/${test_type}/`
    const submit_data = {...data, ...match.params}
    return await window.app.requests.post(url, submit_data, {expected_status: [200, 422]})
  }

  const afterSubmit = r => setRedirect(`/tests/antigen/${r.data.photo_token}/instructions/`)

  return (
    <>
      <div className="row justify-content-center pt-4">
        <div className="col-md-8">
          <h2>Antigen Rapid Test - Form</h2>
          <p className="text-muted">Please confirm the details below are correct.</p>
          <p className="text-muted">
            <b className="text-dark">Please Note:</b> you will not be able to edit these details after you've completed
            your test.
          </p>
          <StandaloneForm
            fields={buildFormFields(field_defs[test_type])}
            initial={{...testInfo}}
            submit_initial={true}
            execute={execute}
            allow_empty={true}
            afterSubmit={afterSubmit}
            save_label="Continue"
          />
        </div>
      </div>
    </>
  )
}
