import React from 'react'

export default ({color, leftLabel, centreLabel, rightLabel, percentage, steps = 4, children, text_margin = 10}) => {
  percentage = Math.max(0, Math.min(percentage, 100))

  if (!color) {
    color = 'button'
  }

  const text_percentage = Math.max(text_margin, Math.min(percentage, 100 - text_margin))
  return (
    <div className="scale">
      <div className="pl-3 pr-3">
        <div className={`bar inactive`} />
        <div className={`bar active bg-${color}`} style={{width: `${percentage}%`}} />
        <div className="lines">
          {[...Array(steps - 1)].map((_, i) => (
            <div key={i} />
          ))}
        </div>
        <div className={`circle bg-${color}-dark`} style={{left: `${percentage}%`}} />
      </div>
      <div className="labels-container">
        {leftLabel && (
          <>
            <span className="d-none d-md-flex scale-label left">{leftLabel}</span>
            <span className="d-md-none scale-label left">Lower / </span>
          </>
        )}
        {centreLabel && <span className="d-none d-md-flex scale-label centre">{centreLabel}</span>}
        {rightLabel && <span className="scale-label right">{rightLabel}</span>}
        <div className={`text`} style={{left: `${text_percentage}%`}}>
          {children}
        </div>
      </div>
    </div>
  )
}
