import React from 'react'
import {Redirect} from 'react-router-dom'
import {Button} from 'reactstrap'
import {confirm_modal, load_script, Loading, message_toast} from 'src/shared/reactstrap-toolbox'
import {faMinusCircle} from '@fortawesome/free-solid-svg-icons'

export default ({match, user}) => {
  const {token} = match.params
  const [allowContinue, setAllowContinue] = React.useState(false)
  const [redirect, setRedirect] = React.useState(null)
  const [loaded, setLoaded] = React.useState(null)
  const [videoLoaded, setVideoLoaded] = React.useState(false)
  const videoRef = React.useRef()
  const loggedIn = !!user

  React.useEffect(() => {
    window.app.requests.get(`/api/antigen/${token}/check/`).then(r => {
      if (r.data.status === 'in-progress') {
        setLoaded(true)
      } else {
        message_toast({
          icon: faMinusCircle,
          title: 'Antigen Test',
          message: 'Test Already Complete',
        })
        setRedirect(loggedIn ? `/tests/${r.data.test_id}/` : '/')
      }
    })
  }, [token, loggedIn])

  React.useEffect(() => {
    if (!videoLoaded) {
      return
    }
    let interval = 0
    const minWatchTime = 120
    const prepare = async () => {
      const player = window.Stream(videoRef.current)
      interval = setInterval(() => {
        if (player.currentTime > minWatchTime) {
          setAllowContinue(true)
        }
      }, 500)
      player.addEventListener('progress', () => {})
    }

    load_script('https://embed.videodelivery.net/embed/sdk.latest.js')
      .then(() => prepare())
      .catch(err => window.app.setError(err))
    return () => clearInterval(interval)
  }, [videoLoaded])

  if (redirect) {
    return <Redirect to={redirect} push={true} />
  } else if (!loaded) {
    return <Loading />
  }
  const onClick = async () => {
    const yes = await confirm_modal({message: <ConfirmMessage />, continue_text: 'Confirm & Continue'})
    if (yes) {
      setRedirect(`/tests/antigen/${token}/sms/`)
    }
  }

  return (
    <>
      <div className="row justify-content-center pt-4">
        <div className="col-md-8">
          <h2>Antigen Rapid Test &mdash; Instructions</h2>
          <p className="text-muted">Follow the instructions below to start you Antigen Rapid Test.</p>
          <iframe
            ref={videoRef}
            id="instructions-video"
            title="Instructions Video"
            className="video-iframe"
            src="https://iframe.videodelivery.net/7f5e83213ccf71e258ee68e97a5a6b2e?preload=true&autoplay=true"
            allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;"
            allowFullScreen={true}
            onLoad={() => setVideoLoaded(true)}
          />
          <ol>
            <li>Watch the video above and follow the instructions to perform your Rapid Test.</li>
            <li>
              You will be required to take a sample using the nasal swab, which is then inserted into the buffer tube
              and stirred. The swab is then removed from the tube, and the sample is applied to the test cassette.
            </li>
            <li>
              Once <b>4 drops</b> of liquid have been applied to the test cassette, please wait 15 minutes for the
              lateral flow cassette to yield a result.
            </li>
            <li>
              Once you've waited for the <b>required 15 minutes</b>, click "Continue" below, you will then be asked to
              take a photo of the cassette using the browser.
            </li>
          </ol>
          <div className="text-right">
            <div className="small text-muted pb-1">
              {allowContinue ? <span>&nbsp;</span> : "You cannot continue until you've watched more of the video"}
            </div>
            <Button disabled={!allowContinue} color="primary" onClick={onClick}>
              Continue
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

const ConfirmMessage = () => (
  <>
    Please confirm you have performed your test and waited at least <b>15 minutes</b> for the result to appear clearly
    on your Cassette?
  </>
)
