import React, {useEffect} from 'react'
import {useHistory, useParams, useLocation} from 'react-router-dom'
import {StandaloneForm, message_toast} from 'src/shared/reactstrap-toolbox'
import {faArrowCircleRight, faMinusCircle} from '@fortawesome/free-solid-svg-icons'
import {buildFormFields} from 'src/form_fields'
import {getTestTypeValueFromUrl} from '../../assessment_utils'

let success
let newData
let edit
let bId

const ConfirmTestType = () => {
  const {test_type: testType} = useParams()
  const location = useLocation()
  edit = location.search.includes('edit=true')
  edit && (bId = location.search?.split('?')[2].split('=')[1])
  const [form_data, setFormData] = React.useState({test_type: getTestTypeValueFromUrl(testType) || ''})
  const [error, setError] = React.useState('')
  const history = useHistory()

  // Pre-populate test type
  useEffect(() => {
    if (testType) {
      setFormData({test_type: getTestTypeValueFromUrl(testType)})
    }
  }, [testType])

  const field_defs = {
    test_type: {
      help_text:
        'Please note that you will be required to pay for the test you selected on the day of your appointment.',
    },
  }
  const fields = buildFormFields(field_defs)

  const execute = async data => {
    if (!edit) {
      // If not editing, use data if available, otherwise fall back to form_data
      const test_type_data = data.test_type ? data : form_data || getTestTypeValueFromUrl(testType)
      newData = {
        ...test_type_data, // Start with form_data
      }
    } else {
      // If editing, use data or form_data for test_type, and set edit to true
      newData = {
        bid: bId,
        test_type: data.test_type ? data?.test_type : form_data?.test_type || getTestTypeValueFromUrl(testType),
        edit: true,
      }
    }

    const response = await window.app.requests.post(
      `/api/dashboard/registration/test-type-confirm/?test_type=${newData?.test_type || form_data?.test_type}`,
      newData,
      {
        expected_status: [200, 422, 404, 405],
      },
    )

    if (response.status !== 200 && response.status !== 405) {
      setError(response.data.detail[0]?.msg)
      message_toast({
        icon: faMinusCircle,
        title: 'Test type confirmation',
        message:
          ` ${response.data.detail[0]?.loc[1]}: ${response.data.detail[0]?.msg}` || 'Error confirming Test type!',
        onClick: () => {},
        className: '',
        time: 3000,
      })
    } else if (response.status === 405) {
      setError(response.data.detail)
      message_toast({
        icon: faMinusCircle,
        title: 'Test type confirmation',
        message: `You already have an appointment under process` || 'Error confirming Test type!',
        onClick: () => {},
        className: '',
        time: 3000,
      })
      history.push('/tests')
    } else {
      success = response.data.bid || bId
    }
  }

  const afterSubmit = () => {
    if (success) {
      sessionStorage.setItem(
        'test_type',
        newData?.test_type || form_data?.test_type || getTestTypeValueFromUrl(testType),
      )
      message_toast({
        icon: faArrowCircleRight,
        title: 'Test type confirmation',
        message: 'Test type confirmed!' || 'Form contains errors',
        onClick: () => {},
        className: '',
      })
    }
    const redirectPath = !edit
      ? `/bloods/boots/consent/${
          newData?.test_type || form_data?.test_type || getTestTypeValueFromUrl(testType)
        }/${success}`
      : `/bloods/boots/assessment-single/${
          newData?.test_type || form_data?.test_type || getTestTypeValueFromUrl(testType)
        }/${success}`

    !error && history.push(redirectPath)
  }

  return (
    <div>
      <div className="row justify-content-center py-5">
        <div className="col-md-8">
          <p>
            Please confirm the test you selected on the Boots website. We will prepare it ahead of your arrival for
            your in-store appointment.
          </p>
          <StandaloneForm
            fields={{...fields}}
            form_data={form_data}
            error={error}
            allow_empty={true}
            initial={{...form_data}}
            onChange={setFormData}
            afterSubmit={afterSubmit}
            execute={execute}
            save_label="Confirm & Continue"
          />
          {/* <p>Please note that you will be required to pay for the test you selected on the day of your appointment.</p> */}
        </div>
      </div>
    </div>
  )
}

export default ConfirmTestType
