import React from 'react'
import {Badge, Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap'

import './BloodMarkerModal.scss'

interface Props {
  marker: Biomarker
  isOpen: boolean
  toggle: () => void
}

const MarkerDetailQuestions: {[key: string]: string} = {
  what_is: 'What is %markerName%?',
  what_can: 'What can my %markerName% tell me about my health?',
  meaning: 'What does it mean if my %markerName% is %result%?',
  symptoms: 'What symptoms could I have if my %markerName% is %result%?',
  actions: 'What can I do if my %markerName% is %result%?',
  repeat: 'When should I repeat my test?',
}

// If string contains %markerName%, replace with marker name.
// If string contains %result%, replace it with marker result
function replaceVariablesInTitle(template: string, markerName: string, result: string) {
  let title = template.replace('%markerName%', markerName).replace('%result%', result)

  return title
}

function matchAll(pattern: RegExp, text: string): RegExpMatchArray[] {
  const regex = new RegExp(pattern, 'g')
  const matches: RegExpMatchArray[] = []

  let matchResult
  while ((matchResult = regex.exec(text))) {
    matches.push(matchResult)
  }

  return matches
}

// Wrap reference numbers with link to the page with list of references
// References are passed in round brackets
function addReferenceLinks(text: string): string {
  // number in round brackets
  const pattern = /\(([0-9,]+)\)/g

  // Find all matches
  const items = matchAll(pattern, text)

  const matches = [...Array.from(items)]
  if (!matches?.length) return text

  // For each match
  matches.forEach((matchedGroup: any) => {
    if (!matchedGroup[1]) return // added check for undefined
    // Split reference group into array
    const references = matchedGroup[1].split(',')

    // Loop through references and replace numbers with links
    let referenceLinks = references
      .map(
        (ref: string) =>
          `<a href="https://myhealthchecked.com/references#ref-${ref}" target="_blank" rel="noopener noreferrer">${ref}</a>`,
      )
      .join(',')

    // Replace raw reference data (ex. (4,5)) with reference links
    text = text.replace(matchedGroup[0], `<sup>[${referenceLinks}]</sup>`)
  })

  return text
}

function prepareQuestionsAndAnswers(marker: Biomarker): QuestionAnswer[] {
  const {details} = marker
  const result: QuestionAnswer[] = []
  type T = keyof typeof details
  const keys = Object.keys(details)

  keys.forEach(key => {
    const answer = details[key as T]
    if (!answer) return
    let question = MarkerDetailQuestions[key]

    if (key === 'meaning' && marker.result === 'normal') {
      question = 'How can I maintain my normal result?'
    }

    // Sexual marker
    if (marker.value === '**DETECTED**') {
      if (key === 'what_can') {
        question = `What can I do if I've tested positive for ${marker.name}?`
      }

      if (key === 'meaning') {
        question = `What does it mean if I've tested positive for ${marker.name}?`
      }

      if (key === 'symptoms') {
        question = `What symptoms will I have if I'm positive for ${marker.name}?`
      }
    }

    // Sexual marker
    if (String(marker.value).toLowerCase() === 'unconfirmed reactive' && key === 'meaning') {
      question = `What does it mean if my result says unconfirmed reactive for ${marker.name}?`
    }

    // Sexual marker
    if (marker.type === 'negative-positive' && key === 'repeat') {
      question = `How can I maintain my normal results?`
    }

    let markerResult = String(marker.result)
    if (String(marker.type) === 'negative-positive') {
      if (marker.result === 'green') {
        markerResult = 'not detected'
      }

      if (marker.result === 'amber') {
        markerResult = 'detected'
      }
    }

    result.push({
      question: replaceVariablesInTitle(question, marker.name, markerResult),
      answer: addReferenceLinks(answer),
    })
  })

  return result
}

function generateBadge(marker: Biomarker) {
  let text = `Your result is ${marker.result}`
  let status = String(marker.result)

  if (String(marker.value).toLowerCase() === 'unconfirmed reactive') {
    text = 'Further testing required'
    status = 'unconfirmed'
  } else if (String(marker.result) === 'amber') {
    text = 'Medical attention required'
    status = 'amber'
  } else if (String(marker.result) === 'green') {
    text = 'Everything looks good'
    status = 'green'
  }

  return <Badge className={`markerModal__result markerModal__result--${status}`}>{text}</Badge>
}

export function BloodMarkerModal({marker, isOpen, toggle}: Props) {
  const questionAnswers = prepareQuestionsAndAnswers(marker)

  return (
    <Modal
      className={`markerModal__container ${
        ['normal', 'green', 'Not Detected'].includes(marker.result) && 'markerModal__container--normal'
      }
      ${
        ['unconfirmed reactive'].includes(String(marker.value).toLowerCase()) && 'markerModal__container--unconfirmed'
      }`}
      isOpen={isOpen}
      toggle={toggle}
      scrollable
      centered
    >
      <ModalHeader toggle={toggle}>
        <span className="markerModal__title">{marker.name}</span>
      </ModalHeader>
      <ModalBody>
        <h3 className="">{generateBadge(marker)}</h3>
        <dl className="markerModal__questions-list">
          {questionAnswers.map(({question, answer}) => (
            <React.Fragment key={question}>
              <dt className="markerModal__question">{question}</dt>
              <dd className="markerModal__answer" dangerouslySetInnerHTML={{__html: answer}}></dd>
            </React.Fragment>
          ))}
        </dl>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  )
}
