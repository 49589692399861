import React from 'react'
import {Redirect} from 'react-router-dom'
import {message_toast, Form} from 'src/shared/reactstrap-toolbox'
import {PasswordStrengthField} from 'src/shared/PasswordStrength'

const fields = {
  first_name: {required: true, title: 'First Name', min_length: 1, max_length: 30, value: 'test'},
  last_name: {required: true, title: 'Last Name', min_length: 1, max_length: 30},
  email: {required: true, type: 'email', max_length: 127, autocomplete: 'email'},
  password: {required: true, type: 'password_strength', max_length: 31, autocomplete: 'new-password'},
  marketing_consent: {
    type: 'bool',
    default: true,
    help_text:
      'We would love to share our latest product innovation, expert health info, and offers with you. We’ll never share your personal data and you can unsubscribe at any time.',
  },
  recaptcha_token: {required: true, type: 'recaptcha'},
  login_redirect: {type: 'hidden'},
}

export default ({user, location}) => {
  const params = new URLSearchParams(location.search)
  const login_redirect = params.get('next')
  const [form_data, setFormData] = React.useState({marketing_consent: false})
  const [finished, setFinished] = React.useState(false)

  React.useEffect(() => {
    window.app.setTitle('Create Account')
    // Make sure the default marketing_consent (or other form_data) isn't over written when the component loads
    setFormData(d => {
      d['login_redirect'] = login_redirect
      return d
    })
  }, [login_redirect])

  if (user) {
    message_toast({title: 'Logged in', message: "You're already logged, so cannot create a new account."})
    return <Redirect to={login_redirect || '/'} push={false} />
  }

  return (
    <div>
      <div className="row justify-content-center pt-4">
        <div className="col-md-8">
          <h1>Create Account</h1>
          {finished ? (
            <div className="lead">
              <p>Please check your email to confirm your new account and login.</p>
              <p>You can safely close this window.</p>
            </div>
          ) : (
            <>
              <p className="lead">Create a new MyHealthChecked account to activate kits and view results.</p>
              <Form
                fields={fields}
                afterSubmit={() => setFinished(true)}
                endpoint="/api/dashboard/create-account/"
                form_data={form_data}
                onChange={setFormData}
                save_label="Create Account"
                type_lookup={{password_strength: PasswordStrengthField}}
              />
            </>
          )}
        </div>
      </div>
    </div>
  )
}
