import React, {useEffect, useState} from 'react'
import ReactMarkdown from 'react-markdown'
import {Link, RouteComponentProps} from 'react-router-dom'
import {Alert, Button, Container} from 'reactstrap'
import {Error, Loading} from 'src/shared/reactstrap-toolbox'

// Blood Consent View
// Checks user consent (for current barcode) and consent content
// If user already gave consent, redirect to the activation

interface MatchParams {
  barcode: string
}

interface Props extends RouteComponentProps<MatchParams> {}

interface Consent {
  consent_given: boolean
  consent_text: string
  test_kit_id: number
}

export function BloodConsent({history, match}: Props) {
  const [error, setError] = useState<string | undefined>()
  const [consent, setConsent] = useState<Consent | undefined>()
  const [checkedConsent, setCheckedConsent] = useState(false)
  const [checkedTerms, setCheckedTerms] = useState(false)

  const {barcode} = match.params

  // Link to the next activation step (form)
  const full_activate_link = `/tests/bloods/activate/${barcode}`

  // Fetch consent data
  async function getConsentData(barcodeToCheck: string) {
    const url = `/api/dashboard/test-kits/consent/${barcodeToCheck}/`
    const response = await window.app.requests.get(url, null, {
      expected_status: [200, 404],
    })

    // If response is correct, set data
    if (response.status === 200) {
      setConsent(response.data)
    } else {
      // Otherwise set error
      setError(response.data?.message || 'Error retrieving consent data')
    }
  }

  // Get consent data on lload
  useEffect(() => {
    getConsentData(barcode)
  }, [barcode])

  useEffect(() => {
    // If consent has been given, redirect to next activation step
    if (consent?.consent_given === true) {
      history.push(full_activate_link)
    }
  }, [consent, full_activate_link, history])

  // Handle consent submit
  const submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setError(undefined)

    if (!checkedConsent || !checkedTerms) {
      alert('You must tick both boxes to continue')
      return false
    }

    const response = await window.app.requests.post(
      '/api/dashboard/test-kits/consent/',
      {
        test_kit_id: consent?.test_kit_id,
        consent_given: true,
      },
      {expected_status: [200, 422, 404]},
    )

    // If saving response fails, set error
    if (response.status !== 200) {
      setError(response.data.message || 'Saving consent failed. Try again.')
      return
    }

    // Otherwise redirect to next activation step
    history.push(full_activate_link)
  }

  // If there's some error and no consent was set, show error view
  if (error && !consent) {
    return (
      <Container className="my-5">
        <Error error={{message: error}} className="" />
        <p className="">If you need assistance, please contact support.</p>
        <Button tag={Link} to="/tests/bloods/activate" color="primary" className="mr-2">
          Previous step
        </Button>
        <Button
          tag="a"
          href="https://myhealthchecked.com/contact"
          target="_blank"
          rel="noopener noreferrers"
          color="primary"
          outline
        >
          Contact us
        </Button>
      </Container>
    )
  }

  // If there are no consent, show loading
  if (!consent) {
    return (
      <Container className="my-5">
        <Loading />
      </Container>
    )
  }

  return (
    <div className="row justify-content-center pt-4">
      <form className="mt-3 p-0 col-10" method="POST" onSubmit={e => submit(e)}>
        <h1>Declaration of Consent</h1>

        <div className="p-0">
          <ReactMarkdown children={consent.consent_text} />
        </div>
        <div className="m-3 p-2">
          <h3>Please confirm consent to the above conditions.</h3>

          <div className="m-2">
            <input
              id="consent_given"
              name="consent_given"
              type="checkbox"
              required
              checked={checkedConsent}
              onChange={e => setCheckedConsent(e.target.checked)}
            />{' '}
            <label htmlFor="consent_given">I have read the above information and give consent</label>
            <br />
          </div>

          <div className="m-2">
            <input
              id="terms_read"
              name="terms_read"
              type="checkbox"
              required
              checked={checkedTerms}
              onChange={e => setCheckedTerms(e.target.checked)}
            />{' '}
            <label htmlFor="terms_read">
              I have read the{' '}
              <a href="https://myhealthchecked.com/privacy-policy" target="_blank" rel="noopener noreferrer">
                Privacy Policy
              </a>{' '}
              and agree to the{' '}
              <a href="https://myhealthchecked.com/terms-and-conditions" target="_blank" rel="noopener noreferrer">
                Terms &amp; Conditions
              </a>
            </label>
            <br />
          </div>

          {error && <Alert color="danger">{error}</Alert>}

          <Button color="primary" type="submit">
            Submit
          </Button>
        </div>
      </form>
    </div>
  )
}
