// Title case string
export function toTitleCase(str) {
  return String(str)
    .toLowerCase()
    .split(' ')
    .map(function (word) {
      if (!word.length) return word

      return word.replace(word[0], word[0].toUpperCase())
    })
    .join(' ')
}
