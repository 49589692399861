import React from 'react'
import {Col, Row} from 'reactstrap'
import {as_title} from 'src/shared/reactstrap-toolbox'
import {format_value} from 'src/utils'

export default ({fields, data}) => (
  <Row>
    {Object.entries(fields).map(([key, field]) => (
      <DisplayItems key={key} field_name={key} field={field} data={data} />
    ))}
  </Row>
)

export const DisplayItems = ({field_name, field, data}) => {
  const value = data[field_name]
  return (
    <Col md={6}>
      <div className="border-top pt-2 pb-4">
        <h4 className="mb-1">{field.title || as_title(field_name)}</h4>
        <h5 className="font-weight-normal">{format_value(value, field.fmt, field.choices)}</h5>
        <small className="text-muted">{field.help_text}</small>
      </div>
    </Col>
  )
}
