import {DetailedError} from './utils'

interface UrlArgs {
  [key: string]: string | number | boolean | any[]
}

export function make_url(path: string): string {
  if (path.match(/^https?:\//)) {
    return path
  } else {
    if (!path.startsWith('/')) {
      throw Error('path must start with "/"')
    }

    if (process.env.REACT_APP_DOMAIN === 'localhost') {
      return `http://localhost:8000/${path}`
    } else if (process.env.REACT_APP_DOMAIN) {
      return `https://${process.env.REACT_APP_DOMAIN}${path}`
    } else {
      return path
    }
  }
}

export function build_url(url: string, args: UrlArgs): string {
  const arg_list: string[] = []
  const add_arg = (n: string, v: string | number | boolean) =>
    arg_list.push(encodeURIComponent(n) + '=' + encodeURIComponent(v))
  for (let [name, value] of Object.entries(args)) {
    if (Array.isArray(value)) {
      for (let value_ of value) {
        add_arg(name, value_)
      }
    } else if (value !== null && value !== undefined) {
      add_arg(name, value)
    }
  }
  if (arg_list.length > 0) {
    const split = url.includes('?') ? '&' : '?'
    return url + split + arg_list.join('&')
  }
  return url
}

export function headers2obj(r: Response): {[key: string]: any} {
  return Object.fromEntries(r.headers.entries())

  // const h = r.headers
  // const entries = Array.from(h.entries())
  // if (entries.length !== 0) {
  //   return Object.assign(...Array.from(h.entries()).map(([k, v]) => ({ [k]: v })))
  // }
  // return {}
}

type HttpMethod = 'DELETE' | 'GET' | 'OPTIONS' | 'PATCH' | 'POST' | 'PUT'

interface RequestConfig {
  args: UrlArgs
  make_url?: (path: string) => string
  expected_status: number[]
  headers?: Record<string, string>
  on_error?: (error: any) => any
  body?: {[key: string]: any}
  send_data?: {[key: string]: any}
  raw_response?: boolean
}

export async function request(method: HttpMethod, path: string, config: RequestConfig) {
  const make_url_ = config.make_url || make_url
  let url = make_url_(path)

  config = config || {}
  if (config.args) {
    url = build_url(url, config.args)
  }

  config.expected_status = config.expected_status || [200, 201]

  const headers: HeadersInit = new Headers(config.headers || {})
  headers.set('Accept', config.headers?.['Accept'] || 'application/json')
  if (method !== 'GET') {
    headers.set('Content-Type', config.headers?.['Content-Type'] || 'application/json')
  }

  const on_error = (e: any) => config.on_error && config.on_error(e)

  const init: RequestInit = {method, headers, credentials: 'include'}
  if (config.send_data) {
    init.body = JSON.stringify(config.send_data)
  }
  let r: Response
  try {
    r = await fetch(url, init)
  } catch (error: any) {
    // generally TypeError: failed to fetch
    const e = DetailedError(error?.message, {error, status: 0, url, init})
    on_error(e)
    throw e
  }

  const get_json = async () => {
    let body = null
    try {
      body = await r.text()
      return JSON.parse(body)
    } catch (error: any) {
      throw DetailedError(error?.message, {error, status: r.status, url, init, body, headers: headers2obj(r)})
    }
  }

  if (config.expected_status.includes(r.status)) {
    if (config.raw_response) {
      // e.g. you might want to use a stream and process one line of nd-json at a time or something
      return r
    } else {
      let data
      try {
        data = await get_json()
      } catch (error) {
        on_error(error)
        throw error
      }
      return {
        data,
        status: r.status,
        response: r,
      }
    }
  } else {
    let response_data: any = {}
    try {
      response_data = await get_json()
    } catch (e: any) {
      // ignore and use normal error
      response_data = e?.details?.body
    }
    let message = `Unexpected response ${r.status} from "${url}"`
    if (response_data?.message) {
      message += `, response message: ${response_data.message}`
    }
    const e = DetailedError(message, {status: r.status, url, init, response_data, headers: headers2obj(r)})
    on_error(e)
    throw e
  }
}

export class Requests {
  config: RequestConfig = {args: {}, expected_status: [200]}

  constructor(config: RequestConfig) {
    this.config = config
  }

  get = async (path: string, args: UrlArgs, config = {}) => {
    const c = Object.assign({}, this.config, config, {args})
    return await request('GET', path, c)
  }

  post = async (path: string, send_data: {[key: string]: any}, config = {}) => {
    const c = Object.assign({}, this.config, config, {send_data})
    return await request('POST', path, c)
  }

  put = async (path: string, send_data: {[key: string]: any}, config = {}) => {
    const c = Object.assign({}, this.config, config, {send_data})
    return await request('PUT', path, c)
  }

  patch = async (path: string, send_data: {[key: string]: any}, config = {}) => {
    const c = Object.assign({}, this.config, config, {send_data})
    return await request('PATCH', path, c)
  }

  delete = async (path: string, send_data: {[key: string]: any}, config = {}) => {
    const c = Object.assign({}, this.config, config, {send_data})
    return await request('DELETE', path, c)
  }
}
