import React from 'react'
import {Link, Redirect} from 'react-router-dom'
import {message_toast, Form} from 'src/shared/reactstrap-toolbox'
import {faSignInAlt} from '@fortawesome/free-solid-svg-icons'
import {Button} from 'reactstrap'
import {URL_TO_TEST_TYPE} from 'src/assessment_utils'
import {TEST_TYPES} from '../Assessment/steps'

let existing
let phlebotomy
let loginData
let filled_data

export default ({user, location}) => {
  const params = new URLSearchParams(location.search)
  const next_url = params.get('next')
  const [recaptcha, setRecaptcha] = React.useState(false)
  const [form_data, setFormData] = React.useState({})
  const [error, setError] = React.useState()

  const pathSegments = location.search.split('%2F')

  let matchedTestType = null

  pathSegments.forEach(segment => {
    // Check in URL_TO_TEST_TYPE
    if (URL_TO_TEST_TYPE[segment]) {
      matchedTestType = URL_TO_TEST_TYPE[segment]
    }

    // Check in TEST_TYPES
    if (TEST_TYPES[segment]) {
      matchedTestType = TEST_TYPES[segment]
    }
  })

  if (matchedTestType) {
    sessionStorage.setItem('test_type', matchedTestType)
  }

  React.useEffect(() => {
    window.app.setTitle('Login')
    window.app.requests.get('/api/dashboard/login/').then(r => setRecaptcha(r.data.recaptcha_required))
  }, [])

  const afterSubmit = () => {
    sessionStorage.setItem('isLoggedIn', true)
    sessionStorage.setItem('existing', existing)
    sessionStorage.setItem('phlebotomy', phlebotomy)
    sessionStorage.setItem('test_type', location.search.split('%2F')[3])

    if (!error && existing) {
      message_toast({
        icon: faSignInAlt,
        title: 'Logged in',
        message: !error && loginData ? 'Logged in successfully' : 'Enter password to login',
        progress: false,
        time: 2000,
      })

      window.app.reAuthenticate()
    }
  }

  const execute = async data => {
    setFormData(data)
    if (data.password) {
      filled_data = {email: form_data.email || data.email || filled_data.email, password: data.password}
    } else {
      filled_data = {...filled_data, email: form_data.email || data.email}
    }

    try {
      data.password ? (loginData = true) : (loginData = false)
      const req = await window.app.requests.post('/api/dashboard/phlebotomist-customer-login/', filled_data, {
        expected_status: [200, 422],
      })

      if (req.status !== 200) {
        setRecaptcha(true)
        setError(req.data.details[0].msg || 'Unexpected error occured')

        if (req.data.details[0].msg) {
          message_toast({
            icon: faSignInAlt,
            title: 'Login Failed',
            message: req.data.details[0].msg || 'An error occurred during login',
            progress: false,
            time: 5000,
          })
        }
      } else {
        existing = req.data.existing
        phlebotomy = req.data.phlebotomy
        if (!req.data.existing) {
          message_toast({
            icon: faSignInAlt,
            title: 'Account not found',
            message: 'Please click on "Create Account"',
            progress: false,
            time: 5000,
          })
        }
      }
    } catch (error) {
      message_toast({
        icon: faSignInAlt,
        title: 'Login Failed',
        message: 'An unexpected error occurred. Please try again.',
        progress: false,
        time: 5000,
      })
    }
  }

  if (user) {
    return <Redirect to={next_url || '/'} push={false} />
  }

  let fields = {
    email: {required: true, type: 'email', max_length: 127, autocomplete: 'username'},
  }
  if (existing) {
    fields = {
      email: {required: true, type: 'email', max_length: 127, autocomplete: 'username'},
      password: {required: true, type: 'password', max_length: 31, autocomplete: 'current-password'},
    }
  }

  if (recaptcha) {
    fields.recaptcha_token = {required: true, type: 'recaptcha'}
  }

  var next_url_to_show = next_url

  if (next_url_to_show === '/tests/bloods/activate') next_url_to_show = 'Activation'

  return (
    <div>
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="py-4">
            <h1>MyHealthChecked Dashboard</h1>
            <p className="lead text-muted">
              <Next next_url={next_url_to_show} />
            </p>
            <Form
              fields={fields}
              afterSubmit={afterSubmit}
              execute={execute}
              form_data={form_data}
              initial={{...filled_data}}
              onChange={setFormData}
              save_label="Log in"
              BeforeButtons={BeforeButtons}
            />
          </div>

          <hr />

          <div className="py-4">
            <h2>Create Account</h2>
            <p className="lead">Don't have an account yet? You can create one now.</p>
            <div className="text-right">
              <Button size="lg" tag={Link} to={`/create-account/${location.search}`} color="primary">
                Create Account
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const BeforeButtons = () => (
  <div>
    <Link className="small-link" to="/password-reset/">
      Reset Password
    </Link>
  </div>
)

const Next = ({next_url}) => {
  if (next_url === '/tests/pcr/activate/') {
    return (
      <>
        Please login to <span className="text-black">Activate your Kit</span>.
      </>
    )
  } else if (next_url) {
    return <>Please login to view</>
  } else {
    return null
  }
}
