import React, {useEffect} from 'react'
import {RouteComponentProps} from 'react-router-dom'

interface MatchParams {
  test_kit_id: string
  customer_id: string
}

interface Props extends RouteComponentProps<MatchParams> {}

export default function ResumeActivateBlood({history, match}: Props) {
  const {test_kit_id} = match.params

  useEffect(() => {
    window.app.requests.get(`/api/dashboard/test-kits/resume/${test_kit_id}`).then((r: any) => {
      if (r.data.activation_stage === 'barcode') {
        if (r.data.secondary_type !== null) {
          history.push(
            `/tests/private/confirm/${r.data.secondary_type}/${r.data.barcode}?secondary_barcode=${r.data.secondary_barcode}`,
          )
        } else {
          history.push(`/tests/private/consent/${r.data.barcode}/`)
        }
      } else if (r.data.activation_stage === 'confirm-barcode') {
        history.push(`/tests/private/consent/${r.data.barcode}/`)
      } else if (r.data.activation_stage === 'consent') {
        history.push(`/tests/bloods/activate/${r.data.barcode}/`)
      } else if (r.data.activation_stage === 'personal-details') {
        history.push(`/tests/private/collect-samples/${test_kit_id}/`)
      } else if (r.data.activation_stage === 'collect-sample') {
        history.push(`/tests/private/time-administered/${r.data.barcode}/`)
      } else if (r.data.activation_stage === 'test-taken') {
        history.push(`/assessment/${test_kit_id}/1`)
        //then /assessment/${test_kit_id}/2/ /assessment/${test_kit_id}/3/ etc
      } else {
        history.push('/tests/bloods/activate')
      }
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <>Loading...</>
}
