import React from 'react'
import {Link, Redirect} from 'react-router-dom'
import {message_toast, on_mobile, Form} from 'src/shared/reactstrap-toolbox'
import {faSignInAlt} from '@fortawesome/free-solid-svg-icons'
import {Button} from 'reactstrap'

export default ({user, location}) => {
  const params = new URLSearchParams(location.search)
  const next_url = params.get('next')
  const [recaptcha, setRecaptcha] = React.useState(false)
  const [form_data, setFormData] = React.useState({})

  React.useEffect(() => {
    window.app.setTitle('Login')
    window.app.requests.get('/api/dashboard/login/').then(r => setRecaptcha(r.data.recaptcha_required))
  }, [])

  const afterSubmit = () => {
    sessionStorage.setItem('isLoggedIn', true)
    if (!on_mobile) {
      message_toast({
        icon: faSignInAlt,
        title: 'Logged in',
        message: 'Logged in successfully',
        progress: false,
        time: 2000,
      })
    }
    window.app.reAuthenticate()
  }

  const execute = async data => {
    const r = await window.app.requests.post('/api/dashboard/login/', data, {expected_status: [200, 422]})
    if (r.status !== 200) {
      setRecaptcha(true)
      setFormData({email: form_data.email || null})
    }
    JSON.parse(r.data) === true && sessionStorage.setItem('phlebotomy', true)
    return r
  }

  if (user) {
    return <Redirect to={next_url || '/'} push={false} />
  }

  const fields = {
    email: {required: true, type: 'email', max_length: 127, autocomplete: 'username'},
    password: {required: true, type: 'password', max_length: 31, autocomplete: 'current-password'},
  }
  if (recaptcha) {
    fields.recaptcha_token = {required: true, type: 'recaptcha'}
  }

  var next_url_to_show = next_url

  if (next_url_to_show === '/tests/bloods/activate') next_url_to_show = 'Activation'

  return (
    <div>
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="py-4">
            <h1>MyHealthChecked Dashboard</h1>
            <p className="lead text-muted">
              <Next next_url={next_url_to_show} />
            </p>
            <Form
              fields={fields}
              afterSubmit={afterSubmit}
              execute={execute}
              form_data={form_data}
              onChange={setFormData}
              save_label="Log in"
              BeforeButtons={BeforeButtons}
            />
          </div>

          <hr />

          <div className="py-4">
            <h2>Create Account</h2>
            <p className="lead">Don't have an account yet? You can create one now.</p>
            <div className="text-right">
              <Button size="lg" tag={Link} to={`/create-account/${location.search}`} color="primary">
                Create Account
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const BeforeButtons = () => (
  <div>
    <Link className="small-link" to="/password-reset/">
      Reset Password
    </Link>
  </div>
)

const Next = ({next_url}) => {
  if (next_url === '/tests/pcr/activate/') {
    return (
      <>
        Please login to <span className="text-black">Activate your Kit</span>.
      </>
    )
  } else if (next_url) {
    return <>Please login to view</>
  } else {
    return null
  }
}
