import React from 'react'
import {StandaloneForm, Loading, message_toast, as_title} from 'src/shared/reactstrap-toolbox'
import {faArrowCircleRight, faMinusCircle} from '@fortawesome/free-solid-svg-icons'
import {buildFormFields} from 'src/form_fields'
import {Redirect} from 'react-router-dom'

const fit_to_fly_fields = {
  first_name: true,
  last_name: true,
  dob: true,
  sex: true,
  ethnicity: true,
  home_postal_code: true,
  passport_number: true,
  test_ts: true,
  postage_tracking: false,
}

const quarantine_fields = {
  first_name: true,
  last_name: true,
  dob: true,
  passport_number: true,
  test_ts: true,
  sex: true,
  ethnicity: true,
  symptoms: false,
  postage_tracking: false,
}

export default ({match, history}) => {
  const {booking_reference} = match.params

  const [test_info, setTestInfo] = React.useState(null)
  const [redirect, setRedirect] = React.useState(null)
  const [toastMessage, setToastMessage] = React.useState(null)

  React.useEffect(() => {
    window.app.setTitle('Activate Kit')
    window.app.requests.get('/api/dashboard/activate/', {booking_reference}, {expected_status: [200, 422]}).then(r => {
      if (r.status === 200) {
        setTestInfo(r.data)
      } else {
        const {message, details} = r.data
        setToastMessage(message)
        setRedirect(details?.test_id ? `/tests/${details.test_id}/` : '/tests/')
      }
    })
  }, [booking_reference])

  React.useEffect(() => {
    if (toastMessage) {
      message_toast({
        icon: faMinusCircle,
        title: 'Activate Kit',
        message: toastMessage,
      })
    }
  }, [toastMessage])

  if (redirect) {
    return <Redirect to={redirect} />
  } else if (test_info === null) {
    return <Loading />
  }

  const {first_name, last_name, test_type} = test_info
  const fit_to_fly = test_type === 'fit-to-fly-pcr'

  const execute = async data => {
    const submit_data = {...data, ...match.params}
    const url = `/api/dashboard/activate/${fit_to_fly ? 'fit-to-fly' : 'quarantine'}/`
    return await window.app.requests.post(url, submit_data, {expected_status: [200, 422]})
  }

  const afterSubmit = r => {
    if (r.response) {
      message_toast({
        icon: faArrowCircleRight,
        title: 'Kit Activated Successfully',
        message: 'Please check all details are correct',
      })
    }
    history.push(`/tests/${r.data.test_id}/`)
  }

  const fields_def = fit_to_fly ? {...fit_to_fly_fields} : {...quarantine_fields}
  if (!booking_reference) {
    fields_def.vendor = false
  }
  const fields = buildFormFields(fields_def)

  return (
    <div className="row justify-content-center pt-4">
      <div className="col-md-8">
        <h1>Activate Kit</h1>
        <p className="text-muted">
          Test Type: <span className="text-dark font-weight-bold">{as_title(test_type)}</span>
        </p>
        <p className="text-muted">
          Please fill in the form below to activate your kit, you'll be able to edit these details for a short time
          after you've activated your test.
        </p>
        <StandaloneForm
          fields={fields}
          initial={{first_name, last_name}}
          submit_initial={true}
          execute={execute}
          afterSubmit={afterSubmit}
          save_label="Continue"
        />
      </div>
    </div>
  )
}
