import React, {useEffect, useState} from 'react'
import {Container, Row, Col} from 'reactstrap'
import {Loading} from 'src/shared/reactstrap-toolbox'
import ReportsDetailCard from './ReportsDetailCard'
import ReportDownloadSection from './ReportDownloadSection'
import {format_date, track} from 'src/utils'
// hack until we correct the name in nell-content
const fix_exclusions_name = name => (name === 'Red Meat excluding Port' ? 'Red Meat excluding Pork' : name)

export default ({user, match, history, location}) => {
  const [report, setReport] = useState(null)
  const {id: report_id, health_area: health_area_tag} = match.params
  useEffect(() => {
    window.app.requests.get(`/api/dashboard/reports/${report_id}/`).then(r => {
      window.app.setTitle(r.data.name)
      setReport(r.data)
    })
  }, [report_id])
  const params = new URLSearchParams(location.search)
  const effect_filter = params.get('effect')
  const goal_filter = params.get('goal')
  const applyFilter = (filter, new_value) => {
    const old_value = params.get(filter)
    if (new_value === old_value) {
      params.delete(filter)
    } else {
      track(`report-filter-${filter}`, new_value)
      params.set(filter, new_value)
    }
    history.push(`?${params.toString()}`)
  }
  if (!report) {
    return <Loading />
  }
  let {advice, health_areas, exclusions, goals} = report
  //Disabling goals for now
  goals = null
  let filteredHealthAreas = health_areas

  for (let i = 0; i < filteredHealthAreas.length; i++) {
    i === 0 ? (filteredHealthAreas[i].expanded = true) : (filteredHealthAreas[i].expanded = false)
  }

  if (effect_filter) {
    filteredHealthAreas = filteredHealthAreas.filter(area => area.effect === effect_filter)
  }
  if (goal_filter) {
    filteredHealthAreas = filteredHealthAreas.filter(area => area.goals.includes(goal_filter))
  }

  return (
    <Container className="peach-area">
      <Row className=" pt-sm-4 pl-sm-2 pt-md-5 pl-md-2 mt-4 pt-3 pb-2 w-100">
        <Col className="mb-1 pl-1 w-100">
          <Row className="mt-3">
            <Col className="pl-4">
              <h1 className="text-left">{report.name}</h1>
            </Col>
            <Col xs="auto" className="ml-2">
              <ReportDownloadSection report_id={report_id} report_raw={report} />
            </Col>
          </Row>
          <div className="">
            <p className="m-1 small text-muted">
              <b>Date first generated: </b>
              {format_date(report.created)}
            </p>
            <p className="m-1 small text-muted mb-3">
              <b>Date last updated: </b>
              {format_date(report.timestamp)}
            </p>
          </div>
        </Col>
      </Row>
      <Row className="pl-sm-2 pl-md-2 pb-2 w-100">
        <Col xs={7} md={true}>
          <h2 className="text-left">
            <b>Health areas</b>
          </h2>
        </Col>
        {(goals && goals.length) || (exclusions && exclusions.length) ? (
          <Col xs="auto" className="float-right">
            {goals && goals.length > 0 && (
              <div className="d-flex flex-row align-items-baseline flex-wrap">
                <p className="m-0">Goals:</p>
                {goals.map(g => (
                  <span
                    key={g.tag}
                    className={`small-pill clickable ${goal_filter === g.tag ? ' filled' : ''}`}
                    onClick={() => applyFilter('goal', g.tag)}
                  >
                    {g.name}
                  </span>
                ))}
              </div>
            )}
            {exclusions && exclusions.length > 0 && (
              <div className="d-flex flex-row align-items-baseline flex-wrap">
                <p className="m-0">I don't eat/drink:</p>
                {exclusions.map(exclusion => (
                  <span key={exclusion.tag} className="small-pill">
                    {fix_exclusions_name(exclusion.name)}
                  </span>
                ))}
              </div>
            )}
          </Col>
        ) : null}
      </Row>
      {filteredHealthAreas.map(ha => (
        <ReportsDetailCard
          user={user}
          advisements={advice}
          key={ha.tag}
          colour={'turquoise'}
          health_area={ha}
          open_health_area={health_area_tag}
          report_id={report_id}
          snps={report.snps.filter(item => ha.snps.includes(item.snp))}
          expanded={ha.expanded}
        />
      ))}
      {/*<RawReportTable snps={report.snps} />*/}
    </Container>
  )
}
