import React from 'react'
import {Link} from 'react-router-dom'
import {Button, Col, Row} from 'reactstrap'
import {faEdit} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {getStepsData} from './steps'
import {toTitleCase} from 'src/shared/toTitleCase'

// Prettify value - make form value human-readable
function prettifyValue(rawValue) {
  let val = rawValue
  // Join array items into a string
  if (Array.isArray(val)) {
    val = val.join(', ')
  }

  // Replace dashes with spaces
  val = String(val || '').replace('_', ' ')
  val = toTitleCase(val)
  return val
}

// Assessment Summary - show table with all entered data
// If assessment can be edited, show edit buttons for each section.
export function AssessmentSummary({can_be_edited, assessment_values, list_of_steps, test_kit_details}) {
  const {test_kit_id} = test_kit_details
  const steps = getStepsData(list_of_steps)
  const values = assessment_values[0] || {}

  // Render single step
  function renderStep(stepName, index) {
    const {title, fields} = steps[stepName]

    // If step doesn't have any data, skip it
    if (!values.hasOwnProperty(stepName)) {
      return null
    }

    // If step doesn't have fields, skip it
    if (Object.keys(fields).length === 0) return null

    const subfields = fields[stepName].subfields

    return (
      <Col key={stepName} xs={12} md={6} xl={4}>
        <h3 className="mt-4">
          {title}
          {can_be_edited && (
            <Button
              tag={Link}
              to={`/assessment/${test_kit_id}/${index + 1}`}
              size="sm"
              className="ml-3"
              color="primary"
              outline
            >
              <div className="d-flex align-items-center">
                <FontAwesomeIcon icon={faEdit} className="mr-2" />
                <span className="mt-1">Edit</span>
              </div>
            </Button>
          )}
        </h3>

        <div>
          {Object.keys(subfields).map(f => {
            const prettyValue = prettifyValue(values[stepName][f])
            if (!prettyValue) return null

            return (
              <p key={f} className="mt-1">
                <small>{subfields[f].title}</small> <br />
                <strong className="">{prettyValue}</strong>
              </p>
            )
          })}
        </div>
      </Col>
    )
  }

  return <Row className="assessment__summary">{Object.keys(steps).map((step, index) => renderStep(step, index))}</Row>
}
