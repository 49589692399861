import {RouteComponentProps} from 'react-router-dom'
import {Button} from 'reactstrap'
import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCog} from '@fortawesome/free-solid-svg-icons'
import {StandaloneForm} from 'src/shared/reactstrap-toolbox'
import LavenderAsset from 'src/assets/images/lavender_barcode.png'
import BlueAsset from 'src/assets/images/blue_barcode.png'
interface MatchParams {
  barcode: string
  colour: string
}

interface Props extends RouteComponentProps<MatchParams> {}

export default function BloodsConfirmation({match, history, location}: Props) {
  const {barcode, colour} = match.params
  const secondaryBarcode = new URLSearchParams(location.search).get('secondary_barcode')

  const fields = {
    secondary_barcode: {
      extra: {
        value: secondaryBarcode || 'Secondary barcode not found',
        readOnly: true,
      },
    },
  }

  const handleSubmit = async (data: Object) => {
    history.push(`/tests/private/consent/${barcode}`)
  }

  return (
    <div className="row justify-content-center pt-4">
      <div className="mt-3 p-0 col-10">
        <h1>Check your second barcode</h1>
      </div>
      <div className="col-10 pt-4">
        <div className="row">
          <div className="col-12 p-0">
            <p className="text-muted">
              Please check and confirm your {colour === 'urine' ? 'BLUE' : colour.toUpperCase()} barcode is correct.
            </p>
            <div className="text-left py-2">
              <img
                loading="eager"
                alt={colour}
                src={colour === 'lavender' ? LavenderAsset : BlueAsset}
                className="img-fluid rounded-lg"
                width={227}
                height={85}
              />
            </div>
            <div className="activate-1-form">
              <StandaloneForm
                fields={fields}
                Buttons={FormButtons}
                save_label="Confirm & Continue"
                execute={handleSubmit}
                allow_empty={true}
                disabled={!secondaryBarcode}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

interface FormButtonsProps {
  state: any
  form_props: any
  submitButtonRef: any
}

// Form buttons
const FormButtons = ({state, form_props, submitButtonRef}: FormButtonsProps) => (
  <footer className="d-flex justify-content-between w-100 mt-4">
    <Button
      tag={'a'}
      href="https://myhealthchecked.com/help"
      target="_blank"
      rel="noopener noreferrer"
      className="mr-3 mb-1"
    >
      No it's not correct
    </Button>

    <Button
      type="submit"
      color="primary"
      disabled={state.disabled || form_props.disabled}
      className="btn-cog mb-1"
      innerRef={submitButtonRef}
    >
      {form_props.save_label || 'Save'}
      {!form_props.disabled && <FontAwesomeIcon icon={faCog} className="cog-loading fa-fw" />}
    </Button>
  </footer>
)
