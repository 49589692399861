import React from 'react'
import {Form, message_toast, Loading} from 'src/shared/reactstrap-toolbox'
import {Redirect} from 'react-router-dom'
import {faMinusCircle} from '@fortawesome/free-solid-svg-icons'
import tube_image from 'src/assets/images/test-tube-arrow.jpg'
import {clean_booking_reference, check_booking_reference} from 'src/utils'
import BankHolidayMessage from 'src/shared/BankHolidayMessage'

// Removed as we no longer sell these test types.
/*
  {value: 'day-2-pcr', label: 'Day 2'},
  {value: 'test-to-release-pcr', label: 'Test to Release'},
  {value: 'day-8-pcr', label: 'Day 8'},
 */

const fields = {
  barcode: {
    required: true,
    title: 'Swab Barcode (required)',
    max_length: 10,
    autocomplete: 'off',
    extra: {
      bsSize: 'lg',
    },
    help_text: '10 character code printed on the outside of the swab bottle.',
    inputClassName: 'text-uppercase',
  },
  test_type: {
    type: 'toggle',
    required: true,
    choices: [{value: 'fit-to-fly-pcr', label: 'Fit to Fly'}],
    help_text: "Select which type of test you're activating a kit for.",
  },
  booking_reference: {max_length: 18, autocomplete: 'off', extra: {bsSize: 'lg'}},
}

export default ({history, location}) => {
  const [form_data, setFormData] = React.useState({})
  const [loaded, setLoaded] = React.useState(false)
  const [redirectMessage, setRedirectMessage] = React.useState(null)
  const test_id = new URLSearchParams(location.search).get('test_id')

  React.useEffect(() => {
    window.app.setTitle('Activate Kit')
    if (test_id) {
      window.app.requests.get(`/api/dashboard/activate/${test_id}/`, null, {expected_status: [200, 422]}).then(r => {
        if (r.status === 200) {
          setFormData(r.data)
          setLoaded(true)
        } else {
          setRedirectMessage(r.data.message)
        }
      })
    } else {
      setLoaded(true)
    }
  }, [test_id])

  React.useEffect(() => {
    if (redirectMessage) {
      message_toast({
        icon: faMinusCircle,
        title: 'Activate Kit',
        message: redirectMessage,
      })
    }
  }, [redirectMessage])

  if (redirectMessage) {
    return <Redirect to={`/tests/${test_id}/`} />
  } else if (!loaded) {
    return <Loading />
  }

  const execute = data => {
    let {test_type, barcode, booking_reference} = data
    const errors = []
    if (!(barcode || '').match(/^[a-z]{2}\d{8}$/i)) {
      errors.push({loc: ['barcode'], msg: 'Invalid barcode format, should be 2 letters followed by 8 digits'})
    }
    if (booking_reference) {
      booking_reference = clean_booking_reference(booking_reference)
      const error_msg = check_booking_reference(booking_reference)
      if (error_msg) {
        errors.push({loc: ['booking_reference'], msg: error_msg})
      }
    } else {
      booking_reference = null
    }

    if (errors.length) {
      return {status: 400, data: {details: errors}}
    } else {
      return window.app.requests.get(
        '/api/dashboard/activate/check/',
        {test_type, barcode, booking_reference},
        {expected_status: [200, 422]},
      )
    }
  }

  const afterSubmit = () => {
    let {barcode, booking_reference} = form_data
    history.push(`/tests/pcr/activate/${barcode}/${clean_booking_reference(booking_reference) || ''}`)
  }

  if (form_data.test_type === 'fit-to-fly-pcr') {
    // booking reference is optional
    fields.booking_reference.title = 'Booking Reference (optional)'
    fields.booking_reference.required = false
    fields.booking_reference.help_text =
      'Barcode should be a 12 character code, if you bought your test direct from MyHealthChecked, ' +
      'you will have received this via email after purchase, otherwise leave this blank.'
  } else {
    fields.booking_reference.title = 'Booking Reference (required)'
    fields.booking_reference.required = true
    fields.booking_reference.help_text =
      'Barcode should be a 12 character code, you will have received via email after purchase.'
  }

  return (
    <div className="row justify-content-center pt-4">
      <div className="col-md-6">
        <h1>PCR Test - Enter swab barcode</h1>
        <p className="text-muted">
          Please enter the barcode number located on the label of the sample collection tube.
        </p>
        <div className="text-center py-2">
          <img
            loading="eager"
            src={tube_image}
            alt="Test Tube Number"
            className="img-fluid"
            width={700}
            height={276}
          />
        </div>
        <div className="activate-1-form">
          <Form
            fields={fields}
            form_data={form_data}
            onChange={setFormData}
            afterSubmit={afterSubmit}
            execute={execute}
            save_label="Continue"
          />
          <br />
          <BankHolidayMessage />
        </div>
      </div>
    </div>
  )
}
