import React from 'react'
import useCollapse from 'react-collapsed'
import {useHistory} from 'react-router-dom'
import {effect_data, getIntensity} from 'src/constants'
import {track} from 'src/utils'
import {Container, Row, Col} from 'reactstrap'
import ReportsDetailHeader from './ReportsDetailHeader'
import RecommendationSection from '../Sections/RecommendationSection'
import ReactMarkdown from 'react-markdown'
import further_research_required from 'src/assets/icons/further_research_required.svg'

const MissingHealthArea = ({health_area}) => {
  const {color} = effect_data[health_area.effect]
  const {name} = health_area
  return (
    <div className="rounded row p-3 bg-light report-detail-card missing">
      <div className="col-12">
        <h5 className={`text-${color}`}>{name}</h5>
        <p className="results-summary">
          Result missing{' '}
          <small>
            &mdash; The laboratory could not read this result from your sample, this could be because your cheek had
            residual food or drink or because there was not enough saliva on the cotton end. It is rare to have missing
            results.
          </small>
        </p>
      </div>
    </div>
  )
}
const RawTableMini = ({snps}) => {
  return (
    <>
      {snps && snps.length > 0 ? (
        <div>
          <table className="raw-report-table">
            <thead className="raw-report-header bg-dark">
              <tr>
                <th>Gene</th>
                <th>SNP</th>
                <th>Your Genotype</th>
              </tr>
            </thead>
            <tbody>
              {snps.map((item, key) => {
                const {gene, snp, base_pair} = item
                return (
                  <tr key={key}>
                    <td>
                      <span> {gene} </span>
                    </td>
                    <td>
                      <span>{snp}</span>
                    </td>
                    <td>
                      <span>{base_pair}</span>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      ) : null}
    </>
  )
}

const FurtherResearchRequired = ({show}) => {
  return (
    <>
      {show ? (
        <>
          <div className="pt-4 results-summary">
            <table>
              <tbody>
                <td>
                  <img src={further_research_required} alt="Further Research Required" />
                </td>
                <td className="pl-2">
                  This icon denotes further research is required in order to fully meet MyHealthChecked internal
                  standards on scientific evidence. Download your PDF report to read more.
                </td>
              </tbody>
            </table>
          </div>
        </>
      ) : null}
    </>
  )
}

const Risk = ({risklevel, risktype}) => {
  if (risklevel === 0) {
    return 'Low ' + risktype
  } else if (risklevel === 0.5) {
    return 'Medium ' + risktype
  }
  return 'High ' + risktype
}
const IncludedHealthArea = ({user, advisements, health_area, report_id, snps, expanded}) => {
  const {leftLabel, centreLabel, rightLabel} = effect_data[health_area.effect]
  const {name, lead, personalised_description, description, value, tag, effect, advice, further_research_required} =
    health_area
  const {first_name} = user
  const [isExpanded, setExpanded] = React.useState(expanded)
  const {getCollapseProps, getToggleProps} = useCollapse({isExpanded})
  const riskColour = getIntensity(value, effect)
  const history = useHistory()
  const onClick = () => {
    track('report-modal', tag)
    history.push(`/my-reports/${report_id}/${tag}/${history.location.search}`)
  }
  return (
    <div className="collapsible">
      <div className="rounded row report-detail-card clickable shadow raise" onClick={onClick}>
        <header
          {...getToggleProps({
            onClick: () => setExpanded(prevExpanded => !prevExpanded),
          })}
          className="border-0 bg-white col-12 p-2 p-md-4"
          style={{WebkitAppearance: 'none'}}
        >
          <ReportsDetailHeader
            name={name}
            tag={tag}
            effect={effect}
            value={value}
            leftLabel={leftLabel}
            centreLabel={centreLabel}
            rightLabel={rightLabel}
            isExpandable={true}
            isExpanded={isExpanded}
          />
        </header>
        <div {...getCollapseProps()} className="overflow-hidden">
          <section className="p-1 p-md-3">
            <Container className="mt-3">
              <Row className="d-md-none w-100">
                <Col>
                  <div>
                    <h2>Scale Meaning</h2>
                    <div className={`p-2 mb-4 rounded-sm risk-bg-${riskColour}`}>
                      <Risk risklevel={value} risktype={effect}></Risk>
                      <p className="results-summary">{lead}</p>
                    </div>
                  </div>
                  <div>
                    <h2 className="mb-3">{first_name}'s result</h2>
                    <p className="results-summary">
                      <ReactMarkdown children={personalised_description} />
                    </p>
                    <RecommendationSection firstName={first_name} advisements={advisements} advice={advice} />
                  </div>
                  <div>
                    <h2>Gene Table</h2>
                    <RawTableMini snps={snps} />
                    <FurtherResearchRequired show={further_research_required} />
                  </div>
                </Col>
              </Row>
              <Row className="d-none d-md-flex w-100">
                <Col md={7}>
                  <h2 className="mb-3">{first_name}'s result</h2>
                  <p className="results-summary">
                    <ReactMarkdown children={personalised_description} />
                  </p>
                  <RecommendationSection firstName={first_name} advisements={advisements} advice={advice} />
                </Col>
                <Col md={5}>
                  <Row>
                    <Col>
                      <h2>Scale Meaning</h2>
                      <div className={`p-2 mb-4 rounded-sm risk-bg-${riskColour}`}>
                        <Risk risklevel={value} risktype={effect}></Risk>
                        <p className="results-summary">{lead}</p>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <h2>Gene Table</h2>
                      <RawTableMini snps={snps} />
                      <FurtherResearchRequired show={further_research_required} />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </section>
          <footer className="results-extra p-3 p-md-4">
            <ReactMarkdown children={description} />
          </footer>
        </div>
      </div>
    </div>
  )
}
export default ({user, advisements, health_area, report_id, open_health_area, snps, expanded}) => {
  const scrollTo = element => {
    // if this health area is open in a modal and the card is not on screen, scroll to view the card
    if (element && open_health_area === health_area.tag) {
      const rect = element.getBoundingClientRect()
      if (rect.top < 0 || rect.top > window.innerHeight) {
        element.scrollIntoView()
      }
    }
  }
  const HealthArea = health_area.missing ? MissingHealthArea : IncludedHealthArea
  return (
    <div ref={scrollTo} className="pt-3 pb-1">
      <HealthArea
        user={user}
        advisements={advisements}
        health_area={health_area}
        report_id={report_id}
        snps={snps}
        expanded={expanded}
      />
    </div>
  )
}
