import {ComponentClass, FC} from 'react'
import {TEST_TYPES} from 'src/views/Assessment/steps'

// usage [1, 2, 3, 2, 4].filter(filter_unique) > [1, 2, 3, 4]
export const filter_unique = (value: any, index: number, array: any[]): boolean => array.indexOf(value) === index

// usage await sleep(1000)
export const sleep = (ms: number): Promise<(...args: any) => any> => new Promise(resolve => setTimeout(resolve, ms))

// usage as_title('what_ever') > 'What Ever'
export const as_title = (s: string): string =>
  (s || '')
    .replace(/(_|-)/g, ' ')
    .replace(/(_|\b)\w/g, l => l.toUpperCase())
    .replace(/\bdna\b/gi, 'DNA')

export const get_component_name = (Comp: FC | ComponentClass) => Comp.displayName || Comp.name || 'Component'

export const on_mobile = /mobile|ip(hone|od|ad)|android|blackberry|opera mini/i.test(navigator.userAgent)

class _DetailedError extends Error {
  name = 'DetailedError'
  details?: any
  constructor(message: string, details?: any) {
    super()
    this.message = message
    this.details = details
  }
}

export const DetailedError = (message: string, details?: any) => new _DetailedError(message, details)

// usage await load_script('https://www.example.com/script.js')
export function load_script(url: string) {
  return new Promise<void>((resolve, reject) => {
    if (document.querySelector(`script[src="${url}"]`)) {
      // script already loaded
      resolve()
    } else {
      const script = document.createElement('script')
      script.src = url
      script.onerror = e => reject(e)
      script.onload = () => resolve()
      document.body.appendChild(script)
      setTimeout(() => reject(`script "${url}" timed out`), 8000)
    }
  })
}

export const combine_classes = (...classes: string[]) => classes.filter(c => c).join(' ')

const testTypeNames = [
  {value: TEST_TYPES['blood-cholesterol'], label: 'Cholesterol Profile'},
  {value: TEST_TYPES['blood-energy'], label: 'Energy Profile'},
  {value: 'blood-erectile', label: 'Erectile Dysfunction'},
  {value: TEST_TYPES['blood-general'], label: 'General Health'},
  {value: TEST_TYPES['blood-heart'], label: 'Heart Profile'},
  {value: TEST_TYPES['blood-menopause'], label: 'Menopause Profile'},
  {value: TEST_TYPES['blood-polycystic'], label: 'Polycystic Ovary Syndrome (PCOS)'},
  {value: 'blood-thyroid', label: 'Thyroid Profile'},
  {value: TEST_TYPES['blood-vitamins'], label: 'Vitamins & Minerals Profile'},
  {value: TEST_TYPES['blood-weight'], label: 'Weight Management'},
]
export const getTestName = (type: any) => {
  const name = testTypeNames.find(testTypeName => testTypeName.value === type)?.label || null
  return name || ''
}
