import React from 'react'
import {Loading} from 'src/shared/reactstrap-toolbox'
import ReactMarkdown from 'react-markdown'
import {Button} from 'reactstrap'
import {Link} from 'react-router-dom'
import Timestamp from 'react-timestamp'

export default ({history, location, page_title, activate_link, barcode}) => {
  const [formData, setFormData] = React.useState(null)
  const [checkedConsent, setCheckedConsent] = React.useState(false)
  const [checkedTerms, setCheckedTerms] = React.useState(false)

  const test_id = new URLSearchParams(location.search).get('test_id')

  var full_activate_link = ''

  if (test_id === null || test_id === undefined) {
    full_activate_link = activate_link
  } else {
    full_activate_link = `${activate_link}?test_id=${test_id}`
  }

  React.useEffect(() => {
    window.app.requests.get('/api/dashboard/consent/form/', null, {expected_status: [200]}).then(req => {
      setFormData(req.data)
    })
  }, [])

  if (!formData) {
    return <Loading />
  }

  let {version, created, content} = formData

  const submit = e => {
    if (checkedConsent && checkedTerms) {
      return window.app.requests.post(
        '/api/dashboard/consent/form/',
        {
          version: version,
          consent_given: true,
          barcode: barcode.toUpperCase(),
        },
        {expected_status: [200, 422]},
      )
    } else {
      e.preventDefault()
      alert('You must tick both boxes to continue')
      return false
    }
  }

  return (
    <>
      <div className="pt-sm-4 pl-sm-1 pt-md-2 ml-md-3 pb-5">
        <div className="mb-4 mt-3 pt-3">
          <h1 className="mt-3 ml-3 text-left text-md-left">{page_title}</h1>
        </div>

        <div className="m-3 p-2">
          <ReactMarkdown children={content} />
          <p className="float-right pr-2">Consent document version {version}</p>
          <p className="float-right pr-2">
            Last Updated: <Timestamp className="timestamp" date={created} />
          </p>
        </div>
        <div className="m-3 p-2">
          <h3>Please confirm consent to the above conditions.</h3>

          <div className="m-2">
            <input
              id="consent_given"
              type="checkbox"
              required
              checked={checkedConsent}
              onChange={e => setCheckedConsent(e.target.checked)}
            />{' '}
            <label htmlFor="consent_given">I have read the above information and give consent</label>
            <br />
          </div>

          <div className="m-2">
            <input
              id="terms_read"
              type="checkbox"
              required
              checked={checkedTerms}
              onChange={e => setCheckedTerms(e.target.checked)}
            />{' '}
            <label htmlFor="terms_read">
              I have read the{' '}
              <a href="https://myhealthchecked.com/privacy-policy" target="_blank" rel="noopener noreferrer">
                Privacy Policy
              </a>{' '}
              and agree to the{' '}
              <a href="https://myhealthchecked.com/terms-and-conditions" target="_blank" rel="noopener noreferrer">
                Terms &amp; Conditions
              </a>
            </label>
            <br />
          </div>
          <Button tag={Link} to={full_activate_link} onClick={e => submit(e)} color="primary">
            Submit
          </Button>
        </div>
      </div>
    </>
  )
}
