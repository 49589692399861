import React from 'react'
import {Button} from 'reactstrap'
import {BankHolidayHelper} from 'src/utils'
import {useLocation} from 'react-router-dom'

const endpoint = process.env.REACT_APP_API_ENDPOINT

const Banner = () => (
  <div className="bank-holiday-flag">
    <a
      className="flag-a"
      href="https://myhealthchecked.com/myhealthchecked-service-updates"
      target="_blank"
      rel="noopener noreferrer"
    >
      Important message regarding expiry dates of PCR kits
    </a>
    <Button
      className="read-more-btn"
      href="https://myhealthchecked.com/myhealthchecked-service-updates"
      target="_blank"
      rel="noopener noreferrer"
    >
      Read More
    </Button>
  </div>
)

export default () => {
  const location = useLocation()
  const isPcrUrl = location.pathname.includes('tests/pcr/activate/')
  const isBankHoliday = BankHolidayHelper()

  if (process.env.NODE_ENV === 'development') {
    return (
      <div className="flag">
        dev mode, endpoint: <a href={endpoint}>{endpoint}</a>
      </div>
    )
  } else if (process.env.REACT_APP_STAGING && isBankHoliday && isPcrUrl) {
    return <Banner />
  } else if (isBankHoliday && isPcrUrl) {
    return <Banner />
  } else {
    return null
  }
}
