import React from 'react'
import {Col, Row} from 'reactstrap'
import {faSignInAlt} from '@fortawesome/free-solid-svg-icons'
import {message_toast} from 'src/shared/reactstrap-toolbox'
import TestGuard from 'src/Page/TestGuard'
import * as Sentry from '@sentry/react'
import Flag from './Flag'
import Menu from './Menu'
import Router from './Router'
import CookieNotice from 'src/shared/CookieNotice'

export default ({error, authenticate_sentinel}) => {
  const [latestTest, setLatestTest] = React.useState(null)
  const [bodyData, setBodyData] = React.useState(null) //to what extent does this replace latestTest?
  const [progressStep, setProgressStep] = React.useState(null)
  const [user, setUser] = React.useState(null)

  React.useEffect(() => {
    // this is called on page load and whenever authenticate_sentinel is incremented by
    // window.app.reAuthenticate it either authenticate the user or "de-authenticates" them
    // redirects based on this are performed in NarrowWide
    setLatestTest(null)
    setUser(null)

    window.app.requests.get('/api/dashboard/').then(r => {
      if (r.data.user) {
        //setLatestTest(r.data.latest_test)
        setBodyData(r.data.body_data)
        setProgressStep(r.data.progress_step)
        setUser(r.data.user)
        Sentry.setUser(r.data.user)

        window.app.requests.get('/api/dashboard/summary/').then(req => {
          if (req.data && req.data.latest_test) {
            setLatestTest(req.data.latest_test)
          }
        })
      } else {
        setUser(false)
        Sentry.configureScope(s => s.setUser(null))
        if (r.data.auth_error === 'session-expired') {
          message_toast({icon: faSignInAlt, title: 'Log in', message: 'Session expired, please login'})
        }
      }
    })
  }, [authenticate_sentinel])

  return (
    <>
      <Flag />
      <main>
        <Row className="main-row m-0">
          <Col className="sidebar-container sidebar-background col-xxs-5 col-lg-2 p-0">
            <Menu user={user} />
          </Col>
          <Col md={12} lg={10} className="p-0">
            <div className="main-bg main-area">
              <TestGuard>
                <Router
                  progressStep={progressStep}
                  bodyData={bodyData}
                  latestTest={latestTest}
                  error={error}
                  user={user}
                />
              </TestGuard>
            </div>
          </Col>
        </Row>
        <CookieNotice />
      </main>
    </>
  )
}
