import React from 'react'
import royalImage from 'src/assets/images/royal-mail-tracking-number-label.png'
import WellnessBarcodeForm from './WellnessBarcodeForm'

const fields = {
  barcode: {
    required: true,
    title: 'DNA Barcode (required)',
    max_length: 10,
    autocomplete: 'off',
    extra: {
      bsSize: 'lg',
    },
    help_text: '10 character code printed on the small sticker.',
    inputClassName: 'text-uppercase',
    placeholder: 'CONCA12345',
  },
  postage_tracking: {
    required: true,
    title: 'Royal mail tracking number (required)',
    max_length: 16,
    autocomplete: 'off',
    custom_type: 'text',
    type: 'mask',
    help_text: 'This number can be found on the Royal Mail Return Label included in your kit.',
    extra: {
      // Sample code: AA 1234 5678 9BB
      mask: [
        /[a-zA-Z]/,
        /[a-zA-Z]/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /[a-zA-Z]/,
        /[a-zA-Z]/,
      ],
      // Format entered values
      pipe: value => {
        let sections = value.split(' ')
        // First group should be uppercase
        sections[0] = sections[0].toUpperCase()
        // make uppercase when mix of numbers and characters (last group)
        sections[3] = sections[3].match(/[a-zA-Z]/) ? sections[3].toUpperCase() : sections[3]
        return sections.join(' ')
      },
      pattern: `[a-zA-Z]{2} [0-9]{4} [0-9]{4} [0-9]{1}[a-zA-Z]{2}`,
      title: 'Code should be in format AA 1234 5678 9BB',
      placeholder: 'AA 1234 5678 9BB',
    },
    image: {
      src: royalImage,
      width: 150,
      className: 'mt-2',
    },
  },
}

export default ({history, location}) => {
  const page_title = 'Enter your barcode & tracking number'
  const activate_check = '/api/dashboard/activate/dnacheck/'
  const activate_link = '/api/dashboard/activate/activating/'

  const barcode_regex_warning = 'Invalid barcode format, should be 5 letters followed by 5 digits'
  const barcode_regex = /^[a-z]{5}\d{5}$/i

  return (
    <WellnessBarcodeForm
      history={history}
      location={location}
      fields={fields}
      activate_check={activate_check}
      activate_link={activate_link}
      page_title={page_title}
      barcode_regex_warning={barcode_regex_warning}
      barcode_regex={barcode_regex}
      show_warn
    />
  )
}
