import React, {useEffect, useState} from 'react'
import {InlineWidget} from 'react-calendly'
import {DetailedError} from './shared/reactstrap-toolbox'
import {Alert} from 'reactstrap'

var eventStatus
var attempt
var time
var error

const Calendly = props => {
  const {id, event_status, updatePostData, meetingDetails} = {...props}

  const [schedulingLink, setSchedulingLink] = useState('')
  const [calendlyData, setCalendlyData] = useState({})
  const [user, setUser] = useState({})

  // Get link for Calendly InlineWidget
  const getEventLink = async () => {
    try {
      await window.app.requests.get(`/api/dashboard/calendly/`, null, {expected_status: [200, 404, 500]}).then(r => {
        setSchedulingLink(r.data.scheduling_url)
        if (r.status === 500) {
          error = `Oops!
          It seems that Calendly is experiencing some technical difficulties. Please try again in a few minutes to book your consultation slot. We apologise for any inconvenience caused. Thank you for your patience!`
        }
      })
    } catch (error) {
      DetailedError(error)
    }
  }

  // Reload Calendly calendar whenever new event has to be scheduled
  useEffect(() => {
    if (schedulingLink === '') {
      getEventLink()
    }
  }, [schedulingLink])

  // Set appropriate status and attempt
  useEffect(() => {
    switch (event_status) {
      case '':
        eventStatus = 'scheduled'
        attempt = 'phone_1'
        break
      case 'scheduled':
        eventStatus = 'reschedule_1'
        attempt = 'phone_2'
        break
      case 'reschedule_1':
        eventStatus = 'reschedule_2'
        attempt = 'phone_3'
        break
      default:
        break
    }
  }, [event_status])

  // Listen on Calendly events to check when event gets scheduled
  useEffect(() => {
    try {
      window.app.requests.get(`/api/dashboard/calendly/user_details/${id}`).then(r => {
        setUser(r.data)
      })
    } catch (error) {
      DetailedError(error)
    }
    try {
      function handleMessageEvent(e) {
        if (isCalendlyEvent(e)) {
          e.data.payload &&
            setCalendlyData({
              event_uri: e.data.payload.event.uri,
              invitee_uri: e.data.payload.invitee.uri,
              test_kit_id: JSON.parse(id),
              status: eventStatus,
              description: attempt,
            })
        }
      }
      window.addEventListener('message', handleMessageEvent)
      return () => {
        window.removeEventListener('message', handleMessageEvent)
      }
    } catch (error) {
      DetailedError(error)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventStatus])

  function isCalendlyEvent(e) {
    return e.data.event && e.data.event.indexOf('calendly') === 0
  }

  // Save Calendly appointment details
  useEffect(() => {
    if (calendlyData.event_uri) {
      window.app.requests
        .post('/api/dashboard/calendly/calendly-urls/', calendlyData, {
          expected_status: [200, 404],
        })
        .then(r => {
          time = meetingDetails(r.data.start_time)
          updatePostData(r.data.note, time, r.data.description, r.data.status)
        }, setSchedulingLink(''))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendlyData])

  return (
    <div>
      {!error && schedulingLink ? (
        <InlineWidget
          url={schedulingLink}
          prefill={{
            name: `${user.first_name} ${user.last_name}`,
            email: user.email,
            customAnswers: {
              a1: `${user?.phone_number_primary?.includes('+44') ? '' : '+44'}${user.phone_number_primary}`,
            },
          }}
        />
      ) : (
        error && <Alert color="danger">{error}</Alert>
      )}
    </div>
  )
}

export default Calendly
