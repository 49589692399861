import React from 'react'
import {Container, Row} from 'reactstrap'
import {Link} from 'react-router-dom'
import ReportsDetailHeader from './ReportsDetailHeader'
import {effect_data} from 'src/constants'

const ShortDate = date => {
  const options = {month: 'long', year: 'numeric'}
  return <div className="float-right short-timestamp">{new Intl.DateTimeFormat('en-GB', options).format(date.ts)}</div>
}

export default ({latestReport}) => {
  const reports = latestReport ? latestReport.health_areas.slice(0, 3) : null
  const report_name = latestReport.name
  const report_link = '/my-reports/' + latestReport.id
  return (
    <div className="report-highlights">
      {reports ? (
        <div className="small-rounded d-flex flex-nowrap no-gutters mb-4 padded-shadow-card shadow">
          <Container>
            <div className="border-bottom" />
            {reports.map((r, i) => (
              <Row>
                <div key={i} className="w-100 mt-2 mb-2">
                  <ReportsDetailHeader
                    name={r.name}
                    tag={r.tag}
                    effect={r.effect}
                    value={r.value}
                    leftLabel={effect_data[r.effect].leftLabel}
                    centreLabel={effect_data[r.effect].centreLabel}
                    rightLabel={effect_data[r.effect].rightLabel}
                    isExpandable={false}
                    isExpanded={r.isExpanded}
                  />
                </div>
              </Row>
            ))}

            <div className="border-bottom" />
            <Link to={report_link} className="float-left small-link">
              View full {report_name} report
            </Link>
            <ShortDate date={reports.date} />
          </Container>
        </div>
      ) : (
        <p>No latest report found!</p>
      )}
    </div>
  )
}
