import React from 'react'
import {BankHolidayHelper} from 'src/utils'

const BankHolidayMessage = () => {
  let isBankHoliday = BankHolidayHelper()
  if (isBankHoliday) {
    console.log(`holiday: ${isBankHoliday}`)

    return (
      <a
        className="btn-link text-decoration-none"
        href="https://myhealthchecked.com/myhealthchecked-service-updates"
        target="blank"
        rel="noopener noreferrer"
      >
        <div className="bankHolidayMessage mb-4 padded-shadow-card clickable shadow raise">
          <p className="mr-auto">Important message regarding expiry dates of PCR kits</p>
          <button className="btn-cog ml-3 btn btn-primary display-4">More</button>
        </div>
      </a>
    )
  } else {
    return null
  }
}

export default BankHolidayMessage
