import React from 'react'
import {Redirect} from 'react-router-dom'
import {ButtonGroup, Button} from 'reactstrap'
import {faCog, faLightbulb, faMinusCircle} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {confirm_modal, DetailedError, Loading, message_toast, sleep} from 'src/shared/reactstrap-toolbox'
/* eslint-disable import/no-webpack-loader-syntax */
import Worker from 'worker-loader!src/shared/qr-worker'

const videoSize = 800
const constraints = {
  audio: false,
  video: {width: videoSize, height: videoSize, frameRate: 20, zoom: {ideal: 1.5}},
}

if (navigator.mediaDevices === undefined) {
  navigator.mediaDevices = {}
}

// Some browsers partially implement mediaDevices. We can't just assign an object
// with getUserMedia as it would overwrite existing properties.
// Here, we will just add the getUserMedia property if it's missing.
if (navigator.mediaDevices.getUserMedia === undefined) {
  navigator.mediaDevices.getUserMedia = function (constraints) {
    const getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia

    // Some browsers just don't implement it - return a rejected promise with an error
    // to keep a consistent interface
    if (!getUserMedia) {
      return Promise.reject(new Error('getUserMedia is not implemented in this browser'))
    }

    // Otherwise, wrap the call to the old navigator.getUserMedia with a Promise
    return new Promise(function (resolve, reject) {
      getUserMedia.call(navigator, constraints, resolve, reject)
    })
  }
}

export default ({match, children}) => {
  const videoRef = React.useRef()
  const canvasRef = React.useRef()
  const clearTemporaryError = React.useRef(0)
  const [processing, setProcessing] = React.useState(false)
  const [scanning, setScanning] = React.useState(false)
  const [result, setResult] = React.useState(null)
  const [pageError, setPageError_] = React.useState(null)
  const [redirect, setRedirect] = React.useState(null)
  const [loaded, setLoaded] = React.useState(false)
  const [showTorch, setShowTorch] = React.useState(false)
  const [torchOn, setTorchOn] = React.useState(false)
  const {token} = match.params

  const setPageError = React.useCallback(err => {
    clearInterval(clearTemporaryError.current)
    setPageError_(err)
  }, [])

  const setTempError = React.useCallback(
    temp_error => {
      setPageError(temp_error)
      clearTemporaryError.current = setTimeout(() => setPageError_(null), 1000)
    },
    [setPageError],
  )

  const videoError = React.useCallback(
    err => {
      console.warn('getUserMedia error:', err)
      setPageError(
        'Unable to access your Camera, you must give Camera permission for this page to work. ' +
          'If you continue to experience problems, please try another smartphone or computer.',
      )
    },
    [setPageError],
  )

  React.useEffect(() => {
    window.app.setTitle('Result Photo | Antigen Test')
    window.app.requests.get(`/api/antigen/${token}/check/`).then(r => {
      if (r.data.status !== 'in-progress') {
        message_toast({
          icon: faMinusCircle,
          title: 'Antigen Test',
          message: 'Test Already Complete',
        })
        // setRedirect(loggedIn ? `/tests/${r.data.test_id}/` : '/')
        setRedirect(`/tests/${r.data.test_id}/`)
        return
      }
      setLoaded(true)
      findCamera()
        .then(stream => {
          window.scanner = new QrScanner(token, videoRef.current, canvasRef.current, setResult, setTempError, r.data)
          window.scanner
            .start(stream)
            .then(() => {
              setShowTorch(window.scanner.torchAvailable)
              setScanning(true)
              window.addEventListener('beforeunload', onClose)
            })
            .catch(videoError)
        })
        .catch(videoError)
    })
    return () => window.scanner?.stop()
  }, [token, setTempError, videoError])

  const ConfirmMessage = () => (
    <>
      <p>
        Are you sure the result area is <b>clearly visible</b> in the photo?
      </p>
      <p>
        If the medical professional reviewing your antigen test cassette image is unable to interpret a clear result,
        your test will be marked as invalid.
      </p>
    </>
  )

  const onClickUpload = async () => {
    const yes = await confirm_modal({message: <ConfirmMessage />, continue_text: 'Confirm & Continue'})
    if (yes) {
      upload()
    }
  }

  const upload = async () => {
    if (!result) {
      return
    }
    setProcessing(true)
    const blob = await window.scanner.getAreaBlob()
    const {code, location} = result
    const data = {size: blob.size, code, location}
    const r = await window.app.requests.post(`/api/antigen/${token}/request-upload-link/`, data)

    const formData = new FormData()
    Object.entries(r.data.fields).forEach(([key, value]) => formData.append(key, value))
    const file = new File([blob], r.data.filename)
    formData.append('file', file)

    const {image_id} = r.data

    try {
      const r_s3 = await fetch(r.data.url, {method: 'POST', body: formData})
      const {status} = r_s3
      if (status !== 204) {
        const response_data = await r_s3.text()
        throw DetailedError(`unexpected response when uploading image: ${status}`, {status, response_data})
      }
    } catch (err) {
      window.app.setError(err)
      return
    }

    let errorMessage = 'Unable to validate image, please try again'
    for (let i = 0; i < 20; i++) {
      await sleep(1000)
      const r = await window.app.requests.get(`/api/antigen/${token}/wait/${image_id}/`)
      // console.log('response:', r.data)
      if (r.data.uploaded) {
        if (!r.data.error_message) {
          setRedirect(`/tests/antigen/${token}/complete/`)
        }
        errorMessage = r.data.error_message || null
        break
      }
    }

    if (errorMessage) {
      setProcessing(false)
      setPageError(errorMessage)
    }
  }

  const toggleTorch = () => {
    if (!processing && !result) {
      setTorchOn(v => {
        const torch_on = !v
        window.scanner.restart(torch_on)
        return torch_on
      })
    }
  }

  const restart = () => {
    setPageError(null)
    setResult(null)
    window.scanner.restart(torchOn)
  }
  if (redirect) {
    return <Redirect to={redirect} push={false} />
  } else if (!loaded) {
    return <Loading />
  }

  return (
    <>
      <h4>Antigen Rapid Test - Result Photo</h4>
      <div className="text-muted">{children}</div>
      {pageError ? (
        <div className="h4 text-danger">{pageError}</div>
      ) : (
        <div className="h4">{result ? 'Cassette successfully detected!' : <InProgress scanning={scanning} />}</div>
      )}
      <div className="d-flex justify-content-center mt-2">
        <video id="video" ref={videoRef} className="d-none" muted playsInline />
        <div>
          <canvas id="canvas" ref={canvasRef} className="video-canvas" />
          {showTorch ? (
            <div className={`flash-button ${torchOn ? 'on' : 'off'}`}>
              <FontAwesomeIcon icon={faLightbulb} className="fa-fw" onClick={toggleTorch} />
            </div>
          ) : null}
        </div>
      </div>

      <div className="text-center mt-2 h-40">
        {result ? (
          <ButtonGroup>
            <Button onClick={restart} disabled={processing}>
              Take new photo
            </Button>
            <Button
              color="primary"
              className={`btn-cog-processing${processing ? ' processing' : ''}`}
              disabled={processing || !!pageError}
              onClick={onClickUpload}
            >
              Use this photo
              <FontAwesomeIcon icon={faCog} className="cog-loading fa-fw" />
            </Button>
          </ButtonGroup>
        ) : null}
      </div>
    </>
  )
}

const InProgress = ({scanning}) => {
  const [dots, setDots] = React.useState(0)
  React.useEffect(() => {
    const clear = setInterval(() => {
      setDots(d => d + 1)
    }, 500)
    return () => clearInterval(clear)
  }, [])
  const word = scanning ? 'Scanning' : 'Loading'
  return (
    <>
      {word}
      {'.'.repeat(dots % 4)}
    </>
  )
}

class QrScanner {
  constructor(token, video, canvas, setResult, setTempError, {booking_reference, qr_regex}) {
    this.token = token
    this.video = video

    canvas.width = videoSize
    canvas.height = videoSize
    this.ctx = canvas.getContext('2d')

    this.setResult = setResult
    this.setTempError = setTempError
    this.bookingReference = booking_reference
    this.worker = new Worker()
    this.worker.postMessage({qr_regex})
    this.x_offset = 340
    this.y_offset = 120
    this.final_padding = 20
    this.box_shape = [290, 40, 220, 720]
    this.bounds = [this.x_offset, this.y_offset, 140, 200]
    this.worker.addEventListener('error', err => window.app.setError(err))
    this.skipCapture = false
    this.video.addEventListener('play', () => requestAnimationFrame(this._step))
    this.qr_codes_found = 0
    this.devices = null
  }

  start = async stream => {
    this.video.srcObject = stream
    const r = await this.restart()
    this.start_time = Date.now()

    const [track] = stream.getVideoTracks()

    if (window.ImageCapture) {
      const imageCapture = new window.ImageCapture(track)
      const photoCapabilities = await imageCapture.getPhotoCapabilities()
      if (photoCapabilities.fillLightMode) {
        this._track = track
      }
    }

    navigator.mediaDevices.enumerateDevices().then(r => {
      this.devices = [...r]
    })
    this.telemetry_interval = setTimeout(() => {
      this.send_telemetry('10s')
    }, 10000)
    return r
  }

  get torchAvailable() {
    return !!this._track
  }

  stop = () => {
    this.video.pause()
    clearInterval(this.telemetry_interval)
    window.removeEventListener('beforeunload', onClose)
    this.send_telemetry('stop')
    this.video.srcObject.getTracks().forEach(t => t.stop())
    this.video.srcObject = null
    this.worker.terminate()
  }

  restart = async (torchOn = null) => {
    this.skipCapture = true
    const r = await this.video.play()
    setTimeout(() => {
      this.skipCapture = false
    }, 1000)
    if (torchOn !== null) {
      await this._setTorch(torchOn)
    }
    return r
  }

  getAreaBlob = async () => {
    const d = this.ctx.getImageData(
      this.box_shape[0] - this.final_padding,
      this.box_shape[1] - this.final_padding,
      this.box_shape[2] + this.final_padding * 2,
      this.box_shape[3] + this.final_padding * 2,
    )
    return await imageDataToBlob(d)
  }

  _step = async () => {
    this.ctx.drawImage(this.video, 0, 0, this.video.videoWidth, this.video.videoHeight)
    this._drawRect(...this.box_shape, 50, 5, '#F00')
    // this._drawRect(...this.bounds, 0)
    if (this.skipCapture) {
      requestAnimationFrame(this._step)
      return
    }
    let result = null
    try {
      result = await this._scan()
    } catch (err) {
      window.app.setError(err)
      return
    }
    const {success, error} = result

    if (success) {
      // apply blur filter:
      // const data_outer = this.ctx.getImageData(0, 0, videoSize, videoSize)
      // const data_inner = this.ctx.getImageData(...this.box_shape)
      // this.ctx.filter = 'blur(10px)'
      // this.ctx.drawImage(this.canvas, 0, 0)
      // this.ctx.filter = 'none'
      // this.ctx.putImageData(data_inner, this.box_shape[0], this.box_shape[1])
      this._drawRect(...this.box_shape, 50, 6, '#17a2b8')
      this._applyBookingReference()
      success.location = Object.fromEntries(Object.entries(success.location).map(this._convertLoc))
      this.setResult(success)
      this.video.pause()
      await this._setTorch(false)
      this.qr_codes_found++
    } else {
      if (error) {
        this.setTempError(error)
      }
      requestAnimationFrame(this._step)
    }
  }

  _setTorch = async torch => {
    if (this._track) {
      await this._track.applyConstraints({advanced: [{torch}]})
    }
  }

  _scan = () => {
    return new Promise(resolve => {
      const onMessage = ({data}) => {
        this.worker.removeEventListener('message', onMessage)
        if (data) {
          resolve(data)
        } else {
          resolve({})
        }
      }
      this.worker.addEventListener('message', onMessage)

      const data = this.ctx.getImageData(...this.bounds)
      this.worker.postMessage(data)
    })
  }

  _convertLoc = ([k, v]) => [k, {x: v.x + this.x_offset, y: v.y + this.y_offset}]

  _drawRect = (x, y, w, h, r, width = 1, color = '#000') => {
    const y_size = h * 0.2
    this.ctx.lineWidth = width
    this.ctx.strokeStyle = color
    this.ctx.beginPath()
    this.ctx.moveTo(x, y + r + y_size)
    this.ctx.arcTo(x, y, x + w, y, r)
    this.ctx.arcTo(x + w, y, x + w, y + h, r)
    this.ctx.lineTo(x + w, y + r + y_size)
    this.ctx.stroke()
    this.ctx.beginPath()
    this.ctx.moveTo(x + w, y + h - r - y_size)
    this.ctx.arcTo(x + w, y + h, x, y + h, r)
    this.ctx.arcTo(x, y + h, x, y, r)
    this.ctx.lineTo(x, y + h - r - y_size)
    this.ctx.stroke()
  }

  _applyBookingReference = () => {
    const left = this.box_shape[0] + 16.5
    const top = this.box_shape[1] + this.box_shape[3] - 22
    this.ctx.fillStyle = 'white'
    this.ctx.fillRect(left, top - 21, 188, 25)

    this.ctx.fillStyle = 'black'
    this.ctx.font = '23px Arial'
    this.ctx.fillText(this.bookingReference, left + 3, top)
  }

  send_telemetry = trigger => {
    const [track] = this.video.srcObject.getVideoTracks()
    const data = {
      trigger,
      start: this.start_time,
      time_on_page: Date.now() - this.start_time,
      qr_codes_found: this.qr_codes_found,
      torch_available: this.torchAvailable,
      video_capabilities: getCapabilities(track),
      video_settings: track.getSettings(),
      devices: this.devices,
    }
    const blob = new Blob([JSON.stringify(data)], {type: 'application/json'})
    navigator.sendBeacon(`/api/antigen/${this.token}/telemetry/`, blob)
  }
}

function getCapabilities(track) {
  try {
    return track.getCapabilities()
  } catch (err) {
    return null
  }
}

async function findCamera() {
  // this requests permissions so enumerateDevices gets a full list of devices
  const devices = await navigator.mediaDevices.enumerateDevices()
  const back_cameras = devices.filter(d => d.kind === 'videoinput' && d.label.toLowerCase().includes('back'))
  if (back_cameras.length > 1) {
    // if there are more than one back camera, we choose the one with a 0 in the name as that appears to be the default
    // see https://github.com/jeromeetienne/AR.js/issues/619
    const {deviceId} = back_cameras.find(d => d.label.includes(' 0')) || back_cameras[0]
    constraints.video.deviceId = deviceId
  } else {
    constraints.video.facingMode = 'environment'
  }
  return await navigator.mediaDevices.getUserMedia(constraints)
}

function onClose() {
  if (window.scanner) {
    window.scanner.send_telemetry('onbeforeunload')
  }
}

function imageDataToBlob(imageData) {
  const canvas = document.createElement('canvas')
  canvas.width = imageData.width
  canvas.height = imageData.height
  const ctx = canvas.getContext('2d')
  ctx.putImageData(imageData, 0, 0)
  return new Promise(resolve => canvas.toBlob(blob => resolve(blob)))
}
