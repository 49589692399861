import React, {useEffect, useState} from 'react'
import {Alert, Container} from 'reactstrap'
import {useHistory, useParams} from 'react-router-dom'
import {LinkCard} from 'src/shared/LinkCard'
import Boots from 'src/assets/images/Vector.svg'
import {ContinueJourney} from './ContinueJourney'
import {getTestTypeValueFromUrl} from '../../assessment_utils'
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons'
import {message_toast} from 'src/shared/reactstrap-toolbox'

// Activate view -Boots Booking to continue
function Dashboard() {
  const {test_type} = useParams()
  const history = useHistory()
  const [untestable, setUntestable] = useState()
  const [resume, setsetResume] = useState()
  const [allow_booking, setAllow_booking] = useState()
  const [rejected, setRejected] = useState()

  // Fetch data for pending journey if any
  const fetchSummary = async () => {
    const response = await window.app.requests.get(`/api/dashboard/registration/summary/`, null, {
      expected_status: [200, 422, 404],
    })
    setUntestable(response.data?.latest_test?.lab_failed)
  }
  const isBookingRejected = () => {
    setRejected(true)
  }
  const fetchBookings = async () => {
    const response = await window.app.requests.get(`/api/dashboard/registration/bookings/`, null, {
      expected_status: [200, 422, 404],
    })
    setAllow_booking(response.data.allow_booking)
    setsetResume(response.data.bookings[0])
  }

  // Fetch data for pending journey if any
  useEffect(() => {
    fetchBookings()
  }, [rejected])

  // Fetch data for untestable samples if any
  useEffect(() => {
    if (resume?.registration_stage === 'assessment' || resume?.registration_stage === 'edit-details') {
      fetchSummary()
    }
  }, [resume, allow_booking])

  useEffect(() => {
    if (resume?.registration_stage === 'assessment' || resume?.registration_stage === 'edit-details') {
      if (!allow_booking && !untestable && resume.booking_status !== 'completed') {
        history.push(`/bloods/boots/success-page/${getTestTypeValueFromUrl(test_type)}/${resume.id}`)
      } else if (!allow_booking) {
        message_toast({
          icon: faInfoCircle,
          title: 'You have a test currently in process',
          message:
            "Once you receive the results for your on going (Phlebotomy service) test, you'll be able to book a new one.",
          onClick: () => {},
          className: '',
          time: 3000,
        })
        history.push('/tests')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resume, allow_booking, untestable, rejected])

  return (
    <div>
      <Container className="mb-5">
        <div className="pt-sm-4 pl-sm-2 pl-md-2 mt-4">
          {getTestTypeValueFromUrl(test_type) === 'unknown' ? (
            <Alert color="danger">You've received an invalid URL. Please verify and try again.</Alert>
          ) : null}
          {allow_booking ? (
            <div className="text-left">
              <h1 className="text-left py-5">MY REGISTRATION</h1>
              <LinkCard
                to={`/bloods/boots/registration/${getTestTypeValueFromUrl(test_type)}`}
                image={Boots}
                title="Register your Boots store appointment"
                button_text="Continue"
              >
                <div className="text-muted">Boots In-store Wellness Testing Service powered by MyHealthChecked</div>
              </LinkCard>
            </div>
          ) : (
            getTestTypeValueFromUrl(test_type) !== 'unknown' &&
            !allow_booking &&
            resume?.registration_stage !== 'assessment' &&
            resume?.registration_stage !== 'edit-details' && (
              <ContinueJourney
                untestable={untestable}
                resume={resume}
                isBookingRejected={isBookingRejected}
                test_type={
                  getTestTypeValueFromUrl(test_type) !== 'unknown'
                    ? getTestTypeValueFromUrl(test_type)
                    : resume.test_type
                }
              />
            )
          )}
        </div>
      </Container>
    </div>
  )
}

export default Dashboard
