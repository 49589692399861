import React from 'react'
import {withRouter} from 'react-router-dom'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {WithContext, Form, Loading, message_toast} from 'src/shared/reactstrap-toolbox'
import {faPollH} from '@fortawesome/free-solid-svg-icons'
import Scale from 'src/shared/Scale'
import steps from './WellnessSteps'

export async function check_step_auth(step) {
  if (step) {
    const r = await window.app.requests.get(`/api/dashboard/assessment/${step}/check/`, null, {
      expected_status: [200, 400],
    })
    if (r.status === 200) {
      this.setState({user: this.props.user})
    } else {
      this.setState({error: {title: r.data.message, description: r.data.details && r.data.details.description}})
    }
  } else {
    this.setState({user: this.props.user})
  }
}

export async function check_step_anon(step) {
  const params = step ? {step} : null
  const r = await window.app.requests.get(`/api/assessment/${this.token()}/`, params, {
    expected_status: [200, 400, 404],
  })
  if (r.status === 200) {
    this.setState({user: r.data})
  } else {
    this.setState({error: {title: r.data.message, description: r.data.details && r.data.details.description}})
  }
}

class AssessmentForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      user: null,
      form_data: {},
      error: null,
    }
    this.check_step = this.props.user ? check_step_auth.bind(this) : check_step_anon.bind(this)
  }

  componentDidMount() {
    window.app.setTitle('MyHealthChecked Assessment')
    this.check_step(this.step_name())
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.check_step(this.step_name())
    }
  }

  token = () => this.props.match.params.token
  test_id = this.props.match.params.test_id || null

  step_number = () => parseInt(this.props.match.params.step || 1)

  step_name = () => Object.keys(steps)[this.step_number() - 1]

  execute = async data => {
    let step_url
    if (this.token()) {
      step_url = `/api/assessment/${this.token()}/${this.step_name()}/`
    } else {
      step_url = `/api/dashboard/assessment/${this.step_name()}/submit/`
    }
    console.log(data)

    const r_step = await window.app.requests.post(step_url, data, {expected_status: [200, 422]})

    if (r_step.status === 200 && this.step_number() === Object.keys(steps).length) {
      let complete_url
      if (this.token()) {
        complete_url = `/api/assessment/${this.token()}/complete/`
      } else {
        complete_url = `/api/dashboard/assessment/complete/`
      }
      const r_complete = await window.app.requests.post(complete_url, null, {expected_status: [200, 400]})
      if (r_complete.status !== 200) {
        console.error('error submitting assessment:', r_complete)
        this.setState({error: {title: r_complete.data.message}})
      }
    }
    return r_step
  }

  afterSubmit = () => {
    if (this.state.error) {
      return
    }
    if (this.step_number() === Object.keys(steps).length) {
      if (this.token()) {
        this.props.history.push('/assessment/complete/')
      } else {
        message_toast({
          icon: faPollH,
          title: 'Assessment Complete',
          message: 'All steps completed successfully',
        })
        window.app.reAuthenticate()

        if (this.test_id) {
          this.props.history.push(`/tests/${this.test_id}/`)
        } else {
          this.props.history.push('/')
        }
      }
      return
    }

    this.setState({form_data: {}})
    let next_url
    if (this.token()) {
      next_url = `/assessment/${this.token()}/${this.step_number() + 1}/`
    } else {
      next_url = `/assessment/${this.step_number() + 1}/`
    }
    this.props.history.push(next_url)
    window.scrollTo(0, 0)
  }

  render() {
    if (this.state.error) {
      return (
        <div>
          <h1>{this.state.error.title}</h1>
          <p>{this.state.error.description || null}</p>
        </div>
      )
    } else if (!this.state.user) {
      return <Loading />
    }

    const current_step = steps[this.step_name()]
    const Before = current_step.before || null

    let dl = Object.keys(this.state.form_data).length

    //remove any bool fields as they are non-mandatory
    if (this.state.form_data) {
      let d = this.state.form_data
      Object.keys(d).forEach(k => {
        if (d[k] === false || d[k] === true) {
          dl = dl - 1
        }
      })
    }

    let fl = Object.keys(current_step.fields).length

    //search for any subfields with toggles in them
    if (current_step && current_step.fields) {
      Object.keys(current_step.fields).forEach(field => {
        if (current_step.fields[field] && current_step.fields[field].subfields) {
          //if it has subfields, remove it from the count
          fl = fl - 1

          const f = current_step.fields[field]
          Object.keys(f.subfields).forEach(sub => {
            if (f.subfields[sub] && f.subfields[sub].type === 'toggle') {
              fl = fl + 1
            }
          })
        }
      })
    }

    let percentage = this.step_number() - 1 + dl / fl
    percentage *= 100 / Object.keys(steps).length
    return (
      <div className="pt-sm-4 pl-sm-1 pt-md-2 ml-md-3 pb-5">
        <div className="assessment">
          <div className="fixed-progress-bar right-0">
            <Scale percentage={percentage} steps={Object.keys(steps).length}>
              {Math.floor(percentage)}% complete
            </Scale>
          </div>

          <div className="">
            <div className="mb-4 mt-3 pt-3">
              {current_step.icon && <FontAwesomeIcon icon={current_step.icon} className="mr-2" />}
              <h1 className="mt-3 text-left text-md-left">{current_step.title}</h1>
            </div>

            <p className="lead">{current_step.lead}</p>
          </div>
          {Before ? <Before /> : null}
          <Form
            highlight_required={false}
            fields={current_step.fields}
            form_body_class={current_step.form_body_class || null}
            form_data={this.state.form_data}
            execute={this.execute}
            RenderFields={current_step.field_render || null}
            afterSubmit={r => this.afterSubmit(r)}
            onChange={form_data => this.setState({form_data})}
            save_label={Object.keys(steps).length === this.step_number() ? 'Submit' : 'Next'}
            cancel_label="Skip"
            onCancel={() => this.props.history.push('/')}
          />
        </div>
      </div>
    )
  }
}

export default WithContext(withRouter(AssessmentForm))
