import React from 'react'
import zxcvbn from 'zxcvbn'
import {InputGeneral} from 'src/shared/reactstrap-toolbox'
import Scale from './Scale'

export function check_passwords(form_data, user) {
  if (form_data.repeat_password !== undefined && form_data.password !== form_data.repeat_password) {
    return form_error('repeat_password', "Passwords don't match")
  }

  const strength = measure_password_strength(form_data.password, user)
  if (!strength.allowed) {
    return form_error('password', `Password ${strength.text.toLowerCase()}`)
  }
  return null
}

export const PasswordStrengthComponent = ({password, user}) => {
  if (!password) {
    return null
  }
  const {percentage, text, warning, suggestions} = measure_password_strength(password, user)
  return (
    <div>
      Password Strength
      <Scale percentage={percentage}>{text}</Scale>
      {warning && <small className="text-danger">{warning}</small>}
      {suggestions &&
        suggestions.map((s, i) => (
          <div key={i}>
            <small className="text-info">{s}</small>
          </div>
        ))}
    </div>
  )
}

export const PasswordStrengthField = props => (
  <div className="pb-2">
    <InputGeneral {...props} type="password" />
    <PasswordStrengthComponent password={props.value} user={props.field.user || {}} />
  </div>
)

const min_password_length = 8
const strength_names = ['Very weak', 'Weak', 'Medium', 'Strong', 'Very strong']

const measure_password_strength = (pw, user) => {
  const {score, guesses_log10, feedback} = zxcvbn(pw || '', [user.first_name, user.last_name, user.email])
  const too_short = pw.length < min_password_length
  let allowed = score >= 2
  let percentage = guesses_log10 * 8 // roughly matches logic in guesses_to_score
  let text = strength_names[score]
  if (too_short) {
    allowed = false
    // so no one complains that their strong but short password is being refused :-)
    percentage = Math.min(percentage, 25)
    text = 'Too short'
  }
  return {allowed, percentage, text, warning: feedback.warning || null, suggestions: feedback.suggestions}
}

// error format here roughly matches errors from pydantic/fastapi
const form_error = (field, msg) => ({
  status: 422,
  data: {details: [{loc: [field], msg}]},
})
