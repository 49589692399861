import React from 'react'
import {RouteComponentProps} from 'react-router-dom'
import {Button} from 'reactstrap'
interface MatchParams {
  barcode: string
  customer_id: string
  test_id: string
}

interface Props extends RouteComponentProps<MatchParams> {}

export default function BloodsWhenToTest({match, history}: Props) {
  const {barcode} = match.params

  async function handleSubmit() {
    await window.app.requests
      .get(`/api/dashboard/test-kits/activate/${barcode}/`, {}, {expected_status: [200, 422]})
      .then((r: any) => {
        history.push(`/tests/private/collect-samples/${r.data.test_kit_id}/`)
      })
  }

  return (
    <div className="row justify-content-center pt-4">
      <div className="mt-3 p-0 col-10">
        <h1>When should I test after exposure?</h1>
      </div>

      <div className="row justify-content-center pt-4">
        <div className="col-10">
          <p className="">
            When testing for sexually transmitted infections (STIs), some infections do not show up immediately after
            sex. The testing window, or the period of time after exposure you will get an accurate result, can vary by
            infection type. For the most test results, we recommend that you follow the accurate testing windows listed
            below.
            <br />
            <br />
            <strong>Chlamydia</strong> - 2 weeks after exposure
            <br />
            <br />
            <strong>Gonorrhoea</strong> - 2 weeks after exposure
            <br />
            <br />
            <strong>Syphilis*</strong> - 4 weeks after exposure
            <br />
            <br />
            <strong>HIV*</strong> - 45 days after exposure. This applies to fourth-generation testing, which is the
            testing method used at our lab.
            <br />
            <br />
            If you've had high-risk exposure to either syphilis or HIV, we recommend seeking help as soon as possible
            by attending a local sexual health clinic or speaking to your GP. If you have been in contact with HIV you
            may need post-exposure prophylaxis after sexual intercourse. Both tests should be repeated 12 weeks after
            your exposure.
            <br />
            <br />
            <span className="text-danger">
              *If you are unsure about whether you are within a testing window, you should do a test now and another
              test in 12 weeks.
            </span>
            <br />
            <br />
            <Button type="submit" color="primary" className="btn-cog mb-1 float-right" onClick={handleSubmit}>
              Confirm &amp; Continue
            </Button>
          </p>
        </div>
      </div>
    </div>
  )
}
