import React from 'react'
import {RouteComponentProps} from 'react-router-dom'
import {Button, FormGroup, Input, Label} from 'reactstrap'

interface MatchParams {
  barcode: string
}

interface Props extends RouteComponentProps<MatchParams> {}

export default function WellnessCollectSample({match, history}: Props) {
  const {barcode} = match.params

  const [warning, setShowWarning] = React.useState(false)
  const [checked, setChecked] = React.useState({
    one: false,
    two: false,
    three: false,
    four: false,
  })

  const handleChange = (prop: string) => (event: any) => {
    event.persist()

    setChecked(prevState => ({
      ...prevState,
      [prop]: event.target.checked,
    }))
  }

  async function handleSubmit() {
    if (checked.one && checked.two && checked.three && checked.four) {
      setShowWarning(false)
      // TODO: Add an API Call to save the ready-to-collect-sample flag
      const url = `/api/dashboard/consent/sample-collection/`
      await await window.app.requests
        .post(url, {barcode: barcode}, {expected_status: [200, 422, 404, 400]})
        .then((res: any) => {
          if (res.status === 200) {
            history.push(`/tests/wellness/time-administered/${barcode}/`)
          } else {
            console.warn(res.data)
          }
        })
    } else {
      setShowWarning(true)
    }
  }

  return (
    <div className="row justify-content-center pt-4">
      <div className="mt-3 p-0 col-10">
        <h1>Ready to collect your sample?</h1>
      </div>

      <div className="row justify-content-center pt-4 mb-8">
        <div className="col-10">
          <h2 className="text-left text-md-left">Before you begin</h2>
          <span className="text-muted">
            To get comfortable with the sample collection process, please read the 'Instructions for use' leaflet
            that’s included with your kit and also watch the video provided below. Even if you have collected a sample
            before, watching the video can help you feel more confident about the process.
          </span>
          <div className="mt-4 mb-4">
            <FormGroup check>
              <Input type="checkbox" value={checked.one as any} onChange={handleChange('one')} />
              <Label check>
                <strong>I have left myself enough time to preform this test.</strong>
                <br />
                Please note it can take up to 20 minutes from start to finish
              </Label>
            </FormGroup>
            <FormGroup check>
              <Input type="checkbox" value={checked.one as any} onChange={handleChange('two')} />
              <Label check>
                <strong>I have not eaten or drunk anything for the past hour.</strong>
                <br />
                Traces of foods or drink (including tea and coffee) might impair the quality and purity of your sample.
                Collect your DNA sample at least 1 hour after eating or drinking.
              </Label>
            </FormGroup>
            <FormGroup check>
              <Input type="checkbox" value={checked.one as any} onChange={handleChange('three')} />
              <Label check>
                <strong>I have not used mouthwash for the past hour.</strong>
                <br />
                The use of mouthwash or toothpaste prior to sample collection can also impair your DNA quality. Please
                avoid using any non-foodstuffs orally (including brushing your teeth) for 1 hour prior to sample
                collection.
              </Label>
            </FormGroup>
            <FormGroup check>
              <Input type="checkbox" value={checked.one as any} onChange={handleChange('four')} />
              <Label check>
                <strong>I have rinsed my mouth before performing this test.</strong>
                <br />
                For best results, rinse your mouth with water immediately prior to sampling.
              </Label>
            </FormGroup>
          </div>
          <div className="mt-4 mb-4">
            <YoutubeEmbed embedId="oejVyz0G71o" />
          </div>

          {warning ? (
            <p className="text-danger">
              These steps are important ways to prepare for sample collection. If you cannot for any reason agree to
              these statements, please contact <strong>customercare@myhealthchecked.com</strong>
            </p>
          ) : null}
          <div className="mt-4 mb-4">
            <Button type="submit" color="primary" className="btn-cog mb-1 float-right" onClick={handleSubmit}>
              Confirm &amp; Continue
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

interface YoutubeProps {
  embedId: string
}

const YoutubeEmbed = ({embedId}: YoutubeProps) => (
  <div className="video-responsive">
    <iframe
      width="853"
      height="480"
      src={`https://www.youtube.com/embed/${embedId}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  </div>
)
