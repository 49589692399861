import React from 'react'
import {useHistory} from 'react-router'

import SwabImg from 'src/assets/images/Isohelix_Rapidri.jpg'
import {Form} from 'src/shared/reactstrap-toolbox'

export default ({
  fields,
  activate_check,
  activate_link,
  page_title,
  barcode_regex,
  barcode_regex_warning,
  image = null,
  get_activate_check_link,
  image_width = null,
  image_height = null,
  show_warn,
}) => {
  const history = useHistory()
  const [form_data, setFormData] = React.useState({})

  const execute = async data => {
    let {barcode, postage_tracking} = data

    const errors = []
    if (barcode_regex && barcode_regex_warning) {
      if (!(barcode || '').match(barcode_regex)) {
        errors.push({loc: ['barcode'], msg: barcode_regex_warning})
      }
    }

    if (errors.length) {
      return {status: 400, data: {details: errors}}
    } else {
      // Use `activate_check` prop or function to generate API url
      const activate_check_link = activate_check || get_activate_check_link(barcode)
      const params = {barcode}

      let checkResponse = await window.app.requests.get(activate_check_link, params, {
        expected_status: [200, 422, 400, 404],
      })
      if (checkResponse.data === null) {
        //passed barcode check, mark it as activating
        checkResponse = await window.app.requests.post(
          activate_link,
          {barcode: barcode, postage_tracking: postage_tracking, stage: 'barcode'},
          {
            expected_status: [200, 422, 400, 404],
          },
        )
      }

      return checkResponse
    }
  }

  const afterSubmit = () => {
    let {barcode} = form_data

    history.push(`/tests/wellness/consent/${barcode}/`)
  }

  return (
    <div className="row justify-content-center pt-4">
      <div className="mt-3 p-0 col-10">
        <h1>{page_title}</h1>
      </div>

      <div className="row justify-content-center pt-4">
        <div className="col-10">
          <p className="text-muted">
            Please enter the 10 character barcode as it appears on the small sticker attached to the inside of your
            Instructions For Use leaflet.
          </p>
          {show_warn ? (
            <>
              <p className="text-muted">
                To ensure the stability of your sample for processing, you should only collect and return your sample
                <strong>– Monday – Thursday</strong>. Do not collect or return your samples on a weekend or Bank
                Holiday as there will be postal delays.
              </p>
            </>
          ) : null}
          {image && (
            <div className="text-center py-2">
              <img
                loading="eager"
                src={image || SwabImg}
                alt="Test Tube Number"
                className="img-fluid rounded-lg"
                width={image_width || 300}
                height={image_height || 300}
              />
            </div>
          )}
          <div className="activate-1-form">
            <Form
              fields={fields}
              form_data={form_data}
              onChange={setFormData}
              afterSubmit={afterSubmit}
              execute={execute}
              save_label="Confirm & Continue"
            />
          </div>
        </div>
      </div>
    </div>
  )
}
