import React from 'react'
import {LinkCard} from 'src/shared/LinkCard'
import ActivatePCR from 'src/assets/icons/Platform/PCR_tests.svg'
import Antigen from 'src/assets/icons/Platform/Lateral_Flow_Tests.svg'
import {Button, Alert} from 'reactstrap'
import {Link} from 'react-router-dom'

export default () => {
  const currentDate = new Date()
  const endOfLifeDay = new Date(2023, 9, 27) // Months are zero-indexed, so 9 represents October.

  if (currentDate < endOfLifeDay) {
    return (
      <>
        <div className="pt-sm-4 pl-sm-2 pt-md-5 pl-md-5 mt-4">
          <div className="py-3 border-bottom">
            <h1 className="text-center text-md-left">Covid Tests</h1>
            <div className="text-center text-md-left">
              <LinkCard
                to="/tests/pcr/activate/"
                image={ActivatePCR}
                title="Activate PCR Test"
                button_text="Activate Kit"
              >
                <div className="text-muted">If you have received a test PCR kit, you can activate it now.</div>
              </LinkCard>
              <LinkCard to="/tests/antigen/" image={Antigen} title="Take Antigen Test" button_text="Start Test">
                <div className="text-muted">
                  If you have an Antigen Test (also known as "Lateral Flow Test"), you can take your test and submit
                  the result for analyse to get test certificate.
                </div>
              </LinkCard>
            </div>
          </div>
        </div>
      </>
    )
  } else {
    return (
      <>
        <div className="pt-sm-4 pl-sm-2 pt-md-5 pl-md-5 mt-4">
          <h1 className="text-center text-md-left">Covid Tests</h1>
          <br />
          <Alert className=" text-center text-md-left p-4" color="danger">
            From October 27th, we will not be providing our COVID testing services due to the reduced demand. If you
            have purchased a test with us recently, please get in touch with our Customer Care team at{' '}
            <a href="mailto:care@myhealthchecked.com">care@myhealthchecked.com</a> and remember to include your booking
            reference number for assistance.
            <br />
            <br />
            <div className="text-center text-md-left">
              <Button tag={Link} color="primary" to="/activate">
                Go Back
              </Button>
            </div>
          </Alert>
        </div>
      </>
    )
  }
}
