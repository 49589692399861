import React from 'react'
import ActivateStep2 from '../Common/ActivateStep2'

export default ({match, history, location}) => {
  const info_fields_basic = {
    first_name: true,
    last_name: true,
    dob: true,
    sex: true,
    ethnicity: true,
  }

  const info_fields_with_booking_reference = {
    first_name: true,
    last_name: true,
    dob: true,
    sex: true,
    ethnicity: true,
  }

  const activate_link = `/api/dashboard/activate/wellness/`

  return (
    <ActivateStep2
      history={history}
      match={match}
      location={location}
      activate_link={activate_link}
      info_fields_basic={info_fields_basic}
      info_fields_with_booking_reference={info_fields_with_booking_reference}
    />
  )
}
