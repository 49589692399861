import React, {useState} from 'react'
import DisplayItems from 'src/shared/DisplayItems'

import {Loading, as_title, Error} from 'src/shared/reactstrap-toolbox'
import BloodTimeline from '../TestDetails/BloodTimeline'

export default ({match}) => {
  const {test_kit_id} = match.params
  const [test_kit, setTestKit] = React.useState(null)
  const [error, setError] = React.useState(null)
  const [reload, setReload] = useState(false)
  const [fromChild, setFromChild] = useState(false)

  React.useEffect(() => {
    !fromChild && setTestKit(null)
    window.app.requests
      .get(`/api/dashboard/test-kits/${test_kit_id}/`, null, {expected_status: [200, 404]})
      .then(r => {
        if (r.status === 200) {
          setTestKit(r.data)
          window.app.setTitle(`${r.data.info.test_kit_type_name}`)
        } else {
          setError(`Test kit not found, id=${test_kit_id}`)
        }
      })

    // eslint-disable-next-line
  }, [test_kit_id, reload])

  if (error) {
    return <Error error={error} />
  } else if (!test_kit) {
    return <Loading />
  }

  const {info} = test_kit
  const {test_kit_type, test_kit_type_name} = info

  const assessment = {
    requireAssessment: test_kit_type && test_kit_type.startsWith('blood-'),
    hasFilledAssessment: info.assessment_id,
    canEditAssessment: info.can_edit_assessment,
  }

  const autoReload = () => {
    setReload(!reload)
    setFromChild(true)
  }

  return (
    <div className="pt-2 pt-md-5 px-md-5">
      <div className="pt-sm-4 pl-sm-1 pt-md-2 ">
        <div className="mb-4 mt-3 pt-3">
          <h1 className="mt-3 text-left text-md-left">{as_title(test_kit_type_name)}</h1>
        </div>
        <BloodTimeline
          test_id={test_kit_id}
          autoReload={autoReload}
          bId={info.bid}
          status={info.test_kit_status}
          test_type={info.test_kit_type}
          test_name={info.test_kit_type_name}
          report_id={info.report_id}
          assessment={assessment}
          unconfirmed_result={info.unconfirmed_result}
          unconfirmed_result_message={info.unconfirmed_result_message}
          calendly_status={info.calendly_status}
          outcome={info.outcome}
          note={info.note}
          attempt_type={info.attempt_type}
          consultation_status={info.consultation_status}
          archive_reason={info.archive_reason}
          archive_reason_message={info.archive_reason_message}
          error_note={info.error_note}
        />
        <h2 className="py-2">Test Summary</h2>
        <DisplayItems fields={summary_fields} data={info} />
      </div>
    </div>
  )
}

const summary_fields = {
  test_kit_type_name: {title: 'Test Kit Type'},
  test_kit_status_name: {title: 'Status'},
  sex: {title: 'Gender at Birth', fmt: 'as-title'},
  dob: {title: 'Date of Birth', fmt: 'date'},
  primary_barcode: {fmt: 'inline-code'},
  secondary_barcode: {fmt: 'inline-code'},
}
